<template>
  <div class="level" v-if="show">
    <div class="level-left"></div>
    <div class="level-right">
      <article class="message" :class="clazz">
        <div class="message-body">
          {{ message }}
        </div>
      </article>
    </div>
  </div>
</template>

<script>
export default {
  name: "OtherAmountMessage",
  props: [ "show", "message", "clazz"]
}
</script>
