import axios from "axios";
import store from "../store/index";

export default async function({ instanceId, accountNo }) {
  let { data } = await axios.get(
    process.env.VUE_APP_API_URL +
      "api/pages/" +
      instanceId +
      ";user=" +
      store.state.username,
    {
      params: {
        pageName: "MakePayment",
        accountNo: accountNo
      },
      headers: {
        Authorization: "Bearer " + store.state.jwt
      }
    }
  );

  store.commit("SET_GLOBAL_CONFIG", data.globalConfig);
  store.commit("SET_INSTANCE_CONFIG", data.instanceConfig);
  store.commit("accounts/SET_ACCOUNTS", data.accounts);
  store.commit("SET_ARRANGEMENT", data.arrangement);
  store.commit("SET_COMPANY_CONFIG", data.companyConfig);
  store.commit("SET_SD_MESSAGES", data.messages);
  if (!!data.overTimePOROVT && !!data.overTimePOROVT.sodResults) {
    store.commit("SET_OVERTIME_POROVT_RESULTS", data.overTimePOROVT.sodResults);
  }
  store.commit("accounts/SET_INTEREST", data.interest);
  store.commit("accounts/SET_STATE_COMPLIANCE", data.stateCompliance)
  store.commit("SET_USER_INFO", data.userInfo);
}
