import axios from "axios";

export default {
  namespaced: true,
  state: {
    hasPrefilledPayInfo: false,
    prefilledPayInfo: {},
    feeResult: {
      amount: 0
    },
    phonePays: [],
    singlePaymentInfo: {}
  },
  mutations: {
    SET_PREFILLED_PAY_INFO: (state, payInfo) => {
      if (payInfo == null) {
        state.hasPrefilledPayInfo = false;
        state.prefilledPayInfo = {};
      } else {
        state.hasPrefilledPayInfo = true;
        state.prefilledPayInfo = payInfo;
      }
    },
    SET_FEE_RESULT: (state, feeResult) => (state.feeResult = feeResult),
    SET_PHONE_PAYS: (state, phonePays) => (state.phonePays = phonePays),
    SET_SINGLE_PAYMENT_INFO: (state, singlePaymentInfo) => (state.singlePaymentInfo = singlePaymentInfo)
  },
  actions: {
    async fetchPrefilledPayInfo({ commit, rootState }, { instanceId, dbtNo }) {
      try {
        let { data } = await axios.get(
          process.env.VUE_APP_API_URL +
            "api/payments/info/" +
            instanceId +
            "/" +
            dbtNo +
            ";user=" +
            rootState.username,
          {
            headers: {
              Authorization: "Bearer " + rootState.jwt
            }
          }
        );
        commit("SET_PREFILLED_PAY_INFO", data);
      } catch (e) {
        if (e.response.status == 404) {
          commit("SET_PREFILLED_PAY_INFO", null);
        } else {
          throw e;
        }
      }
    },
    async calculateFee({ rootState, commit }, { account, phrType, amount }) {
      let qs = `relatedAccount=${account}&phrType=${phrType}&amount=${amount}`;
      let {data} = await axios.get(
          process.env.VUE_APP_API_URL + "api/demographics/fee?" + qs,
          {
            headers: {
              Authorization: "Bearer " + rootState.jwt
            }
          }
      );
      if (data.success) {
        commit("SET_FEE_RESULT", data);
      } else {
        commit("SET_FEE_RESULT", {amount: 0});
      }
    },
    async fetchPhonePays({ commit, rootState }, { instanceId, dbtNo }) {
      try {
        let response = await axios.get(
            process.env.VUE_APP_API_URL +
            "api/payments/phonePays/" +
            instanceId +
            "/" +
            dbtNo +
            ";user=" +
            rootState.username,
            {
              headers: {
                Authorization: "Bearer " + rootState.jwt
              }
            }
        );

        commit("SET_PHONE_PAYS", response.data.phonePays);

      } catch(e) {
        commit("SET_PHONE_PAYS", []);
      }
    },
    async fetchSinglePaymentInfo({ commit, rootState }, { dbtNo }) {
      try {
        let response = await axios.get(
            process.env.VUE_APP_API_URL +
            "api/payments/singlePaymentInfo/" +
            dbtNo +
            ";user=" +
            rootState.username,
            {
              headers: {
                Authorization: "Bearer " + rootState.jwt
              }
            }
        );
        commit("SET_SINGLE_PAYMENT_INFO", response.data);
      } catch(e) {
        commit("SET_SINGLE_PAYMENT_INFO", {});
      }
    }
  },
  getters: {
    hasPhonePays: state => {
      return state.phonePays != null && state.phonePays.length > 0;
    },
    hasSinglePaymentInfo: state => (state.singlePaymentInfo != null && Object.keys(state.singlePaymentInfo).length > 0)
  }
};
