import axios from "axios";

export default {
  namespaced: true,
  state: () => ({
    paymentAmount: null,
    numberOfPayments: null,
    firstPaymentDate: null,
    prompts: {},
    formFields: {},
    retVals: {},
    results: {}
  }),
  mutations: {
    SET_PAYMENT_AMOUNT: (state, paymentAmount) =>
      (state.paymentAmount = paymentAmount),
    SET_NUMBER_OF_PAYMENTS: (state, numberOfPayments) =>
      (state.numberOfPayments = numberOfPayments),
    SET_FIRST_PAYMENT_DATE: (state, firstPaymentDate) =>
      (state.firstPaymentDate = firstPaymentDate),
    SET_FORM_FIELDS: (state, payload) => {
      state.formFields = payload.inputs;
      state.retVals = payload.outputs;
    },
    SET_PROMPTS: (state, prompts) => (state.prompts = prompts),
    SET_RESULTS: (state, results) => {
      state.results = results;
    }
  },
  actions: {
    setPARRNGFields({ commit }, payload) {
      commit("SET_PAYMENT_AMOUNT", payload.paymentAmount);
      commit("SET_NUMBER_OF_PAYMENTS", payload.numberOfPayments);
      commit("SET_FIRST_PAYMENT_DATE", payload.firstPaymentDate);
    },
    async fetchPrompts({ commit }, sodCode) {
      let { data } = await axios.get(
        process.env.VUE_APP_API_URL + `public/strategies/${sodCode}/prompts`
      );
      commit("SET_PROMPTS", data);
    },
    async fetchFormFields({ rootState, commit }, sodCode) {
      let { data } = await axios.get(
        process.env.VUE_APP_API_URL +
          "api/strategies/" +
          sodCode +
          "/formFields",
        {
          headers: {
            Authorization: "Bearer " + rootState.jwt
          }
        }
      );
      commit("SET_FORM_FIELDS", data); // retvals too
    },
    async callPARRNG(
      { rootState, commit, getters },
      { sodCode, relatedAccount, overTimeAmount, overTimeNumber, overTimeFirst }
    ) {
      let s = `${getters.getFormParam(
        "PORTAL_OVER_TIME_AMOUNT"
      )}=${overTimeAmount}&${getters.getFormParam(
        "PORTAL_OVER_TIME_NUMBER"
      )}=${overTimeNumber}&${getters.getFormParam(
        "PORTAL_OVER_TIME_FIRST"
      )}=${overTimeFirst}&relatedAccount=${relatedAccount}`;

      let { data } = await axios.post(
        process.env.VUE_APP_API_URL +
          `api/strategies/${sodCode};user=${rootState.username}`,
        s,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + rootState.jwt
          }
        }
      );
      commit("SET_RESULTS", data);
    }
  },
  getters: {
    getFormParam: state => formField => {
      if (state.formFields) {
        return state.formFields[formField];
      }
      return null;
    },
    getRetVal: state => retVal => {
      if (state.retvals) {
        return state.retVals[retVal];
      }
      return null;
    }
  }
};
