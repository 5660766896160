<template>
  <div class="field">
    <div class="field is-grouped is-horizontal">
      <div class="control">
        <button class="button" v-if="activatedBrowse">
          Browse
          <input
            class="file-input"
            type="file"
            :name="inputName"
            :ref="inputRef"
            @change="browseFile"
          />
        </button>
        <button
          class="button is-success"
          @click="uploadFile"
          v-if="activatedUpload"
        >
          Upload
        </button>
      </div>
      <div class="control is-expanded">
        <input class="input" type="text" v-model="fileName" readonly />
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  props: ["inputName", "inputRef"],
  data() {
    return {
      file: null,
      type: null,
      desc: "File uploaded with Upload Docs service",
      activatedBrowse: true,
      activatedUpload: false,
      fileName: null
    };
  },
  methods: {
    changeButtons() {
      this.activatedBrowse = !this.activatedBrowse;
      this.activatedUpload = !this.activatedUpload;
    },
    async browseFile() {
      this.file = this.$refs[this.inputRef].files[0];
      this.fileName = this.file.name;
      this.changeButtons();
      await this.loadAccountInfo();
    },
    async uploadFile() {
      try {
        await this.$store.dispatch("addDoc", {
          dbtNo: this.$route.params.id,
          desc: this.desc,
          type: this.type,
          uploadedFile: this.file
        });
        this.fileName = null;
      } catch (e) {
        this.$store.commit("SET_GLOBAL_NOTIFICATION", {
          showNotification: true,
          notificationMessage: `${e}`,
          notificationType: "error"
        });
      } finally {
        this.changeButtons();
        this.fileName = null;
      }
    },
    loadAccountInfo() {
      this.type = this.$store.state.instanceConfig.uploadDocType;
      // this.desc = this.$store.state.instanceConfig.desc; //
    }
  },
  computed: {
    ...mapState(["instanceConfig"])
  }
};
</script>
