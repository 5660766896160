<template>
  <section class="hero is-fullheight has-background-light">
    <div class="hero-head"></div>
    <div class="hero-body">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-8-tablet is-5-desktop">
            <form class="box has-text-centered" @submit.prevent>
              <div class="field">
                <img :src="computedURL()" alt="splashURL" />
              </div>
              <div class="field">
                <h1 class="title is-4">{{ instanceConfig.header }}</h1>
              </div>
              <p v-show="errorFlag" class="notification is-danger">
                <button class="delete" @click="clearError"></button>
                {{ errorMessage }}
              </p>
              <ValidationObserver slim v-slot="{ invalid }">
                <ValidationProvider rules="required" tag="div" class="field">
                  <div class="control">
                    <label class="radio" v-for="label in lookupFields" :key="label">
                      <input
                          :disabled="loadingFlag"
                          type="radio"
                          name="lookupField"
                          v-model="lookupField"
                          :value="label">
                      {{ label }}
                    </label>
                  </div>
                </ValidationProvider>
                <ValidationProvider rules="required" tag="div" class="field">
                  <div class="control has-icons-left">
                    <input
                        :disabled="loadingFlag"
                        class="input"
                        type="text"
                        :placeholder="lookupField"
                        v-model="lookupValue"
                        @keyup.enter="lookup"
                    />
                    <span class="icon is-small is-left">
                      <font-awesome-icon icon="id-badge" />
                    </span>
                  </div>
                </ValidationProvider>
                <div class="field">
                  <div class="buttons is-centered">
                    <button
                        :disabled="loadingFlag || invalid"
                        class="button is-link"
                        @click="lookup"
                    >
                      Submit
                    </button>
                  </div>
                </div>

                <hr />
                <div class="field" v-if="instanceConfig.loginMessage">
                  <p class="login-message">
                    {{ instanceConfig.loginMessage }}
                  </p>
                </div>
                <div class="field">
                  <p class="company-notice">
                    {{ instanceConfig.footer2 }}
                  </p>
                </div>
                <progress
                    v-show="loadingFlag"
                    class="progress is-small is-info"
                    max="100"
                >15%</progress
                >
              </ValidationObserver>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="hero-foot"></div>
  </section>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { mapState, mapActions } from "vuex";

extend("required", {
  ...required,
  message: "This field is required"
});

export default {
  components: { ValidationProvider, ValidationObserver },
  data() {
    return {
      loadingFlag: false,
      errorFlag: false,
      errorMessage: "",
      lookupField: null,
      lookupValue: null
    };
  },
  computed: {
    ...mapState(["instanceConfig"]),
    ...mapState("g", ["lookupFields","demPortalUsername"]),
    computedURL() {
      return !this.instanceConfig.splashURL
          ? () => require("../../../assets/defaultGray.png")
          : () => {
            return this.instanceConfig.splashURL;
          };
    }
  },
  methods: {
    ...mapActions(["fetchPaymentPortalInstanceConfig"]),
    ...mapActions("g", ["fetchLookupFields","lookupAccount"]),
    clearError() {
      this.errorFlag = false;
      this.errorMessage = "";
    },
    async lookup() {
      this.loadingFlag = true;
      this.errorMessage = false;
      this.errorMessage = "";
      try {

        await this.lookupAccount({
          label: this.lookupField,
          value: this.lookupValue
        });

        this.$router.push(
            {
              name: "guest.home",
              params: {
                instanceId: this.$route.params.instanceId
              },
              query: {
                user: this.demPortalUsername
              }
            });

      } catch (e) {
        this.errorFlag = true;
        const fn = (this.lookupField)?this.lookupField:"Account";
        this.errorMessage = `${fn} not found`;
      } finally {
        this.loadingFlag = false;
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next(async vm => {
      try {
        vm.loadingFlag = true;
        await vm.fetchPaymentPortalInstanceConfig(vm.$route.params.instanceId);
        await vm.fetchLookupFields();
        if( vm.lookupFields ) {
          vm.lookupField = vm.lookupFields[0]; // #1892 sel first item
        }
      } catch(e) {
        vm.errorFlag = true;
        vm.errorMessage = "Error building page";
      } finally {
        vm.loadingFlag = false;
      }
    });
  }
};
</script>

<style lang="scss">
html,
body,
#wrapper {
  height: 100%;
}

.login-message {
  font-size: 0.8rem;
}

.company-notice {
  font-size: 0.6rem;
}
</style>
