<template>
  <base-layout :loadingFlag="$store.state.loading">
    <nav class="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li>
          <router-link :to="{ name: 'home' }">Home</router-link>
        </li>
        <li>
          <router-link
            :to="{ name: 'makePayment', params: { id: $route.params.id } }"
            >Make Payment</router-link
          >
        </li>
        <li>
          <router-link
            :to="{
              name: 'details',
              params: { id: $route.params.id }
            }"
            >Details</router-link
          >
        </li>
        <li class="is-active">
          <router-link
            :to="{
              name: 'paymentInfo',
              params: { id: $route.params.id }
            }"
            >Payment Information</router-link
          >
        </li>
      </ul>
    </nav>
    <div class="content">
      <user-info />
    </div>

    <account-header />
    <div class="columns">
      <div class="column is-one-quarter is-hidden-mobile">
        <sidebar />
      </div>
      <div class="column">
        <h1 class="title is-6">Payment Information</h1>
        <ValidationObserver v-slot="{ invalid }">
          <ValidationProvider rules="required" tag="div" class="field">
            <label class="label" for="firstName"
              >First Name <span class="has-text-danger">*</span></label
            >
            <div class="control">
              <input
                class="input"
                type="text"
                id="firstName"
                v-model="firstName"
                maxlength="25"
              />
            </div>
          </ValidationProvider>
          <ValidationProvider rules="required" tag="div" class="field">
            <label class="label" for="lastName"
              >Last Name <span class="has-text-danger">*</span></label
            >
            <div class="control">
              <input
                class="input"
                type="text"
                id="lastName"
                v-model="lastName"
                maxlength="25"
              />
            </div>
          </ValidationProvider>
          <ValidationProvider rules="required" tag="div" class="field">
            <label class="label" for="billingAddress"
              >Billing Address <span class="has-text-danger">*</span></label
            >
            <div class="control">
              <input
                class="input"
                type="text"
                id="billingAddress"
                v-model="billingAddress"
                maxlength="30"
              />
            </div>
          </ValidationProvider>
          <ValidationProvider rules="required" tag="div" class="field">
            <label class="label" for="city"
              >City <span class="has-text-danger">*</span></label
            >
            <div class="control">
              <input
                class="input"
                type="text"
                id="city"
                v-model="city"
                maxlength="28"
              />
            </div>
          </ValidationProvider>
          <ValidationProvider rules="required" tag="div" class="field">
            <label class="label" for="state"
              >State <span class="has-text-danger">*</span></label
            >
            <div class="control">
              <div class="select">
                <select id="state" v-model="state">
                  <option :value="null">Please select a value</option>
                  <option value="AL">Alabama</option>
                  <option value="AK">Alaska</option>
                  <option value="AZ">Arizona</option>
                  <option value="AR">Arkansas</option>
                  <option value="CA">California</option>
                  <option value="CO">Colorado</option>
                  <option value="CT">Connecticut</option>
                  <option value="DE">Delaware</option>
                  <option value="DC">District Of Columbia</option>
                  <option value="FL">Florida</option>
                  <option value="GA">Georgia</option>
                  <option value="HI">Hawaii</option>
                  <option value="ID">Idaho</option>
                  <option value="IL">Illinois</option>
                  <option value="IN">Indiana</option>
                  <option value="IA">Iowa</option>
                  <option value="KS">Kansas</option>
                  <option value="KY">Kentucky</option>
                  <option value="LA">Louisiana</option>
                  <option value="ME">Maine</option>
                  <option value="MD">Maryland</option>
                  <option value="MA">Massachusetts</option>
                  <option value="MI">Michigan</option>
                  <option value="MN">Minnesota</option>
                  <option value="MS">Mississippi</option>
                  <option value="MO">Missouri</option>
                  <option value="MT">Montana</option>
                  <option value="NE">Nebraska</option>
                  <option value="NV">Nevada</option>
                  <option value="NH">New Hampshire</option>
                  <option value="NJ">New Jersey</option>
                  <option value="NM">New Mexico</option>
                  <option value="NY">New York</option>
                  <option value="NC">North Carolina</option>
                  <option value="ND">North Dakota</option>
                  <option value="OH">Ohio</option>
                  <option value="OK">Oklahoma</option>
                  <option value="OR">Oregon</option>
                  <option value="PA">Pennsylvania</option>
                  <option value="PR">Puerto Rico</option>
                  <option value="RI">Rhode Island</option>
                  <option value="SC">South Carolina</option>
                  <option value="SD">South Dakota</option>
                  <option value="TN">Tennessee</option>
                  <option value="TX">Texas</option>
                  <option value="UT">Utah</option>
                  <option value="VT">Vermont</option>
                  <option value="VA">Virginia</option>
                  <option value="WA">Washington</option>
                  <option value="WV">West Virginia</option>
                  <option value="WI">Wisconsin</option>
                  <option value="WY">Wyoming</option>
                </select>
              </div>
            </div>
          </ValidationProvider>

          <ValidationProvider
            rules="required|regex:/^[0-9]+$/"
            tag="div"
            class="field"
          >
            <label class="label" for="zipCode"
              >Zip <span class="has-text-danger">*</span></label
            >
            <div class="control">
              <input
                class="input"
                type="text"
                id="zipCode"
                v-model="zipCode"
                maxlength="10"
              />
            </div>
          </ValidationProvider>

          <ValidationProvider rules="required|numeric" tag="div" class="field">
            <label class="label" for="ccNumber"
              >Card Number <span class="has-text-danger">*</span></label
            >
            <div class="control">
              <input
                class="input"
                type="text"
                id="ccNumber"
                v-model="ccNumber"
              />
            </div>
          </ValidationProvider>
          <div class="field">
            <label class="label"
              >Exp MMYY<span class="has-text-danger">*</span></label
            >
            <div class="columns is-mobile">
              <div class="column">
                <ValidationProvider
                  rules="required"
                  tag="div"
                  class="control"
                  v-slot="{ errors }"
                >
                  <input
                    class="input"
                    type="text"
                    ccExpDateMonth="Text input"
                    id="ccExp"
                    v-model="ccExpDateMonth"
                    placeholder="MM"
                    maxlength="2"
                  />
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="column">
                <ValidationProvider
                  rules="required"
                  class="control"
                  v-slot="{ errors }"
                >
                  <input
                    class="input"
                    type="text"
                    id="ccExpDateYear"
                    v-model="ccExpDateYear"
                    placeholder="YY"
                    maxlength="2"
                  />
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <ValidationProvider rules="required" tag="div" class="field">
            <label class="label" for="cvv"
              >CVV <span class="has-text-danger">*</span></label
            >
            <div class="control">
              <input class="input" type="text" id="cvv" v-model="cvv" />
            </div>
          </ValidationProvider>

          <div class="buttons">
            <button class="button is-primary" @click="save" :disabled="invalid">
              Save
            </button>
            <router-link
              :to="{ name: 'makePayment', params: { id: $route.params.id } }"
              tag="button"
              class="button is-danger"
              >Cancel</router-link
            >
          </div>
        </ValidationObserver>
      </div>
    </div>
  </base-layout>
</template>

<script>
import BaseLayout from "@/components/BaseLayout.vue";
import AccountHeader from "@/components/AccountHeader.vue";
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import UserInfo from "@/components/UserInfo.vue";
import Sidebar from "@/components/Sidebar.vue";

extend("required", {
  validate(value) {
    return {
      required: true,
      valid: ["", null, undefined].indexOf(value) === -1
    };
  },
  computesRequired: true
});

export default {
  components: {
    BaseLayout,
    AccountHeader,
    ValidationObserver,
    ValidationProvider,
    UserInfo,
    Sidebar
  },
  data() {
    return {
      firstName: null,
      lastName: null,
      billingAddress: null,
      city: null,
      state: null,
      zipCode: null,
      ccNumber: null,
      ccExpDateMonth: null,
      ccExpDateYear: null,
      cvv: null
    };
  },
  methods: {
    save() {
      window.alert("to be implemented");
    }
  }
};
</script>
