<template>
    <li :class="{ 'is-active': active }">
        <router-link 
            :to="{ name: 'guest.home', query: { user } }">
            <span class="icon is-small">
            <font-awesome-icon icon="home" />
            </span>
            <span>Home</span>
        </router-link>
    </li>      
</template>
<script>

export default {
    props: {
      active: {
        type: Boolean,
        default: false
      },
      user: {
        type: String
      }
    }
};
</script>