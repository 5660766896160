<template>
  <base-layout :loadingFlag="$store.state.loading">
    <nav class="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li>
          <router-link
            :to="{ name: 'home', query: { tenant: $route.query.tenant } }"
          >
            <span class="icon is-small">
              <font-awesome-icon icon="home" />
            </span>
            <span>Home</span></router-link
          >
        </li>
        <li>
          <router-link
            :to="{
              name: 'makePayment',
              params: { id: $route.params.id }
            }"
          >
            <span class="icon is-small">
              <font-awesome-icon icon="file-invoice-dollar" />
            </span>
            <span>Make Payment</span></router-link
          >
        </li>
        <li class="is-active">
          <router-link
            :to="{
              name: 'completed',
              params: { id: $route.params.id },
              query: { tenant: $route.query.tenant }
            }"
          >
            <span class="icon is-small">
              <font-awesome-icon icon="dollar-sign" />
            </span>
            <span>Completed</span>
          </router-link>
        </li>
      </ul>
    </nav>
    <div class="content">
      <user-info />
    </div>

    <account-header />
    <div class="columns">
      <div class="column is-one-quarter is-hidden-mobile">
        <sidebar />
      </div>
      <div class="column">
        <template v-if="$store.state.paymentResult.success">
          <h1 class="title is-6">Payment Completed</h1>
          <p class="content">
            Your payment was successful.
          </p>
          <p v-for="msg in successMessagesSplit" :key="msg.id" class="content">
            {{ msg.text }}
          </p>
          <p class="content" v-if="$store.state.paymentResult.authCode">
            <strong
              >Your authorization code is
              {{ $store.state.paymentResult.authCode }}.</strong
            >
          </p>
          <p class="content" v-if="$store.state.paymentResult.authCode">
            <strong> Please keep this code for your records.</strong>
          </p>
          <p class="content" v-if="$store.state.paymentResult.confNumber">
            <strong
            >Your confirmation code is
              {{ $store.state.paymentResult.confNumber }}.</strong
            >
          </p>
          <p class="content" v-if="$store.state.paymentResult.confNumber">
            <strong> Please keep this code for your records.</strong>
          </p>
          <div class="buttons">
            <router-link
              :to="{ name: 'makePayment', params: { id: $route.params.id } }"
              tag="button"
              class="button is-link"
              >Home</router-link
            >
            <button class="button" @click="print">
              <span class="icon is-small">
                <font-awesome-icon icon="print" />
              </span>
              <span>Print</span>
            </button>
          </div>
        </template>
        <template v-else>
          <h1 class="title is-6">Payment Error</h1>
          <p class="content">
            There was an error processing your payment.
          </p>
          <p class="content">
            Please press the Retry button to resubmit your payment.
          </p>
          <div class="buttons">
            <router-link
              :to="{ name: 'makePayment', params: { id: $route.params.id } }"
              tag="button"
              class="button is-link"
              >Retry</router-link
            >
            <router-link
              :to="{ name: 'contactUs' }"
              tag="button"
              class="button is-light"
              >Contact</router-link
            >
          </div>
        </template>
      </div>
    </div>
  </base-layout>
</template>

<script>
import BaseLayout from "@/components/BaseLayout.vue";
import AccountHeader from "@/components/AccountHeader.vue";
import UserInfo from "@/components/UserInfo.vue";
import Sidebar from "@/components/Sidebar.vue";
import store from "../store/index";

function unloadListener(event) {
  // Cancel the event as stated by the standard.
  event.preventDefault();
  // Chrome requires returnValue to be set.
  event.returnValue = "";
}

export default {
  components: {
    BaseLayout,
    AccountHeader,
    UserInfo,
    Sidebar
  },
  computed: {
    successMessagesSplit() {
      let newArray = this.$store.state.additionalSuccessMessages.split("\n");
      newArray = newArray.map(elem => ({ id: this.idgen++, text: elem }));
      return newArray;
    }
  },
  beforeRouteEnter(to, from, next) {
    if (!store.state.paymentResult) {
      next({ name: "account", params: { id: to.params.id } });
    } else {
      window.addEventListener("beforeunload", unloadListener);
      next();
    }
  },
  beforeRouteLeave(to, from, next) {
    window.removeEventListener("beforeunload", unloadListener);
    this.$store.commit("SET_GLOBAL_NOTIFICATION", {}); // clear any errors
    next();
  },
  methods: {
    print() {
      window.print();
    }
  }
};
</script>
