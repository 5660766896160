<template>
  <base-layout :loadingFlag="loading">
    <h1 class="title">Registration Completed</h1>
    <p class="content">
      Your registration has been completed. Press the login button to return to
      continue with your username and password
    </p>
    <div class="control">
      <div class="field">
        <router-link
          :to="{ name: 'login', query: { tenant: $route.query.tenant } }"
          class="button is-info"
          tag="button"
          >Login</router-link
        >
      </div>
    </div>
  </base-layout>
</template>
<script>
import BaseLayout from "@/components/RegistrationBaseLayout.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: { BaseLayout },
  computed: {
    ...mapState(["loading"])
  },
  methods: {
    ...mapActions(["fetchPaymentPortalInstanceConfig"])
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.fetchPaymentPortalInstanceConfig(to.params.instanceId));
  }
};
</script>
