<template>
    <section class="hero secondaryBanner" v-if="instanceConfig.secondaryBanner" :style="styleObject">
        <div class="hero-body">
            <p class="title has-text-white">
                {{ instanceConfig.secondaryBanner }}
            </p>
        </div>
    </section>
</template>
<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState(["instanceConfig"])
    },
    data() {
        return {
            styleObject: {
                backgroundColor: null
            }
        };
    },
    watch: {
        instanceConfig(val) {
            if( val ) {
                this.styleObject.backgroundColor = val.secondaryBannerColor;
            }
        }
    }
};
</script>
<style lang="scss">
.secondaryBanner {
    background-color: #2565ae
}
</style>