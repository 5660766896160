<template>
  <div class="page-wrapper">
    <top-nav :show-home-link="showHomeLink" :show-user-profile-link="showUserProfileLink" />
    <secondary-banner />
    <notifications />
    <main class="content-wrapper">
      <div class="columns is-centered">
        <div class="column is-10-tablet is-9-desktop">
          <section class="section">
            <div class="pb content">
              <progress-bar :showOn="loadingFlag" />
            </div>
            <slot></slot>
          </section>
        </div>
      </div>
    </main>
    <custom-footer />
  </div>
</template>

<script>
import TopNav from "@/components/TopNav.vue";
import SecondaryBanner from "@/components/SecondaryBanner.vue";
import Notifications from "@/components/Notifications.vue";
import ProgressBar from "@/components/ProgressBar.vue";
import CustomFooter from "@/components/CustomFooter.vue";

export default {
  components: { 
    TopNav, 
    SecondaryBanner,
    Notifications, 
    ProgressBar, 
    CustomFooter },
  props: {
    loadingFlag: Boolean,
    showHomeLink: {
      type: Boolean,
      default: true
    },
    showUserProfileLink : {
      type: Boolean,
      default: true
    }
  }
};
</script>
<style lang="scss">
.page-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.content-wrapper {
  flex: 1;
}

.pb {
  margin-top: 0.5em;
}

@media print {
  .page-wrapper,
  .content-wrapper,
  .columns {
    display: block !important;
  }
}
</style>
