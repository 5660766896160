<template>
  <div v-if="relatedAccounts.length > 0">
    <div class="field">
      <div class="control">
        <div class="tags are-medium">
          <span class="tag has-text-weight-bold">Related Accounts:</span>
        </div>
      </div>
    </div>
    <div class="field">
      <div class="control">
        <div class="tags are-medium">
          <span
            v-for="account in relatedAccounts"
            :key="account.ACCOUNT.value"
            class="tag"
            ><router-link
              :to="{
                name: 'makePayment',
                params: { id: account.ACCOUNT.value }
              }"
              >{{ account.ACCOUNT.value }}</router-link
            ></span
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("accounts", ["accounts"]),
    relatedAccounts() {
      return this.accounts.filter(
        acc => acc.ACCOUNT.value != this.$route.params.id
      );
    }
  }
};
</script>
