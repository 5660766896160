<template>
    <div class="box">
      <div class="columns">
        <div class="column">
          <ul class="list">
              <li class="list-item">
                <span class="has-text-weight-bold">Payment Plan Status: </span>&nbsp;
                <span data-testid="plan-status-span">{{ plan.status }}</span>
              </li>
              <li class="list-item">
                  <span class="has-text-weight-bold">Original Enrollment Amount:</span>&nbsp;
                <span data-testid="plan-orig-enroll-amount-span">{{ plan.origEnrollAmount | formatCurrency }}</span>
              </li>
              <li class="list-item">
                  <span class="has-text-weight-bold">Scheduled Completion Date:</span>&nbsp;
                <span data-testid="plan-completion-date-span">{{ plan.completionDate | formatDate }}</span>
              </li>
          </ul>
        </div>
        <div class="column">
          <div class="buttons">
            <router-link
                :to="{ name: 'guest.payments', params: { planId: plan.id }, query: { user }}"
                custom v-slot="{ navigate }">
              <button class="button has-background-light" @click="navigate" @keypress.enter="navigate">
                See Payments
              </button>
            </router-link>
            <router-link
                :to="{ name: 'guest.schedule', params: { planId: plan.id }, query: { user }}"
                custom v-slot="{ navigate }">
              <button class="button has-background-light" @click="navigate" @keypress.enter="navigate">
                See Schedule
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
export default {
    props: ["plan","user"]
};
</script>