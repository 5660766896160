<template>
  <base-layout :loadingFlag="$store.state.loading">
    <h1 class="title">State Declarations</h1>
    <p
      class="content"
      v-for="(sd, index) in stateDeclarationsSplit"
      :key="index"
    >
      {{ sd }}
    </p>
  </base-layout>
</template>
<script>
import BaseLayout from "@/components/BaseLayout.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: { BaseLayout },
  data() {
    return {
      stateDeclarations: ""
    };
  },
  computed: {
    ...mapState("cc", ["companyConfig"]),
    stateDeclarationsSplit() {
      let arr = [];
      for (let i = 0; i < this.stateDeclarations.length; i++) {
        arr.push(this.stateDeclarations[i].split("\n"));
      }
      return arr.flat();
    }
  },
  methods: {
    ...mapActions("cc", ["fetchPaymentPortalCompanyConfig"])
  },
  beforeRouteEnter(to, from, next) {
    next(async vm => {
      await vm.fetchPaymentPortalCompanyConfig();
      vm.stateDeclarations = await vm.$store.dispatch("fetchMessages", {
        sodCode: vm.companyConfig.sdSODCode,
        relatedAccount: to.params.id
      });
    });
  }
};
</script>
<style lang="scss">
@media print {
  .media {
    display: block !important;
  }
}
</style>
