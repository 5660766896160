<template>
  <table class="table is-striped is-fullwidth">
    <thead>
      <tr>
        <th class="has-text-left">
          {{ $store.state.instanceConfig.homeCol1 }}
        </th>
        <th class="has-text-right">
          {{ $store.state.instanceConfig.homeCol2 }}
        </th>
        <th class="has-text-right">
          {{ $store.state.instanceConfig.homeCol3 }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="has-text-left">{{ $route.params.id }}</td>
        <td class="has-text-right" v-if="col2Type == 'CURRENCY'">
          {{ col2 | formatCurrency }}
        </td>
        <td class="has-text-right" v-else>
          {{ col2 }}
        </td>
        <td class="has-text-right" v-if="col3Type == 'CURRENCY'">
          {{ col3 | formatCurrency }}
        </td>
        <td class="has-text-right" v-else>
          {{ col3 }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("accounts", ["fullAccount"]),
    col2() {
      let account = this.fullAccount(this.$route.params.id);
      if (account) {
        if( account[this.$store.state.instanceConfig.homeCol2] ) {
          return account[this.$store.state.instanceConfig.homeCol2].value;
        } else {
          return 0.0;
        }
      } else {
        return 0.0;
      }
    },
    col2Type() {
      let account = this.fullAccount(this.$route.params.id);
      if (account) {
        if( account[this.$store.state.instanceConfig.homeCol2] ) {
          return account[this.$store.state.instanceConfig.homeCol2].type;
        } else {
          return "TEXT";
        }
      } else {
        return "TEXT";
      }
    },
    col3() {
      let account = this.fullAccount(this.$route.params.id);
      if (account) {
        if( account[this.$store.state.instanceConfig.homeCol3] ) {
          return account[this.$store.state.instanceConfig.homeCol3].value;
        } else {
          return 0.0
        }
      } else {
        return 0.0;
      }
    },
    col3Type() {
      let account = this.fullAccount(this.$route.params.id);
      if (account) {
        if( account[this.$store.state.instanceConfig.homeCol3] ) {
          return account[this.$store.state.instanceConfig.homeCol3].type;
        } else {
          return "TEXT";
        }
      } else {
        return "TEXT";
      }
    }
  }
};
</script>
