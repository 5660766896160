import Home from "@/views/guest/home/Home.vue";
import Confirm from "@/views/guest/confirm/Confirm.vue";
import Success from "@/views/guest/success/Success.vue";
import Cancel from "@/views/guest/cancel/Cancel.vue";
import Lookup from "@/views/guest/lookup/Lookup.vue";

export default {
    routes: [
        {
            path: "/:instanceId/guest",
            name: "guest.lookup",
            component: Lookup,
            meta: { requiresAuth: false }
        },
        {
            path: "/:instanceId/guest/home",
            name: "guest.home",
            component: Home,
            meta: { requiresAuth: false },
            props: route => ({
                user: route.query.user,
                instanceId: route.params.instanceId
            })
        },
        {
            path: "/:instanceId/guest/confirm",
            name: "guest.confirm",
            component: Confirm,
            meta: { requiresAuth: false },
            props: route => ({ user: route.query.user })
        },
        {
            path: "/:instanceId/guest/success",
            name: "guest.success",
            component: Success,
            meta: { requiresAuth: false }
        },    
        {
            path: "/:instanceId/guest/cancel",
            name: "guest.cancel",
            component: Cancel,
            meta: { requiresAuth: false }
        },
        {
            path: "/:instanceId/guest/:planId/payments",
            name: "guest.payments",
            component: () => import("@/views/guest/payments/Payments.vue"),
            meta: { requiresAuth: false },
            props: route => ({ user: route.query.user })
        },
        {
            path: "/:instanceId/guest/:planId/schedule",
            name: "guest.schedule",
            component: () => import("@/views/guest/schedule/Schedule.vue"),
            meta: { requiresAuth: false },
            props: route => ({ user: route.query.user })
        }          
    ]
};
