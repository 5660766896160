import axios from "axios";

export default {
  namespaced: true,
  state: {
    registration: {
      username: null,
      password: null,
      prompts: []
    },
    provisionalAccount: null,
    prompts: []
  },
  mutations: {
    SET_REGISTRATION: (state, registration) =>
      (state.registration = registration),
    SET_PROVISIONAL_ACCOUNT: (state, provisionalAccount) => {
      state.provisionalAccount = provisionalAccount;
    },
    SET_PROMPTS: (state, prompts) => (state.prompts = prompts)
  },
  actions: {
    async verifyRegister({ commit }, r) {
      let queryString = `instanceId=${r.instanceId}&`;
      let properties = {
        instanceId: r.instanceId
      };
      for (let i = 0; i < r.prompts.length; i++) {
        properties[r.prompts[i].name] = r.prompts[i].value;
        queryString += `${r.prompts[i].name}=${r.prompts[i].value}`;
        if (i < r.prompts.length - 1) {
          queryString += "&";
        }
      }

      let response = await axios.post(
        process.env.VUE_APP_API_URL + "public/registration/verify",
        queryString,
        {
          validateStatus: (status) => (status < 500),
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          }
        }
      );
      
      if( response.status == 404 ) {
        throw new Error("Account Not Found");
      }

      let r2 = Object.assign({}, r);
      delete r2.prompts;
      r2.properties = properties; // sod prompts plus instanceId
      commit("SET_REGISTRATION", r2);
      commit("SET_PROVISIONAL_ACCOUNT", response.data);
    },
    async addRegistration({ state }) {
      await axios.post(
        process.env.VUE_APP_API_URL + "public/registration/register",
        state.registration,
        {
          Accept: "application/json"
        }
      );
    },
    async fetchSODPrompts({ commit }, sodCode) {
      let { data } = await axios.get(
        process.env.VUE_APP_API_URL + `public/strategies/${sodCode}/prompts`
      );
      commit("SET_PROMPTS", data);
    }
  }
};
