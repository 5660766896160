const DELIM = "*";

export default function(plans, planSubmission) {

    if( plans && plans.length > 0 ) {
        if( planSubmission && planSubmission.amounts ) {
            const lineItems = [];
            for( let i=0; i< plans.length; i++ ) {
                const plan = plans[i];
                const amount = planSubmission.amounts.find( a => a.id == plan.id );
                if( amount ) {
                    const tokens = [
                        i+1,
                        plan.userPart1,
                        "",
                        "",
                        "MTA Payment Plan",
                        "MTA Payment Plan", // formerly plan.status
                        plan.id,
                        plan.linkKeyDbtNo,
                        plan.clientCode,
                        plan.userPart9,
                        amount.value
                    ];
                    const li = "[" + tokens.join("*") + "]";
                    lineItems.push( li );
                }
            }
            return lineItems.join(",");
        }
    }

    return null;

};
