import axios from "axios";

export default {
  namespaced: true,
  state: {
    accounts: [],
    account: {}, // cached for performance reasons
    interest: {},
    stateCompliance: {}
  },
  mutations: {
    SET_ACCOUNTS(state, accounts) {
      state.accounts = accounts;
    },
    SET_ACCOUNT: (state, account) => {
      state.account = account;
    },
    SET_INTEREST: (state, interest) => {
      state.interest = interest;
    },
    SET_STATE_COMPLIANCE: (state, stateCompliance) => {
      state.stateCompliance = stateCompliance;
    }
  },
  actions: {
    async fetchAccounts({ commit, rootState }) {
      let { data } = await axios.get(
        process.env.VUE_APP_API_URL +
          "api/demographics/listM;user=" +
          rootState.username,
        {
          headers: {
            Authorization: "Bearer " + rootState.jwt
          }
        }
      );
      commit("SET_ACCOUNTS", data);
    },
    async fetchAccount2({ commit, state, rootState }, id) {
      if (
        (Object.keys(state.account).length === 0 &&
          state.account.constructor === Object) ||
        state.account.ACCOUNT.value != id
      ) {
        commit("SET_ACCOUNT", {});
        let { data } = await axios.get(
          process.env.VUE_APP_API_URL +
            "api/demographics;user=" +
            rootState.username,
          {
            params: {
              accountNo: id
            },
            headers: {
              Authorization: "Bearer " + rootState.jwt
            }
          }
        );
        commit("SET_ACCOUNT", data);
      }
    }
  },
  getters: {
    fullAccount: (state, _getters, rootState) => accountNo => {
      if (rootState.globalConfig.rowAccount) {
        return state.accounts.find(
          a => a[rootState.globalConfig.rowAccount].value == accountNo
        );
      } else {
        return null;
      }
    },
    dueValue: (_state, getters, rootState) => accountNo => {
      let key = rootState.globalConfig.rowDue;
      if (key) {
        let acct = getters.fullAccount(accountNo);
        if (acct) {
          return acct[key].value;
        }
      }
      return null;
    },
    pmtDispositionValue: (_state, getters, rootState) => accountNo => {
      let key = rootState.globalConfig.rowPmtDisposition;
      if (key) {
        let acct = getters.fullAccount(accountNo);
        if (acct) {
          return acct[key].value;
        }
      }
      return null;
    },
    demIdtyNoValue: (_state, getters, rootState) => accountNo => {
      let key = rootState.globalConfig.rowDemIdtyNo;
      if (key) {
        let acct = getters.fullAccount(accountNo);
        if (acct) {
          return acct[key].value;
        }
      }
      return null;
    }
  }
};
