/**
 * Root application mutatations
 */
const mutations = {
  ADD_REGISTRATION(state, registration) {
    state.registration = registration;

    if (state.registration != null) {
      state.registration.username = "NEWUSERNAME";
      state.registration.password = "NEWPASSWORD";
    }
  },
  SET_PAYMENT_IN_PROGRESS_POSTED(state, paymentInProgressPosted) {
    state.paymentInProgressPosted = paymentInProgressPosted;
  },
  SET_PAYMENT_IN_PROGRESS(state, payment) {
    state.paymentInProgress = payment;
  },
  SET_PAYMENT_IN_PROGRESS_FEE_AMOUNT(state, feeAmount) {
    if (state.paymentInProgress) {
      state.paymentInProgress.feeAmount = feeAmount;
    }
  },
  SET_PAYMENT_RESULT(state, result) {
    state.paymentResult = result;
  },
  SET_JWT(state, jwt) {
    state.jwt = jwt;
  },
  SET_ARRANGEMENT(state, arrangement) {
    state.arrangement = arrangement;
  },
  SET_USERNAME(state, username) {
    state.username = username;
  },
  SET_AUTH_CODE(state, authCode) {
    state.authCode = authCode;
  },
  SET_GLOBAL_ERROR(state, e) {
    state.g_showError = e.showError;
    state.g_errorMessage = e.errorMessage;
  },
  SET_GLOBAL_NOTIFICATION(state, n) {
    if (n) {
      state.notificationMessage = n.notificationMessage;
      state.showNotification = n.showNotification;
      state.notificationType = n.notificationType;
    } else {
      state.notificationMessage = null;
      state.showNotification = false;
      state.notificationType = null;
    }
  },
  SET_GLOBAL_SHOW_NOTIFICATION(state, showNotification) {
    state.showNotification = showNotification;
  },
  SET_GLOBAL_CONFIG(state, globalConfig) {
    state.globalConfig = globalConfig;
  },
  SET_INSTANCE_CONFIG(state, instanceConfig) {
    state.instanceConfig = instanceConfig;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_LOGO_URL(state, logoURL) {
    state.globalConfig.logoURL = logoURL;
  },
  SET_DEMOGRAPHICS(state, demographics) {
    state.demographics = demographics;
  },
  SET_DISCLOSURES_ACKNOWLEDGED(state, disclosuresAcknowledged) {
    state.disclosuresAcknowledged = disclosuresAcknowledged;
  },
  SET_USER_INFO(state, userInfo) {
    state.userInfo.firstName = userInfo.first_name;
    state.userInfo.fullName = userInfo.name;
    state.userInfo.demdbtno = userInfo.demdbtno;
    state.userInfo.allowedPayTypes = userInfo.allowedPayTypes;
    state.userInfo.allowedPaySelects = userInfo.allowedPaySelects;
  },
  SET_USER_PROFILE(state, userProfile) {
    state.userProfile.phone = userProfile.phone;
    state.userProfile.email = userProfile.email;
  },
  SET_ACCEPT_REGE(state, acceptRegE) {
    if (state.paymentInProgress) {
      state.paymentInProgress.acceptRegE = acceptRegE;
    }
  },
  SET_SD_DISCLOSURE: (state, disclosure) => {
    if (disclosure) {
      state.sdDisclosureText = disclosure.disclosureText;
      state.sdDisclosureLabel = disclosure.disclosureLabel;
    } else {
      state.sdDisclosureText = null;
      state.sdDisclosureText = null;
    }
  },
  SET_REGE_DISCLOSURE: (state, disclosure) => {
    if (disclosure) {
      state.regeDisclosureText = disclosure.disclosureText;
      state.regeDisclosureLabel = disclosure.disclosureLabel;
    } else {
      state.regeDisclosureText = null;
      state.regeDisclosureText = null;
    }
  },
  SET_ADDITIONAL_SUCCESS_MESSAGES: (state, additionalSuccessMessages) => {
    state.additionalSuccessMessages = additionalSuccessMessages;
  },
  SET_NEGOTIATOR_SOD2_RESULTS: (state, results) => {
    state.negotiatorSOD2Results = results;
  },
  SET_NEGOTIATOR_OFFER_AMOUNT: (state, amount) => {
    state.negotiatorOfferAmount = amount;
  },
  SET_NEGOTIATOR_OFFER_DATE: (state, date) => {
    state.negotiatorOfferDate = date;
  },
  SET_NEGOTIATOR_SOD2_FORM_FIELDS: (state, payload) => {
    state.negotiatorSOD2FormFields = payload.inputs;
    state.negotiatorSOD2RetVals = payload.outputs;
  },
  SET_DOCS_LIST(state, docsList) {
    state.docsList = docsList;
  },
  ADD_RECORD_TO_DOCSLIST(state, { object }) {
    state.docsList.unshift(object);
  },
  SET_WEB_LINKS(state, webLinks) {
    state.webLinks = webLinks;
  },
  SET_COMPANY_CONFIG(state, companyConfig) {
    state.companyConfig = companyConfig;
  },
  SET_SD_MESSAGES(state, sdMessages) {
    state.sdMessages = sdMessages;
  },
  SET_OVERTIME_POROVT_RESULTS(state, sodResults) {
    state.overTimePOROVTResults = sodResults;
  },
  SET_CONF_NUMBER(state, confNumber) {
    state.confNumber = confNumber;
  }
};

export default mutations;
