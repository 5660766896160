<template>
  <nav 
    class="navbar is-info" 
    role="navigation" 
    aria-label="main navigation" 
    :style="{ backgroundColor: instanceConfig.headerColor }"
    >
    <div class="navbar-brand">
      <router-link v-if="instanceConfig.mode == 'GUEST' && !instanceConfig.logoHREF" class="navbar-item"
                   :to="{ name: 'guest.lookup' }"
                    data-testid="guest-home-brand-link">
        <img :src="instanceConfig.logoURL" alt="Logo" class="logo" /><span
          class="is-size-6"
          >{{ instanceConfig.header }}</span
        >
      </router-link>
      <a class="navbar-item" :href="instanceConfig.logoHREF" v-else-if="instanceConfig.logoHREF">
        <img :src="instanceConfig.logoURL" alt="Logo" class="logo" /><span
          class="is-size-6"
        >{{ instanceConfig.header }}</span
      >
      </a>
      <router-link v-else class="navbar-item" :to="{ name: 'home' }"
        data-testid="home-brand-link">
        <img :src="instanceConfig.logoURL" alt="Logo" class="logo" /><span
          class="is-size-6"
          >{{ instanceConfig.header }}</span
        >
      </router-link>
      <a
        role="button"
        class="navbar-burger"
        aria-label="menu"
        aria-expanded="false"
        :class="{ 'is-active': activated }"
        @click="activated = !activated"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>
    <div class="navbar-menu" :class="{ 'is-active': activated }">
      <div class="navbar-end">
        <router-link
            v-if="instanceConfig.mode == 'GUEST' && $route.name != 'guest.home'"
            class="navbar-item"
            :to="{ name: 'guest.home', query: { user: $route.query.user } }"
            data-testid="guest-home-link">
          Home
        </router-link>
        <router-link v-if="instanceConfig.mode != 'GUEST' && showHomeLink"
            class="navbar-item"
            :to="{ name: 'home' }"
            data-testid="home-link">
          Home
        </router-link>
        <router-link
          class="navbar-item"
          v-if="$route.params.id && showButton"
          :to="{ name: 'documents' }"
        >
          {{ instanceConfig.documentsButtonLabel }}
        </router-link>
        <router-link
          class="navbar-item"
          :to="{ name: 'stateDeclarations' }"
          v-if="$route.params.id && instanceConfig.stateDeclarationsEnabled"
        >
          State Declarations
        </router-link>
        <router-link 
          class="navbar-item" 
          :to="{ name: 'termsAndConditions' }" 
          v-if="$route.params.id && instanceConfig.termsAndConditionsEnabled">
          Terms &amp; Conditions
        </router-link>
        <router-link 
          class="navbar-item" 
          :to="{ name: 'privacyPolicy' }"
          v-if="$route.params.id && instanceConfig.privacyPolicyEnabled">
          Privacy Policy
        </router-link>
        <hr class="navbar-divider" />
        <router-link 
          class="navbar-item" 
          :to="{ name: 'contactUs' }"
          v-if="$route.params.id && instanceConfig.contactUsEnabled"
          >
          Contact Us
        </router-link>
        <hr class="navbar-divider" />
        <router-link 
          class="navbar-item" 
          :to="{ name: 'changePassword' }"
          v-if="$route.params.id && instanceConfig.changePasswordEnabled"
          >Change Password</router-link
        >
        <router-link v-if="instanceConfig.mode != 'GUEST' && showUserProfileLink"
          class="navbar-item"
          :to="{ name: 'userProfile' }"

          >User Profile</router-link
        >
        <hr class="navbar-divider" />
        <a
            class="navbar-item"
            @click="doLogout"
            v-if="instanceConfig.signOutEnabled"
        >Sign Out</a
        >
      </div>
    </div>
  </nav>
</template>
<script>
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  props: {
    showHomeLink: {
      type: Boolean,
      default: true
    },
    showUserProfileLink : {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      activated: false
    };
  },
  computed: {
    ...mapState(["instanceConfig", "jwt"]),
    showButton() {
      return this.instanceConfig.documentsEnabled;
    }
  },
  methods: {
    ...mapMutations(["SET_LOADING", "SET_GLOBAL_NOTIFICATION"]),
    ...mapActions(["logout"]),
    async doLogout() {
      try {
        this.SET_LOADING(true);
        this.SET_GLOBAL_NOTIFICATION();
        sessionStorage.removeItem('otp_info')
        if (this.jwt) {
          await this.logout();
        }
      } catch (e) {
        this.SET_GLOBAL_NOTIFICATION({
          showNotification: true,
          notificationMessage: `Error logging out ${e}`,
          notificationType: "error"
        });
      } finally {
        this.SET_LOADING(false);
        this.$router.push({ name: "login" });
      }
    }
  }
};
</script>
<style lang="scss">
.logo {
  margin-right: 0.5em;
}
</style>
