<template>
  <base-layout :loadingFlag="$store.state.loading">
    <nav class="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li>
          <router-link
            :to="{ name: 'home', query: { tenant: $route.query.tenant } }"
          >
            <span class="icon is-small">
              <font-awesome-icon icon="home" />
            </span>
            <span>Home</span></router-link
          >
        </li>
        <li>
          <router-link
            :to="{
              name: 'makePayment',
              params: { id: $route.params.id }
            }"
          >
            <span class="icon is-small">
              <font-awesome-icon icon="file-invoice-dollar" />
            </span>
            <span>Make Payment</span></router-link
          >
        </li>
        <li class="is-active">
          <router-link
            :to="{
              name: 'details',
              params: { id: $route.params.id },
              query: { tenant: $route.query.tenant }
            }"
          >
            <span class="icon is-small">
              <font-awesome-icon icon="info-circle" />
            </span>
            <span>Details</span>
          </router-link>
        </li>
      </ul>
    </nav>
    <div class="content">
      <user-info />
    </div>

    <h1 class="title is-6">Details</h1>
    <ul class="list">
      <li
        class="list-item"
        v-for="{ label, type, value } in displayableValues"
        :key="label"
      >
        <p>
          <strong>{{ label }}</strong>
        </p>
        <p v-if="type == 'CURRENCY'">
          {{ value | formatCurrency }}
        </p>
        <p v-else-if="type == 'DATE'">
          {{ value | formatDate }}
        </p>
        <p v-else>{{ value }}</p>
      </li>
    </ul>
    <router-link
      :to="{
        name: 'makePayment',
        params: { id: $route.params.id },
        query: { tenant: $route.query.tenant }
      }"
      class="button"
      >Back</router-link
    >
  </base-layout>
</template>

<script>
import BaseLayout from "@/components/BaseLayout.vue";
import UserInfo from "@/components/UserInfo.vue";

export default {
  components: { BaseLayout, UserInfo },
  data() {
    return {
      account: {}
    };
  },
  computed: {
    displayableValues() {
      return Object.values(this.account).filter(rec => rec.display == "Y");
    }
  },
  async mounted() {
    this.account = await this.$store.dispatch(
      "fetchAccount",
      this.$route.params.id
    );
  }
};
</script>
