import axios from "axios";

export default {
  namespaced: true,
  state: () => ({
    acceptRegE: false,
    sdDisclosureText: null,
    sdDisclosureLabel: null,
    regeAcknowledgeLine: null,
    rege: [],
    rege1Label: null,
    rege2Label: null,
    rege3Label: null,
    rege4Label: null
  }),
  mutations: {
    SET_ACCEPT_REG_E: (state, acceptRegE) => (state.acceptRegE = acceptRegE),
    SET_SD_DISCLOSURE_TEXT: (state, sdDisclosureText) =>
      (state.sdDisclosureText = sdDisclosureText),
    SET_SD_DISCLOSURE_LABEL: (state, sdDisclosureLabel) =>
      (state.sdDisclosureLabel = sdDisclosureLabel),
    SET_REG_E_ACKNOWLEDGE_LINE: (state, regeAcknowledgeLine) =>
      (state.regeAcknowledgeLine = regeAcknowledgeLine),
    SET_REG_E: (state, rege) => (state.rege = rege),
    SET_REG_E_1_LABEL: (state, rege1Label) => (state.rege1Label = rege1Label),
    SET_REG_E_2_LABEL: (state, rege2Label) => (state.rege2Label = rege2Label),
    SET_REG_E_3_LABEL: (state, rege3Label) => (state.rege3Label = rege3Label),
    SET_REG_E_4_LABEL: (state, rege4Label) => (state.rege4Label = rege4Label)
  },
  actions: {
    async fetchRegEConfig({ rootState, commit }) {
      // fetch PPCC
      let { data } = await axios.get(
        process.env.VUE_APP_API_URL +
          "api/udw/paymentPortal;user=" +
          rootState.username,
        {
          headers: {
            Authorization: "Bearer " + rootState.jwt
          }
        }
      );
      commit("SET_REG_E_ACKNOWLEDGE_LINE", data.regeAcknowledgeLine);
      commit("SET_REG_E_1_LABEL", data.rege1Label);
      commit("SET_REG_E_2_LABEL", data.rege2Label);
      commit("SET_REG_E_3_LABEL", data.rege3Label);
      commit("SET_REG_E_4_LABEL", data.rege4Label);
    },
    async fetchRegEMessages({ commit, rootState }, params) {
      let { data } = await axios.get(
        process.env.VUE_APP_API_URL +
          "api/regeMessages;user=" +
          rootState.username,
        {
          params: {
            option: params.option,
            action: params.action,
            relatedAccount: params.relatedAccount
          },
          headers: {
            Authorization: "Bearer " + rootState.jwt
          }
        }
      );
      commit("SET_REG_E", data);
    },
    async saveRegEAcceptance({ rootState, state }, { demIdtyNo }) {
      await axios.post(
        process.env.VUE_APP_API_URL +
          "api/demographics/acknowledge;user=" +
          rootState.username,
        {
          demPortalUsername: rootState.username,
          demIdtyNo: demIdtyNo,
          disclosureLabel: state.sdDisclosureLabel,
          disclosureText: state.sdDisclosureText
        },
        {
          headers: {
            Authorization: "Bearer " + rootState.jwt
          }
        }
      );
    }
  },
  getters: {}
};
