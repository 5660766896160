<template>
  <progress v-show="showOn" class="progress is-info is-small" max="100"
    >15%</progress
  >
</template>
<script>
export default {
  props: ["showOn"]
};
</script>
