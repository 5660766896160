import axios from "axios";
import moment from "moment";

function nowDataBaseDateFormat() {
  const d = new Date()
  return `${d.getFullYear()}-${String(d.getMonth()+1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')}`
}

export default {
  namespaced: true,
  state: () => ({
    parameters: {},
    preview: [],
    arrangement: [],
    paymentInfo: {},
    result: {}
  }),
  mutations: {
    SET_PARAMETERS: (state, parameters) => (state.parameters = parameters),
    SET_PREVIEW: (state, preview) => (state.preview = preview),
    SET_ARRANGEMENT: (state, arr) => (state.arrangement = arr),
    SET_PAYMENT_INFO: (state, paymentInfo) => (state.paymentInfo = paymentInfo),
    SET_RESULT: (state, result) => (state.result = result)
  },
  actions: {
    async previewArrangement({ commit, state }, payload) {
      commit("SET_PARAMETERS", payload);
      let queryString =
        "accountNo=" +
        state.parameters.accountNo +
        "&paymentAmount=" +
        state.parameters.paymentAmount +
        "&numberOfPayments=" +
        0 + // dbio will determine
        "&firstPaymentDate=" +
        state.parameters.firstPaymentDate +
        "&planCode=" +
        state.parameters.planCode +
        "&totalAmount=" +
        state.parameters.totalAmount +
        "&timezone=" +
        state.parameters.timezone +
        "&fee=" +
        payload.fee;
      let { data } = await axios.post(
        process.env.VUE_APP_API_URL +
          "api/paymentArrangements;user=" +
          state.parameters.username,
        queryString,
        {
          headers: {
            Authorization: "Bearer " + state.parameters.jwt,
            "Content-Type": "application/x-www-form-urlencoded"
          }
        }
      );
      commit("SET_PREVIEW", data);
    },
    async createArrangement({ rootState, commit, state }) {
      let queryString =
        "accountNo=" +
        state.parameters.accountNo +
        "&paymentAmount=" +
        state.parameters.paymentAmount +
        "&numberOfPayments=" +
        0 + // dbio will determine
        "&firstPaymentDate=" +
        state.parameters.firstPaymentDate +
        "&planCode=" +
        state.parameters.planCode +
        "&totalAmount=" +
        state.parameters.totalAmount +
        "&timezone=" +
        state.parameters.timezone +
        "&fee=0.0" + // recalculated later
        "&preview=false" +
        "&reminderId=" + rootState.instanceConfig.ppaDemId;
      let { data } = await axios.post(
        process.env.VUE_APP_API_URL +
          "api/paymentArrangements;user=" +
          state.parameters.username,
        queryString,
        {
          headers: {
            Authorization: "Bearer " + state.parameters.jwt,
            "Content-Type": "application/x-www-form-urlencoded"
          }
        }
      );
      commit("SET_ARRANGEMENT", data);
    },
    async deleteArrangement({ commit, state }) {
      await axios.delete(
        process.env.VUE_APP_API_URL +
          "api/paymentArrangements;user=" +
          state.parameters.username +
          "/" +
          state.parameters.accountNo,
        {
          headers: {
            Authorization: "Bearer " + state.parameters.jwt,
            "Content-Type": "application/x-www-form-urlencoded"
          }
        }
      );
      commit("SET_PARAMETERS", []);
      commit("SET_PREVIEW", []);
      commit("SET_ARRANGEMENT", []);
    },
    async updateArrangement(
      { commit, rootState, state },
      { instanceId, refNum, demIdtyNo, fee }
    ) {
      let payload = {}
      if (state.paymentInfo != null && state.paymentInfo.paymentOption != null) {

        const p_opt = Number(state.paymentInfo.paymentOption)
        switch (p_opt) {
          case 0:
          case 2: {

              const prefilled = state.paymentInfo.prefilled && (state.paymentInfo.ccNumber.indexOf("*") > -1)

              payload = {
                refNum: refNum,
                demIdtyNo: demIdtyNo,
                firstName: state.paymentInfo.firstName,
                lastName: state.paymentInfo.lastName,
                address: state.paymentInfo.billingAddress,
                city: state.paymentInfo.city,
                state: state.paymentInfo.state,
                zipCode: state.paymentInfo.zipCode,
                paymentOption: state.paymentInfo.paymentOption,
                cardNumber: state.paymentInfo.ccNumber,
                expDateMonth: state.paymentInfo.ccExpDateMonth,
                expDateYear: state.paymentInfo.ccExpDateYear,
                ccv: state.paymentInfo.cvv,
                paymentDate: nowDataBaseDateFormat(),
                demPortalUsername: rootState.username,
                ppaType: "1",
                prefilled: prefilled,
                fee: fee
              }
            }
            break
          case 1:
          case 3: {
              payload = {
                refNum: refNum,
                demIdtyNo: demIdtyNo,
                firstName: state.paymentInfo.firstName,
                lastName: state.paymentInfo.lastName,
                address: state.paymentInfo.billingAddress,
                city: state.paymentInfo.city,
                state: state.paymentInfo.state,
                zipCode: state.paymentInfo.zipCode,
                paymentOption: state.paymentInfo.paymentOption,
                paymentDate: nowDataBaseDateFormat(),
                demPortalUsername: rootState.username,
                ppaType: "1",
                prefilled: false, // prefilled doesn't matter b/c info not masked
                fee: fee,
                routingNumber: state.paymentInfo.routingNumber,
                accountNumber: state.paymentInfo.bankAccountNumber,
                checkNo: state.paymentInfo.checkNo,
                checkPos: "R"
              }
            }
            break
          default:
            throw new Error("unrecognized paymentOption=" + state.paymentInfo.paymentOption)
        }

        let response = await fetch(
            `${process.env.VUE_APP_API_URL}api/payments/info/${instanceId}/${refNum};user=${rootState.username}`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + rootState.jwt,
                "Accept": "application/json"
              },
              body: JSON.stringify(payload)
            }
        )

        let data = await response.json()

        commit("SET_RESULT", data);

      } else {
        throw new Error("state.paymentInfo or state.paymentInfo.paymentOption is null")
      }
    }
  },
  getters: {
    includeInterest: state => usePreview => {
      if( usePreview && state.preview ) {
        return state
            .preview
            .map( p => Number(p.interestAmount) )
            .reduce((accumulator, currentValue) => {
              return accumulator + currentValue
              }, 0) > 0;
      }
      if( !usePreview && state.arrangement ) {
        return state
            .arrangement
            .map( p => Number(p.interestAmount) )
            .reduce((accumulator, currentValue) => {
              return accumulator + currentValue
            }, 0) > 0;
      }
      return false;
    }
  }
};
