<template>
  <base-layout :loadingFlag="$store.state.loading || pageLoading">
    <breadcrumbs :links="links" />
    <div class="content">
      <user-info />
    </div>
    <account-header />
    <div class="columns">
      <div class="column is-one-quarter is-hidden-mobile">
        <sidebar />
      </div>
      <div class="column">
        <div class="box" v-if="!$store.state.loading && !allowPortalPayments">
          <p class="notification is-warning">
            Access to your Account has been restricted please call {{ companyPhoneNumber }}
          </p>
        </div>
        <div class="box" v-if="!$store.state.loading && belowMinTotalDue">
          <p class="notification is-warning">
            The total due of {{ dueValue($route.params.id) | formatCurrency }}
            on this account is below the {{ instanceConfig.minTotalDue | formatCurrency }}
            minimum to allow payments.
          </p>
        </div>
        <div class="box" v-if="showPaymentOptions && needsSD && !belowMinTotalDue">
          <div class="field">
            <div class="control">
              <label class="label">
                <input type="checkbox" v-model="sdAccepted" />
                {{ sdAcknowledgeLine }}
              </label>
            </div>
          </div>
          <p class="notification is-warning">
            You must read and acknowledge the following before continuing.
          </p>
          <p
            class="content"
            v-for="(sm, index) in stateDeclarationsSplit"
            :key="index"
          >
            {{ sm }}
          </p>
        </div>
        <div class="columns" v-if="showPaymentOptions">
          <div class="column">
            <make-payment-card
              :title="payOptionTitle"
              :content="payOptionContent"
              toName="paymentForm"
              :toQuery="{
                action: 'full',
                needsSD: needsSD,
                sdAccepted: sdAccepted
              }"
              :buttonLabel="payOptionButton"
              :buttonDisabled="
                $store.state.loading || pageLoading || (needsSD && !sdAccepted) || !allowPortalPayments || belowMinTotalDue
              "
            />
          </div>
          <div class="column">
            <make-payment-card
              :title="oneOptionTitle"
              :content="oneOptionContent"
              toName="paymentForm"
              :toQuery="{
                action: 'oneTime',
                needsSD: needsSD,
                sdAccepted: sdAccepted
              }"
              :buttonLabel="oneOptionButton"
              :buttonDisabled="
                $store.state.loading || pageLoading || (needsSD && !sdAccepted) || !allowPortalPayments || belowMinTotalDue
              "
            />
          </div>
          <div
            class="column"
            v-if="
              globalConfig.negotiatorEnabled &&
                fullAccount($route.params.id) &&
                (pmtDispositionValue($route.params.id) == 'P' ||
                  pmtDispositionValue($route.params.id) == 'M')
            "
          >
            <make-payment-card
              :title="instanceConfig.negotiatorOptionTitle"
              :content="instanceConfig.negotiatorOptionContent"
              toName="negotiatorStart"
              :buttonLabel="instanceConfig.negotiatorOptionButton"
              :buttonDisabled="
                $store.state.loading || pageLoading || (needsSD && !sdAccepted) || !allowPortalPayments || belowMinTotalDue
              "
            />
          </div>
          <div class="column" v-if="showRecurring">
            <make-payment-card
              :title="updOptionTitle"
              :content="updOptionContent"
              toName="paymentForm"
              :toQuery="{
                action: 'next',
                needsSD: needsSD,
                sdAccepted: sdAccepted
              }"
              :buttonLabel="updOptionButton"
              :buttonDisabled="
                $store.state.loading || pageLoading || (needsSD && !sdAccepted) || !allowPortalPayments || belowMinTotalDue
              "
            />
          </div>
          <div class="column" v-if="showOverTime">
            <make-payment-card
              :title="instanceConfig.overTimeTitle"
              :content="instanceConfig.overTimeContent"
              toName="overTime"
              :buttonLabel="instanceConfig.overTimeButton"
              :buttonDisabled="
                $store.state.loading || pageLoading || (needsSD && !sdAccepted) || !allowPortalPayments || belowMinTotalDue
              "
            />
          </div>
        </div>
        <div class="field">
          <div class="buttons">
            <router-link
              :to="{
                name: 'home',
                params: { id: $route.params.id }
              }"
              class="button"
              >Back</router-link
            >
          </div>
        </div>
        <div class="level options" v-if="showPaymentOptions">
          <div class="level-left">
            Payment options:
            <span
              v-for="(allowedType, index) in allowedPayTypeIcon"
              :key="index"
              class="opt"
            >
              <img
                :src="allowedPayTypeIcon[index].src()"
                :alt="allowedPayTypeIcon[index].alt"
              />
            </span>
          </div>
        </div>
        <div class="content">
          <account-tags />
        </div>
        <div class="buttons are-medium is-marginless">
          <router-link
              custom
            :to="{
              name: 'transactionHistory',
              params: { id: $route.params.id }
            }"
            v-slot="{navigate}"
            ><button @click="navigate" class="button is-fullwidth has-background-grey-lighter">See Payments</button></router-link
          >
          <router-link
              custom
            :to="{ name: 'schedule', params: { id: $route.params.id } }"
              v-slot="{navigate}"
            ><button @click="navigate"  class="button is-fullwidth has-background-grey-lighter">Schedule</button></router-link
          >
          <router-link
              custom
            :to="{ name: 'details', params: { id: $route.params.id } }"
              v-slot="{navigate}"
            ><button @click="navigate"  class="button is-fullwidth has-background-grey-lighter is-hidden-tablet">Account Details</button></router-link
          >
        </div>
      </div>
    </div>
  </base-layout>
</template>

<script>
import BaseLayout from "@/components/BaseLayout.vue";
import AccountHeader from "@/components/AccountHeader.vue";
import { mapGetters, mapActions, mapState } from "vuex";
import UserInfo from "@/components/UserInfo.vue";
import Sidebar from "@/components/Sidebar.vue";
import AccountTags from "@/components/AccountTags.vue";
import MakePaymentCard from "@/components/payment/MakePaymentCard.vue";
import Filters from "@/utils/filters";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import sod from "@/utils/sod";
import fetchMakePaymentPage from "@/activities/FetchMakePaymentPage";

export default {
  components: {
    BaseLayout,
    AccountHeader,
    UserInfo,
    Sidebar,
    AccountTags,
    MakePaymentCard,
    Breadcrumbs
  },
  computed: {
    ...mapState(["instanceConfig", "globalConfig", "overTimePOROVTResults","userInfo"]),
    ...mapGetters("accounts", [
      "fullAccount",
      "dueValue",
      "pmtDispositionValue"
    ]),
    needsSD() {
      return this.sdMessages != null && this.sdMessages.length > 0;
    },
    payOptionTitle() {
      if (!this.instanceConfig.payOptionTitle) {
        return "Full Payment";
      }
      return this.instanceConfig.payOptionTitle;
    },
    oneOptionTitle() {
      if (!this.instanceConfig.oneOptionTitle) {
        return "One-Time Payment";
      }
      return this.instanceConfig.oneOptionTitle;
    },
    updOptionTitle() {
      if (!this.instanceConfig.updOptionTitle) {
        return "Update Payment Info";
      }
      return this.instanceConfig.updOptionTitle;
    },
    payOptionContent() {
      let val = "";
      if (this.fullAccount(this.$route.params.id)) {
        val +=
          " " + Filters.formatCurrency(this.dueValue(this.$route.params.id));
      }
      if (!this.instanceConfig.payOptionContent) {
        return "Make a full payment in the amount of" + val;
      }
      return this.instanceConfig.payOptionContent + val;
    },
    oneOptionContent() {
      if (!this.instanceConfig.oneOptionContent) {
        return "Make a one-time payment";
      }
      return this.instanceConfig.oneOptionContent;
    },
    updOptionContent() {
      if (!this.instanceConfig.updOptionContent) {
        return "Update Payment Info";
      }
      return this.instanceConfig.updOptionContent;
    },
    payOptionButton() {
      if (!this.instanceConfig.payOptionButton) {
        return "Pay In Full";
      }
      return this.instanceConfig.payOptionButton;
    },
    oneOptionButton() {
      if (!this.instanceConfig.oneOptionButton) {
        return "Make One-Time Payment";
      }
      return this.instanceConfig.oneOptionButton;
    },
    updOptionButton() {
      if (!this.instanceConfig.updOptionButton) {
        return "Update Payment Info";
      }
      return this.instanceConfig.updOptionButton;
    },
    stateDeclarationsSplit() {
      let arr = [];
      for (let i = 0; i < this.sdMessages.length; i++) {
        arr.push(this.sdMessages[i].split("\n"));
      }
      return arr.flat();
    },
    allowedPayTypeIcon() {
      let allPayTypes = this.userInfo.allowedPayTypes
        ? this.userInfo.allowedPayTypes
        : [];
      let arr = [];
      for (let i = 0; i < allPayTypes.length; i++) {
        let imgInfo = {};
        if (allPayTypes[i] == "ACH") {
          imgInfo = {
            src: () => require("@/assets/paymentOptionIcons/ACH_Icon.png"),
            alt: "ach_icon",
            tooltip: "ACH"
          };
        } else if (allPayTypes[i] == "VISA") {
          imgInfo = {
            src: () => require("@/assets/paymentOptionIcons/Visa_Icon.png"),
            alt: "visa_icon",
            tooltip: "Visa"
          };
        } else if (allPayTypes[i] == "CHECK") {
          imgInfo = {
            src: () => require("@/assets/paymentOptionIcons/eCheck_Icon.png"),
            alt: "echeck_icon",
            tooltip: "eCheck"
          };
        } else if (allPayTypes[i] == "DISCOVER") {
          imgInfo = {
            src: () => require("@/assets/paymentOptionIcons/Discover_Icon.png"),
            alt: "discover_icon",
            tooltip: "Discover"
          };
        } else if (allPayTypes[i] == "AMEX") {
          imgInfo = {
            src: () => require("@/assets/paymentOptionIcons/Amex_Icon.png"),
            alt: "amex_icon",
            tooltip: "Amex"
          };
        } else if (allPayTypes[i] == "MC") {
          imgInfo = {
            src: () =>
              require("@/assets/paymentOptionIcons/Mastercard_Icon.png"),
            alt: "mastercard_icon",
            tooltip: "Mastercard"
          };
        }
        arr.push(imgInfo);
      }
      return arr;
    },
    showPaymentOptions() {
      return (
          this.fullAccount(this.$route.params.id) &&
          this.dueValue(this.$route.params.id) > 0 &&
          (this.pmtDispositionValue(this.$route.params.id) == "M" ||
            this.pmtDispositionValue(this.$route.params.id) == "S" ||
            this.pmtDispositionValue(this.$route.params.id) == "P" ||
            this.pmtDispositionValue(this.$route.params.id) == "N")
      );
    },
    showOverTime() {
      if (this.$store.getters.allowPmtArr) {
        if (this.pmtDispositionValue(this.$route.params.id) == "S") {
          if (sod.anyTrue(this.overTimePOROVTResults)) {
            return true;
          }
        }
      }
      return false;
    },
    showRecurring() {
      return (
        this.pmtDispositionValue(this.$route.params.id) == "M" ||
        this.pmtDispositionValue(this.$route.params.id) == "L" ||
        this.pmtDispositionValue(this.$route.params.id) == "P"
      );
    },
    allowPortalPayments() {
      return this.$store.state.accounts.stateCompliance.allowPortalPayments
    },
    belowMinTotalDue() {
      if( this.instanceConfig.minTotalDue ) {
        return Number(this.dueValue(this.$route.params.id)) < Number(this.instanceConfig.minTotalDue);
      }
      return false;
    }
  },
  data() {
    return {
      action: null,
      sdAccepted: false,
      sdMessages: [],
      sdLabel: null,
      sdAcknowledgeLine: null,
      pageLoading: true,
      links: [
        { label: "Home", icon: "home", to: { name: "home" } },
        {
          label: "Make Payment",
          icon: "dollar-sign",
          to: { name: "makePayment", params: { id: this.$route.params.id } },
          isActive: true
        }
      ],
      companyPhoneNumber: null
    };
  },
  methods: {
    ...mapActions("accounts", ["fetchAccounts"])
  },
  async beforeRouteLeave(to, from, next) {
    // record exact wording of terms of service
    if (this.needsSD && this.sdAccepted && this.sdMessages != null) {
      await this.$store.commit("SET_SD_DISCLOSURE", {
        disclosureLabel: this.sdLabel,
        disclosureText: this.sdMessages.join()
      });
    }
    next();
  },
  beforeRouteEnter(to, from, next) {
    next(async vm => {
      try {
        vm.$store.commit("SET_LOADING", true);
        vm.pageLoading = true;
        await fetchMakePaymentPage({
          instanceId: vm.$route.params.instanceId,
          accountNo: vm.$route.params.id
        });
        vm.sdMessages = vm.$store.state.sdMessages;
        vm.sdAcknowledgeLine = vm.$store.state.companyConfig.sdAcknowledgeLine;
        vm.sdLabel = vm.$store.state.companyConfig.sdLabel;
        vm.companyPhoneNumber = vm.$store.state.companyConfig.companyPhoneNumber;
        vm.$store.commit("SET_SD_DISCLOSURE"); // clear wording
      } catch (e) {
        vm.$store.commit("SET_GLOBAL_NOTIFICATION", {
          showNotification: true,
          notificationMessage: `Error fetching makePayment page for ${
            vm.$route.params.id
          }; ${e}`,
          notificationType: "error"
        });
      } finally {
        vm.$store.commit("SET_LOADING");
        vm.pageLoading = false;
      }
    });
  },
  async beforeRouteUpdate(to, from, next) {
      try {
        this.$store.commit("SET_LOADING", true);
        this.pageLoading = true;
        await fetchMakePaymentPage({
          instanceId: to.params.instanceId,
          accountNo: to.params.id
        });
        this.sdMessages = this.$store.state.sdMessages;
        this.sdAcknowledgeLine = this.$store.state.companyConfig.sdAcknowledgeLine;
        this.sdLabel = this.$store.state.companyConfig.sdLabel;
        this.companyPhoneNumber = this.$store.state.companyConfig.companyPhoneNumber;
        this.$store.commit("SET_SD_DISCLOSURE"); // clear wording
      } catch (e) {
        this.$store.commit("SET_GLOBAL_NOTIFICATION", {
          showNotification: true,
          notificationMessage: `Error fetching makePayment page for ${
              this.$route.params.id
          }; ${e}`,
          notificationType: "error"
        });
      } finally {
        this.$store.commit("SET_LOADING");
        this.pageLoading = false;
        next();
      }
  }
};
</script>

<style lang="scss" scoped>
.opt {
  margin-left: 0.5rem;
}
</style>
