<template>
    <nav class="breadcrumb" aria-label="breadcrumbs">
        <ul>
            <home-nav-list-item />    
            <li class="is-active">
                <router-link
                    :to="{ name: 'guest.cancel',
                    params: { user: $route.query.user } }"
                >
                    <span class="icon is-small has-text-danger">
                    <font-awesome-icon icon="times-circle" />
                    </span>
                    <span>Payment Cancelled</span></router-link
                >
            </li>
        </ul>
    </nav>
</template>
<script>
import HomeNavListItem from "../commons/HomeNavListItem.vue";

export default {
    components: { HomeNavListItem }
};
</script>