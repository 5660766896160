var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-layout',[_c('h1',{staticClass:"title"},[_vm._v("Registration")]),_c('p',{staticClass:"content"},[_vm._v(" Fill in and submit the required information to claim your account ")]),_c('validation-observer',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('prompt-input-control',{attrs:{"prompts":_vm.m_prompts},on:{"prompts-updated":_vm.updatePrompts}}),(_vm.instanceConfig.loginIdentifier === 'EMAIL')?_c('validation-provider',{staticClass:"field",attrs:{"rules":"required|min:8|max:100|email|found","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Email")]),_c('div',{staticClass:"control"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.username),expression:"username"}],staticClass:"input",attrs:{"type":"text","name":"Email"},domProps:{"value":(_vm.username)},on:{"input":function($event){if($event.target.composing){ return; }_vm.username=$event.target.value}}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors != null && errors.length > 0),expression:"errors != null && errors.length > 0"}],staticClass:"has-text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}):_c('validation-provider',{staticClass:"field",attrs:{"rules":"required|alpha_num|min:8|max:100|found","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Pick a username")]),_c('div',{staticClass:"control"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.username),expression:"username"}],staticClass:"input",attrs:{"type":"text","name":"Username"},domProps:{"value":(_vm.username)},on:{"input":function($event){if($event.target.composing){ return; }_vm.username=$event.target.value}}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors != null && errors.length > 0),expression:"errors != null && errors.length > 0"}],staticClass:"has-text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}])}),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('validation-provider',{staticClass:"field",attrs:{"rules":"required|min:8|max:40|password:@confirm","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"control"},[_c('label',{staticClass:"label"},[_vm._v("Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newPassword),expression:"newPassword"}],staticClass:"input",attrs:{"type":"password","name":"Password"},domProps:{"value":(_vm.newPassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.newPassword=$event.target.value}}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors != null && errors.length > 0),expression:"errors != null && errors.length > 0"}],staticClass:"has-text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('div',{staticClass:"column"},[_c('validation-provider',{staticClass:"field",attrs:{"vid":"confirm","rules":"required|min:8|max:40","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"control"},[_c('label',{staticClass:"label"},[_vm._v("Confirm Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.confirmNewPassword),expression:"confirmNewPassword"}],staticClass:"input",attrs:{"type":"password","name":"Confirm Password"},domProps:{"value":(_vm.confirmNewPassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.confirmNewPassword=$event.target.value}}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors != null && errors.length > 0),expression:"errors != null && errors.length > 0"}],staticClass:"has-text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)]),_c('div',{staticClass:"buttons"},[_c('button',{staticClass:"button is-success",attrs:{"disabled":invalid},on:{"click":_vm.submit}},[_vm._v(" Continue ")]),_c('router-link',{attrs:{"to":{ name: 'login' }}},[_c('button',{staticClass:"button is-light"},[_vm._v("Cancel")])])],1)]}}])}),_c('registration-loading-modal',{attrs:{"loadingFlag":_vm.loading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }