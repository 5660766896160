<template>
    <nav class="breadcrumb" aria-label="breadcrumbs">
        <ul>
            <home-nav-list-item :active="true" :user="user" />
        </ul>
    </nav>
</template>
<script>
import HomeNavListItem from "../commons/HomeNavListItem.vue";

export default {
  components: { HomeNavListItem },
  props: ["user"]
};
</script>