const getters = {
  formattedName: state => {
    if (state.paymentInProgress == null) {
      return null;
    }
    return (
      state.paymentInProgress.firstName + " " + state.paymentInProgress.lastName
    );
  },
  maskedAccountNum: state => {
    if (state.paymentInProgress == null) {
      return null;
    }

    var num = null;
    if (state.paymentInProgress.paymentOption === "1") {
      num = state.paymentInProgress.accountNumber; // Check
    } else {
      num = state.paymentInProgress.cardNumber;
    }

    return num.substring(num.length - 4, num.length);
  },
  getFormParam: state => formField => {
    if (state.negotiatorSOD2FormFields) {
      return state.negotiatorSOD2FormFields[formField];
    }
    return null;
  },
  getRetVal: state => retVal => {
    if (state.negotiatorSOD2RetVals) {
      return state.negotiatorSOD2RetVals[retVal];
    }
    return null;
  },
  allowPmtArr: state => {
    if (state.arrangement !== undefined) {
      if (
        state.arrangement.ppa_status !== undefined &&
        state.arrangement.ppa_status == "B"
      ) {
        return false;
      }
      // #1649 need to add in check on state.arrangement.phr_post_date
    }
    return true;
  }
};

export default getters;
