var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-layout',{attrs:{"loadingFlag":_vm.$store.state.loading}},[_c('nav',{staticClass:"breadcrumb",attrs:{"aria-label":"breadcrumbs"}},[_c('ul',[_c('li',[_c('router-link',{attrs:{"to":{ name: 'home', query: { tenant: _vm.$route.query.tenant } }}},[_c('span',{staticClass:"icon is-small"},[_c('font-awesome-icon',{attrs:{"icon":"home"}})],1),_c('span',[_vm._v("Home")])])],1),_c('li',{staticClass:"is-active"},[_c('router-link',{attrs:{"to":{
            name: 'changePassword',
            query: { tenant: _vm.$route.query.tenant }
          }}},[_c('span',{staticClass:"icon is-small"},[_c('font-awesome-icon',{attrs:{"icon":"lock"}})],1),_c('span',[_vm._v("Change Password")])])],1)])]),_c('div',{staticClass:"content"},[_c('user-info')],1),_c('h1',{staticClass:"title is-6"},[_vm._v("Change Password")]),_c('p',{staticClass:"content"},[_vm._v(" Save this form to change your password. You will be logged out and returned to the login screen. Use your new password. ")]),_c('ValidationObserver',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var invalid = ref.invalid;
return [_c('ValidationProvider',{staticClass:"field",attrs:{"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('div',{staticClass:"control"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentPassword),expression:"currentPassword"}],staticClass:"input",attrs:{"name":"Current Password","type":"password","placeholder":"Current Password"},domProps:{"value":(_vm.currentPassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.currentPassword=$event.target.value}}}),_c('small',{staticClass:"has-text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('ValidationProvider',{staticClass:"field",attrs:{"rules":"required|min:8|max:40|password:@confirm","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('div',{staticClass:"control"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newPassword),expression:"newPassword"}],staticClass:"input",attrs:{"type":"password","placeholder":"New Password","name":"New Password"},domProps:{"value":(_vm.newPassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.newPassword=$event.target.value}}}),_c('small',{staticClass:"has-text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('ValidationProvider',{staticClass:"field",attrs:{"name":"confirm","rules":"required|min:8|max:40","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('div',{staticClass:"control"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.confirmNewPassword),expression:"confirmNewPassword"}],staticClass:"input",attrs:{"type":"password","placeholder":"Confirm New Password","name":"Confirm New Password"},domProps:{"value":(_vm.confirmNewPassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.confirmNewPassword=$event.target.value}}}),_c('small',{staticClass:"has-text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('div',{staticClass:"buttons"},[_c('button',{staticClass:"button is-success",attrs:{"disabled":invalid || _vm.$store.state.loading},on:{"click":_vm.save}},[_vm._v(" Save ")]),_c('router-link',{staticClass:"button is-light",attrs:{"to":{ name: 'home', query: { tenant: _vm.$route.query.tenant } },"tag":"button"}},[_vm._v("Cancel")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }