<template>
  <base-layout :loadingFlag="loading">
    <h1 class="title">Confirm Registration</h1>
    <p class="notification is-warning" v-if="!$store.state.loading && instanceConfig.loginIdentifier === 'EMAIL' && instanceConfig.emailDisclosure">
      {{ instanceConfig.emailDisclosure }}
    </p>
    <p class="content">
      Continue registering this account
    </p>
    <div class="columns">
      <div class="column">
        <span class="has-text-weight-bold">Name</span>
      </div>
      <div class="column">
        {{ provisionalAccount.name }}
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <span class="has-text-weight-bold">Address</span>
      </div>
      <div class="column">
        {{ provisionalAccount.address }}
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <span class="has-text-weight-bold">City</span>
      </div>
      <div class="column">
        {{ provisionalAccount.city }}
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <span class="has-text-weight-bold">State</span>
      </div>
      <div class="column">
        {{ provisionalAccount.state }}
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <span class="has-text-weight-bold">Zip Code</span>
      </div>
      <div class="column">
        {{ provisionalAccount.zipCode }}
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <span class="has-text-weight-bold">Phone #1</span>
      </div>
      <div class="column">
        {{ provisionalAccount.phone1 | formatPhone }}
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <span class="has-text-weight-bold">Phone #2</span>
      </div>
      <div class="column">
        {{ provisionalAccount.phone1 | formatPhone }}
      </div>
    </div>

    <div class="buttons">
      <button class="button is-success" @click="post">
        Register
      </button>
      <button class="button is-light" @click="cancel">Cancel</button>
    </div>
  </base-layout>
</template>
<script>
import BaseLayout from "@/components/RegistrationBaseLayout.vue";
import { mapState, mapActions } from "vuex";
import store from "../../store/index";

function unloadListener(event) {
  event.preventDefault();
  event.returnValue = "";
}

export default {
  components: { BaseLayout },
  computed: {
    ...mapState(["loading","instanceConfig"]),
    ...mapState("registration", ["provisionalAccount"])
  },
  methods: {
    ...mapActions(["fetchPaymentPortalInstanceConfig"]),
    ...mapActions("registration", ["addRegistration"]),
    async post() {
      try {
        this.$store.commit("SET_GLOBAL_NOTIFICATION");

        await this.addRegistration();
        this.$router.push({
          name: "registrationCompleted",
          query: { tenant: this.$route.query.tenant }
        });
      } catch (e) {
        let msg = e;
        if (e.response) {
          if (e.response.status == 409) {
            // conflict
            msg =
              "The username has been taken.  Please press 'Cancel' to select a different one.";
          }
        }
        this.$store.commit("SET_GLOBAL_NOTIFICATION", {
          showNotification: true,
          notificationMessage: msg,
          notificationType: "error"
        });
      }
    },
    cancel() {
      this.$store.commit("SET_GLOBAL_NOTIFICATION");
      this.$router.push({ name: "registrationForm" });
    }
  },
  beforeRouteEnter(to, from, next) {
    if (store.state.registration.provisionalAccount) {
      next(vm => {
        vm.$store.commit("SET_LOADING", true);
        window.addEventListener("beforeunload", unloadListener);
        try {
          vm.fetchPaymentPortalInstanceConfig(to.params.instanceId);
        } catch (e) {
          this.$store.commit("SET_GLOBAL_NOTIFICATION", {
            showNotification: true,
            notificationMessage: e.errorMessage,
            notificationType: "error"
          });
        } finally {
          vm.$store.commit("SET_LOADING", false);
        }
      });
    } else {
      // hard refresh that was confirmed by user
      next({
        name: "registrationForm",
        params: { instanceId: to.params.instanceId }
      });
    }
  },
  beforeRouteLeave(to, from, next) {
    window.removeEventListener("beforeunload", unloadListener);
    next();
  }
};
</script>
