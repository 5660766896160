export default function (prompts) {
    // #1419 dem_dbt_no or dbt_no need to be padded
    if( prompts ) {
        for (let i = 0; i < prompts.length; i++) {
            let p = prompts[i];
            if (p.name == "dem_dbt_no" || p.name == "dbt_no") {
                p.value = p.value.padStart(9, "0");
            }
        }
    }
};
