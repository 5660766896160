import axios from "axios";

export default async function(username, payload) {
    await axios.post(
        process.env.VUE_APP_API_URL +
            "public/plans/history;user=" +
            username ,
        payload
        );
}
