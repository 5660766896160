<template>
  <p
    v-show="$store.state.showNotification"
    class="notification is-radiusless"
    :class="{
      'is-danger': $store.state.notificationType == 'error',
      'is-success': $store.state.notificationType == 'success',
      'is-info':
        $store.state.notificationType != 'error' &&
        $store.state.notificationType != 'success'
    }"
  >
    <button class="delete" @click="clearNotification"></button>
    {{ $store.state.notificationMessage }}
  </p>
</template>

<script>
export default {
  methods: {
    clearNotification() {
      this.$store.commit("SET_GLOBAL_NOTIFICATION");
    }
  }
};
</script>
