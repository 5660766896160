<template>
  <base-layout :loadingFlag="$store.state.loading">
    <nav class="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li>
          <router-link :to="{ name: 'home' }">
            <span class="icon is-small">
              <font-awesome-icon icon="home" />
            </span>
            <span>Home</span></router-link
          >
        </li>
        <li>
          <router-link
            :to="{ name: 'makePayment', params: { id: $route.params.id } }"
          >
            <span class="icon is-small">
              <font-awesome-icon icon="file-invoice-dollar" />
            </span>
            <span>Make Payment</span></router-link
          >
        </li>

        <li class="is-active">
          <router-link
            :to="{ name: 'confirm', params: { id: $route.params.id } }"
          >
            <span class="icon is-small">
              <font-awesome-icon icon="dollar-sign" />
            </span>
            <span>Confirm</span>
          </router-link>
        </li>
      </ul>
    </nav>
    <div class="content">
      <user-info />
    </div>

    <account-header />
    <div class="columns">
      <div class="column is-one-quarter is-hidden-mobile">
        <sidebar />
      </div>
      <div class="column">
        <h1 class="title is-6">Confirm</h1>
        <div class="columns">
          <div class="column is-half">
            <table class="table is-fullwidth">
              <tbody>
                <tr>
                  <td>Payment</td>
                  <td><money-span :value="amount" /></td>
                </tr>

                <tr v-if="feeResult.amount > 0">
                  <td>+ Convenience fee</td>
                  <td>
                    <money-span
                      :value="feeResult.amount"
                      :isUnderlined="true"
                    />
                  </td>
                </tr>
                <tr>
                  <td>Total</td>
                  <td><money-span :value="amount + feeResult.amount" /></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <p class="content" v-if="paymentDate">
          <span v-if="action == 'full'"
            >You will be making the full payment of
            {{ (amount + feeResult.amount) | formatCurrency }} to your account
            on {{ paymentDate | formatDate }}.</span
          >
          <span v-else-if="action == 'next'"
            >A payment of
            {{ (amount + feeResult.amount) | formatCurrency }} will be processed
            on {{ paymentDate | formatDate }}.</span
          >
          <span v-else
            >A payment of
            {{ (amount + feeResult.amount) | formatCurrency }} will be processed
            on {{ paymentDate | formatDate }}.</span
          >
        </p>

        <div class="content" v-if="action == 'next'">
          <payment-arrangements :id="$route.params.id" />
        </div>

        <p v-for="msg in confirmMessagesSplit" :key="msg.id" class="content">
          {{ msg.text }}
        </p>
        <p class="content">
          Press <strong>{{ payButtonLabel }}</strong> to process payment.
        </p>
        <div class="buttons">
          <button
            class="button is-success"
            @click="post"
            :disabled="$store.state.loading"
          >
            {{ payButtonLabel }}
          </button>
          <router-link
            :to="{ name: 'makePayment', params: { id: $route.params.id } }"
            tag="button"
            class="button is-light"
            >Cancel</router-link
          >
        </div>
      </div>
    </div>
  </base-layout>
</template>

<script>
import AccountHeader from "@/components/AccountHeader.vue";
import BaseLayout from "@/components/BaseLayout.vue";
import UserInfo from "@/components/UserInfo.vue";
import Sidebar from "@/components/Sidebar.vue";
import store from "../store/index";
import MoneySpan from "@/components/payment/MoneySpan";
import PaymentArrangements from "@/components/PaymentArrangements.vue";
import { mapState, mapActions } from "vuex";

function unloadListener(event) {
  // Cancel the event as stated by the standard.
  event.preventDefault();
  // Chrome requires returnValue to be set.
  event.returnValue = "";
}

export default {
  data() {
    return {
      action: null,
      amount: null,
      payButtonLabel: "Pay",
      additionalConfirmMessages: "",
      paymentDate: null
    };
  },
  components: {
    BaseLayout,
    AccountHeader,
    UserInfo,
    Sidebar,
    MoneySpan,
    PaymentArrangements
  },
  mounted() {
    this.action = this.paymentInProgress.action;
    this.amount = Number(this.paymentInProgress.amount);
  },
  computed: {
    ...mapState(["paymentInProgress"]),
    ...mapState("pay", ["feeResult"]),
    ...mapState("cc", ["companyConfig"]),
    confirmMessagesSplit() {
      let newArray = this.additionalConfirmMessages.split("\n");
      newArray = newArray.map(elem => ({ id: this.idgen++, text: elem }));
      return newArray;
    }
  },
  methods: {
    ...mapActions(["finalizePayment","fetchPaymentArrangements"]),
    ...mapActions("pay", ["calculateFee"]),
    ...mapActions("cc", ["fetchPaymentPortalCompanyConfig"]),
    async post() {
      try {
        this.$store.commit(
          "SET_PAYMENT_IN_PROGRESS_FEE_AMOUNT",
          this.feeResult.amount
        );
        await this.finalizePayment();
        this.$router.push({
          name: "completed",
          params: { id: this.$route.params.id }
        });
      } catch (e) {
        this.$store.commit("SET_GLOBAL_NOTIFICATION", {
          showNotification: true,
          notificationMessage: `Error finalizing payment ${e}`,
          notificationType: "error"
        });
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    if (!store.state.paymentInProgress) {
      next({ name: "account", params: { id: to.params.id } });
    } else {
      next(async vm => {
        window.addEventListener("beforeunload", unloadListener);
        await vm.fetchPaymentPortalCompanyConfig();
        let sodCode = vm.companyConfig.confirmSODCode;
        if (vm.action == "next") {
          sodCode = vm.companyConfig.recurringSODCode;
        }

        if (sodCode) {
          vm.additionalConfirmMessages = await vm.$store.dispatch(
            "fetchMessages",
            {
              sodCode: sodCode,
              relatedAccount: to.params.id
            }
          );
          if (vm.additionalConfirmMessages) {
            vm.additionalConfirmMessages = vm.additionalConfirmMessages.join();
          }
        }
        let phrType = "4";
        if (vm.paymentInProgress.paymentOption == 1) {
          phrType = "1";
        }
        await vm.calculateFee({
          account: to.params.id,
          phrType: phrType,
          amount: vm.paymentInProgress.amount
        });

        let dateSet = false;
        if( vm.action == 'next' ) {
          const arrangements = await vm.fetchPaymentArrangements(vm.$route.params.id);
          if( arrangements && arrangements.length > 0 ) {
            const firstRow = arrangements[0];
            const dateDue = firstRow["Date Due"];
            if( dateDue ) {
              vm.paymentDate = dateDue.value;
              dateSet = true;
            }
          }
        }
        if( !dateSet ) {
            vm.paymentDate = new Date().toLocaleDateString();
        }
      });
    }
  },
  beforeRouteLeave(to, from, next) {
    window.removeEventListener("beforeunload", unloadListener);
    next();
  }
};
</script>
