<template>
  <base-layout :loadingFlag="$store.state.loading">
    <h1 class="title">Contact Us</h1>
    <p class="content">
      {{ instanceConfig.generalContactMessage }}
    </p>
    <div class="field">
      <div class="buttons">
        <router-link
          tag="button"
          class="button is-link"
          :to="{ name: 'login', query: { tenant: $route.query.tenant } }"
        >
          Back To Login
        </router-link>
      </div>
    </div>
  </base-layout>
</template>
<script>
import BaseLayout from "@/components/RegistrationBaseLayout.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: { BaseLayout },
  computed: {
    ...mapState(["instanceConfig"])
  },
  methods: {
    ...mapActions(["fetchPaymentPortalInstanceConfig"])
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.fetchPaymentPortalInstanceConfig(vm.$route.params.instanceId);
    });
  }
};
</script>
