import axios from "axios";

export default {
  namespaced: true,
  state: {
    companyConfig: null
  },
  mutations: {
    SET_COMPANY_CONFIG: (state, companyConfig) =>
      (state.companyConfig = companyConfig)
  },
  actions: {
    async fetchPaymentPortalCompanyConfig({ rootState, commit }) {
      let { data } = await axios.get(
        process.env.VUE_APP_API_URL +
          "api/udw/paymentPortal;user=" +
          rootState.username,
        {
          headers: {
            Authorization: "Bearer " + rootState.jwt
          }
        }
      );
      commit("SET_COMPANY_CONFIG", data);
    }
  }
};
