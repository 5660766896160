<template>
  <div>
    <div :class="{ 'is-hidden-mobile': supportMobile }">
      <table :class="tableClasses">
        <thead>
          <tr>
            <th
              v-for="(hdr, index) in headers"
              :key="hdr.label"
              :class="{
                'has-text-left': index == 0,
                'has-text-right':
                  (hdr.type == 'CURRENCY' && index > 0) ||
                  index == headers.length - 1,
                'has-text-centered': hdr.type != 'CURRENCY' && index > 0
              }"
            >
              <!-- hack: return label obj with hide flag or 'suppressHeaders' list -->
              <span v-if="hdr.label != 'FIRST_COLUMN'">{{
                lookupLabel(hdr)
              }}</span>
              <span>&nbsp;</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(d, index) in data"
            :key="index"
            @click="$emit('rowClick', d[rowKey]['value'])"
            :class="{ 'is-selected': selectedRow == d[rowKey]['value'] }"
          >
            <td
              v-for="(hdr, index) in headers"
              :key="hdr.label"
              :class="{
                'has-text-left': index == 0,
                'has-text-right':
                  (hdr.type == 'CURRENCY' && index > 0) ||
                  index == headers.length - 1,
                'has-text-centered': hdr.type != 'CURRENCY' && index > 0
              }"
            >
              <span v-if="d[hdr.label] == null">&nbsp;</span>
              <!-- 
                right now "GRAPHIC" is fixed to only one graphic rendering 
                -->
              <span v-else-if="d[hdr.label].type == 'GRAPHIC'">
                <span
                  v-html="d[hdr.label].value"
                  class="has-text-info has-text-weight-bold is-size-7"
                />
              </span>
              <span v-else-if="d[hdr.label].type == 'CURRENCY'">{{
                d[hdr.label].value | formatCurrency
              }}</span>
              <span v-else-if="d[hdr.label].type == 'DATE'">{{
                d[hdr.label].value | formatDate
              }}</span>
              <span v-else>{{ d[hdr.label].value }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      v-if="supportMobile"
      :class="{
        'is-hidden-tablet': supportMobile,
        'is-hidden-widescreen-only': supportMobile
      }"
    >
      <div class="card content" v-for="(d, index) in data" :key="index">
        <div class="card-content">
          <div v-for="hdr in headers" :key="hdr.label">
            <div class="columns is-mobile">
              <div class="column">
                <strong>{{ lookupLabel(hdr) }}</strong>
              </div>
              <div class="column">
                <span v-if="d[hdr.label] == null">&nbsp;</span>
                <span v-else-if="d[hdr.label].type == 'CURRENCY'">{{
                  d[hdr.label].value | formatCurrency
                }}</span>
                <span v-else-if="d[hdr.label].type == 'DATE'">{{
                  d[hdr.label].value | formatDate
                }}</span>
                <span v-else>{{ d[hdr.label].value }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tableClasses: {
      type: String,
      required: true
    },
    headers: {
      type: Array,
      required: true
    },
    data: {
      type: Array,
      required: true
    },
    selectedRow: {
      type: String,
      required: false,
      default: null
    },
    rowKey: {
      type: String,
      required: false,
      default: "ACCOUNT"
    },
    supportMobile: {
      type: Boolean,
      required: false,
      default: false
    },
    alternateDisplayLabelMap: {
      type: Map,
      required: false
    }
  },
  methods: {
    lookupLabel(hdr) {
      if (this.alternateDisplayLabelMap) {
        let altLabel = this.alternateDisplayLabelMap.get(hdr.label);
        if (altLabel) {
          return altLabel;
        } else {
          return hdr.label;
        }
      } else {
        return hdr.label;
      }
    }
  }
};
</script>
