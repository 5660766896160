const state = {
  jwt: null,
  username: null,
  arrangement: {},
  paymentInProgressPosted: false,
  paymentInProgress: null,
  paymentResult: null,
  authCode: null,
  paymentOutcome: null,
  showNotification: false,
  notificationMessage: null,
  notificationType: null,
  loading: false,
  globalConfig: {},
  instanceConfig: {},
  companyConfig: {},
  demographics: [],
  disclosuresAcknowledged: false,
  userInfo: {
    firstName: null,
    fullName: null,
    demdbtno: null,
    allowedPayTypes: [],
    allowedPaySelects: []
  },
  userProfile: {
    phone: null,
    email: null,
  },
  sdDisclosureText: null,
  regeDisclosureText: null,
  regeDisclosureType: null,
  regeDisclosureLabel: null,
  additionalSuccessMessages: "",
  sdMessages: null,
  negotiatorSOD2Results: {},
  negotiatorOfferAmount: null,
  negotiatorOfferDate: null,
  negotiatorSOD2FormFields: {},
  negotiatorSOD2RetVals: {},
  docsList: {},
  webLinks: {},
  negotiatorPARRNGResults: {},
  negotiatorPARRNGPrompts: {},
  negotiatorPARRNGFormFields: {},
  negotiatorPARRNGRetVals: {},
  overTimePOROVTResults: [],
  confNumber: null
};

export default state;
