<template>
  <article class="message">
    <div class="message-body">
      <p class="content" data-testid="message-content">
        {{ message }}
      </p>
      <div class="buttons">
        <button data-testid="yes-button"
            class="button is-success"
            :disabled="invalid || loading"
                @click="$emit('single-payment-confirmed')"
        >
          Yes
        </button>
        <button class="button" data-testid="cancel-button"
        :disabled="invalid || loading"
                @click="$emit('single-payment-cancelled')"
        >
        Cancel
        </button>
      </div>
    </div>
  </article>
</template>

<script>
import filters from "@/utils/filters.js";

export default {
  name: "CorePaymentSinglePayment",
  props: [
      "amount",
      "pastDueAmt",
      "nextSchedPmtAmt",
      "currPeriodOverpayment",
      "totalPaymentDue",
      "loading",
      "invalid"
  ],
  computed: {
    message() {
      if( this.amount < this.pastDueAmt ) {
        // Scenario #1
        return `The entered amount is less than the past due amount of ${filters.formatCurrency(this.pastDueAmt)}. Would you like to continue?`;
      } else if( this.amount > this.pastDueAmt && this.pastDueAmt > 0 ) {
        // Scenario #3
        return `The entered amount will pay the current past due amount of ${filters.formatCurrency(this.pastDueAmt)} any excess amount will be applied to the account balance. However this will not affect the processing of the next upcoming scheduled payment. Would you like to continue?`
      } else if( this.amount < this.nextSchedPmtAmt &&
          this.pastDueAmt == 0 &&
          this.currPeriodOverpayment >= 0 ) {
        // Scenario #4
        return `The entered amount is less than the current scheduled payment amount of ${filters.formatCurrency(this.nextSchedPmtAmt)}. Would you like to continue?`
      } else if( this.amount >= this.nextSchedPmtAmt &&
        this.pastDueAmt == 0 &&
        this.currPeriodOverpayment >= 0
      ) {
        // Scenario #5
        return `The entered amount will be applied to the current scheduled payment of ${filters.formatCurrency(this.nextSchedPmtAmt)} and the excess amount will be applied to the account balance. However this will not affect the processing of the following scheduled payment. Would you like to continue?`;
      } else {
        return "";
      }
    }
  }
}
</script>
