<template>
  <div class="card">
    <header class="card-header">
      <h1 class="card-header-title">
        {{ title }}
      </h1>
    </header>
    <div class="card-content">
      <div class="content">
        {{ content }}
      </div>
    </div>
    <div class="card-footer">
      <router-link
          custom
        :disabled="buttonDisabled"
        :to="{
          name: toName,
          query: toQuery
        }"
          v-slot="{ navigate }"
        ><button @click="navigate" class="card-footer-item button has-background-info has-text-white is-radiusless">
          {{ buttonLabel }}
      </button></router-link
      >
    </div>
  </div>
</template>
<script>
export default {
  props: [
    "title",
    "content",
    "toName",
    "toQuery",
    "buttonLabel",
    "buttonDisabled"
  ]
};
</script>
<style lang="scss">
.card {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.card-content {
  height: 100%;
}
</style>
