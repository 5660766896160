import Vue from "vue";
import App from "./App.vue";
import router from "./router/index";
import store from "./store/index";
import Filters from "@/utils/filters";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faSyncAlt,
  faArrowAltCircleRight,
  faDollarSign,
  faLevelUpAlt,
  faLevelDownAlt,
  faReply,
  faLock,
  faUser,
  faHome,
  faFileInvoiceDollar,
  faHistory,
  faInfoCircle,
  faArrowUp,
  faPrint,
  faCalendar,
  faSpinner,
  faUserFriends,
  faCheck,
  faExclamationCircle,
  faMinusCircle,
  faPlusCircle,
  faTimesCircle,
  faIdBadge
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import { required, numeric } from "vee-validate/dist/rules";
import LogRocket from 'logrocket'

library.add(faSyncAlt);
library.add(faArrowAltCircleRight);
library.add(faDollarSign);
library.add(faLevelUpAlt);
library.add(faLevelDownAlt);
library.add(faReply);
library.add(faLock);
library.add(faUser);
library.add(faHome);
library.add(faFileInvoiceDollar);
library.add(faHistory);
library.add(faInfoCircle);
library.add(faArrowUp);
library.add(faPrint);
library.add(faCalendar);
library.add(faSpinner);
library.add(faUserFriends);
library.add(faCheck);
library.add(faExclamationCircle);
library.add(faMinusCircle);
library.add(faPlusCircle);
library.add(faTimesCircle);
library.add(faIdBadge);

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

/**
 * These filters will be available to all SFCs without requiring individual filters
 * sections.
 */
Vue.filter("formatDate", Filters.formatDate);
Vue.filter("formatCurrency", Filters.formatCurrency);
Vue.filter("formatPhone", Filters.formatPhone);
Vue.filter("formatMethod", Filters.formatMethod);

Vue.component("validation-observer", ValidationObserver);
Vue.component("validation-provider", ValidationProvider);

router.beforeEach(async (to, from, next) => {
  if (to.params.instanceId) {
    await store.dispatch(
        "fetchPaymentPortalInstanceConfig",
        to.params.instanceId
    );

    if( process.env.NODE_ENV == "production" && store.state.instanceConfig.logRocketAppId ) {
      // this add'l config redacts sensitive info
      LogRocket.init(store.state.instanceConfig.logRocketAppId , {
        dom: {
          textSanitizer: true,
          inputSanitizer: true,
        },
        network: {
          requestSanitizer: request => {
            if (request.url.toLowerCase().indexOf('/payments') !== -1) {
              // scrub out the body
              request.body = null;
            }
            if (request.url.toLowerCase().indexOf('/otp') !== -1) {
              request.body = null;
            }
            if (request.url.toLowerCase().indexOf('/login') !== -1) {
              request.body = null;
            }

            return request;
          },
        }
      })
    }

    document.title = store.state.instanceConfig.documentTitle;
    (function(link) {
      let $favicon = document.querySelector('link[rel="icon"]');
      if ($favicon !== null) {
        $favicon.href = link;
      } else {
        $favicon = document.createElement("link");
        $favicon.rel = "icon";
        $favicon.href = link;
        document.head.appendChild($favicon);
      }
    })(store.state.instanceConfig.faviconURL);
  }
  if (to.meta.requiresAuth == false) {
    next(); // always ok to go straight to login page or public registration pages
  } else {
    if (store.state.jwt) {
      let retval = await store.dispatch("authOk", {
        jwt: store.state.jwt,
        username: store.state.username
      });
      if (retval) {
        next();
      } else {
        next({
          name: "login",
          params: { instanceId: to.params.instanceId },
          query: { sessionTimeout: true }
        });
      }
    } else {
      let savedJWT = localStorage.getItem("jwt");
      let savedUsername = localStorage.getItem("username");
      if (savedJWT && savedUsername) {
        store.commit("SET_JWT", savedJWT);
        store.commit("SET_USERNAME", savedUsername);
        let retval = await store.dispatch("authOk", {
          jwt: store.state.jwt,
          username: store.state.username
        });
        if (retval) {
          next();
        } else {
          next({
            name: "login",
            params: { instanceId: to.params.instanceId },
            query: { sessionTimeout: true }
          });
        }
      } else {
        next({ name: "login", params: { instanceId: to.params.instanceId } });
      }
    }
  }

});

extend("required", {
  ...required,
  message: "{_field_} is required."
});
extend("numeric", {
  ...numeric,
  message: "{_field_} must contain only numbers."
});
extend("notNaN", {
  validate: (value) => !isNaN(value),
  message: "{_field_} must be a number."
});
extend("atMostNumDigits", {
  params: ["numDigits"],
  validate: (value, { numDigits }) => {
    if (isNaN(value)) {
      return false;
    }
    if (value.indexOf('.') >= 0) {
      let decDigits = value.substring(value.indexOf('.') + 1);
      if (decDigits.length > numDigits) {
        return false;
      }
    }
    return true;
  },
  message: "{_field_} must be a number and have at most {numDigits} digits."
});

extend("noCurrencySymbol", {
  validate: value => {
    if (value.match(/\$/) && value.match(/\$/).length > 0) {
      return "Please remove the $";
    } else {
      return true;
    }
  }
});

extend("removeWhitespace", {
  validate: value => {
    if (value.match(/\s/) && value.match(/\s/).length > 0) {
      return "please remove whitespace";
    } else {
      return true;
    }
  }
});

extend("cardLength", {
  validate: value => {
    let firstChar = value[0];
    if (firstChar == "3") {
      if (value.length == 15) {
        return true;
      } else {
        return "American Express card must be 15 digits.";
      }
    } else {
      if (value.length == 16) {
        return true;
      } else {
        return "{_field_} must be 16 digits.";
      }
    }
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
