var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-layout',{attrs:{"loadingFlag":_vm.$store.state.loading}},[_c('nav',{staticClass:"breadcrumb",attrs:{"aria-label":"breadcrumbs"}},[_c('ul',[_c('li',[_c('router-link',{attrs:{"to":{ name: 'home', query: { tenant: _vm.$route.query.tenant } }}},[_c('span',{staticClass:"icon is-small"},[_c('font-awesome-icon',{attrs:{"icon":"home"}})],1),_c('span',[_vm._v("Home")])])],1),_c('li',{staticClass:"is-active"},[_c('router-link',{attrs:{"to":{
            name: 'userProfile',
            query: { tenant: _vm.$route.query.tenant }
          }}},[_c('span',{staticClass:"icon is-small"},[_c('font-awesome-icon',{attrs:{"icon":"lock"}})],1),_c('span',[_vm._v("User Profile")])])],1)])]),_c('div',{staticClass:"content"},[_c('user-info')],1),_c('h1',{staticClass:"title is-6"},[_vm._v("User Profile")]),_c('p',{staticClass:"content"},[_vm._v(" Save this form to change your contacts required to send One Time Password. ")]),_c('ValidationObserver',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var invalid = ref.invalid;
return [_c('ValidationProvider',{staticClass:"field",attrs:{"rules":"required|min:10|max:10","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('div',{staticClass:"control"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.phone),expression:"phone"}],staticClass:"input",attrs:{"name":"Phone","type":"text","placeholder":"Phone"},domProps:{"value":(_vm.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.phone=$event.target.value}}}),_c('small',{staticClass:"has-text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('ValidationProvider',{staticClass:"field",attrs:{"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('div',{staticClass:"control"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"input",attrs:{"type":"text","placeholder":"Email","name":"Email"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}),_c('small',{staticClass:"has-text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('div',{staticClass:"buttons"},[_c('button',{staticClass:"button is-success",attrs:{"disabled":invalid || _vm.$store.state.loading},on:{"click":_vm.save}},[_vm._v(" Save ")]),_c('router-link',{staticClass:"button is-light",attrs:{"to":{ name: 'home', query: { tenant: _vm.$route.query.tenant } },"tag":"button"}},[_vm._v("Cancel")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }