<template>
  <div class="page-wrapper">
    <nav class="navbar is-info" role="navigation" aria-label="main navigation">
      <div class="navbar-brand">
        <router-link class="navbar-item" :to="{ name: 'home' }">
          <img :src="instanceConfig.logoURL" alt="Logo" class="logo" /><span
            class="is-size-6"
            >{{ instanceConfig.header }}</span
          >
        </router-link>
      </div>
    </nav>
    <notifications />
    <main class="content-wrapper">
      <div class="columns is-centered mycol">
        <div class="column is-8-tablet is-6-desktop">
          <section class="section">
            <slot></slot>
          </section>
        </div>
      </div>
    </main>
    <custom-footer />
  </div>
</template>

<script>
import CustomFooter from "@/components/CustomFooter.vue";
import Notifications from "@/components/Notifications.vue";
import { mapState } from "vuex";

export default {
  components: { CustomFooter, Notifications },
  computed: {
    ...mapState(["instanceConfig"])
  }
};
</script>
<style lang="sass">
.page-wrapper
  min-height: 100vh
  display: flex
  flex-direction: column

.content-wrapper
  flex: 1
</style>
