<template>
    <base-layout>
        <confirm-nav :user="user" />
        <guest-progress :show="showProgress" :value="progressPercent" />
        <div class="columns">
            <div class="column">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Payment Plan ID Number</th>
                            <th>Payment Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="amt in planSubmission.amounts" :key="amt.id" >
                            <td>{{ amt.id }}</td>
                            <td class="has-text-right">{{ amt.value | formatCurrency }}</td>
                        </tr>
                        <tr>
                            <th>Total Payment</th>
                            <td class="has-text-right">{{ planSubmission.grandTotal | formatCurrency }}</td>
                        </tr>
                    </tbody>
                </table>
                <form :action="actionURL" method="POST" @submit.prevent="handleSubmit" ref="theform">
                    <input type="hidden" name="ReturnURL" :value="returnURL" />
                    <input type="hidden" name="CancelURL" :value="cancelURL" />
                    <input type="hidden" name="PaymentAmount" :value="planSubmission.grandTotal" />
                    <input type="hidden" name="MerchantCode" :value="merchantCode" />
                    <input type="hidden" name="UserPart1" :value="userPart1" />
                    <input type="hidden" name="UserPart2" :value="demA0Username" />
                    <input type="hidden" name="UserPart3" :value="trackingNumber" />
                    <input type="hidden" name="UserPart4" value="" />
                    <input type="hidden" name="LineItems" :value="lineItems" />
                    <div class="buttons">
                        <input type="submit" class="button is-success" value="Continue" :disabled="showProgress"/>
                        <router-link 
                            :to="{
                              name: 'guest.home',
                              params: { instanceId: $route.params.instanceId },
                              query: { user: $route.query.user }
                            }"
                            custom v-slot="{ navigate }">
                            <button class="button is-danger" :disabled="showProgress" @click="navigate" @keypress.enter="navigate">
                                Cancel
                            </button>
                        </router-link>
                    </div>
                </form>
            </div>
            <div class="column">
                <div class="message is-warning">
                  <p class="message-body">
                    {{ instanceConfig.guestConfirmMessage }}
                  </p>
                </div>
            </div>
        </div>
    </base-layout>
</template>
<script>
import BaseLayout from "@/components/BaseLayout.vue";
import ConfirmNav from "./ConfirmNav.vue";
import GuestProgress from "../commons/GuestProgress.vue";
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import formatMTALineItems from "@/utils/FormatMTALineItems";
import writeHistory from "@/activities/WriteHistory";

export default {
    components: { BaseLayout, ConfirmNav, GuestProgress },
    props: ["user"],
    data() {
      return {
          showProgress: false,
          progressPercent: 0,
          planSubmission: []
      };
    },
    computed: {
      ...mapState("g", ["demPortalUsername"]),
      actionURL() {
          return this.instanceConfig.externalURL;
      },
      returnURL() {
          return window.location.origin
              + process.env.BASE_URL
              + this.$route.params.instanceId
              + "/guest/success?user=" + this.demPortalUsername;
      },
      cancelURL() {
          return window.location.origin
              + process.env.BASE_URL
              + this.$route.params.instanceId
              + "/guest/cancel?user=" + this.demPortalUsername;
      },
      ...mapState(["instanceConfig"]),
      ...mapState("g", ["plans", "trackingNumber","demPortalUsername"]),
      ...mapGetters("g", [
          "userPart1",
          "userPart9",
          "merchantCode",
          "demA0Username"
      ]),
      lineItems() {
          return formatMTALineItems(
              this.plans,
              this.planSubmission
          )
      }
    },
    methods: {
      ...mapMutations("g", ["SET_DEMPORTALUSERNAME"]),
      ...mapActions(["fetchPaymentPortalInstanceConfig"]),
      async handleSubmit(e) {
          this.showProgress = true;
          this.progressPercent = 10;
          try {
            // #1862 writing out a css.hst to each ppg
            for( let i=0; i<this.planSubmission.amounts.length; i++ ) {
              await writeHistory(this.demPortalUsername, {
                event: "LEAVE_PORTAL",
                accountNo: this.planSubmission.amounts[i].id, // planid
                payload: this.lineItems,
                trackingNo: this.trackingNumber
              });
            }
            this.$refs.theform.submit();
          } finally {
              this.showProgress = false;
              this.progressPercent = 0;
          }
      }
    },
    beforeRouteEnter(to, from, next) {
      next(async vm => {
        try {
          vm.showProgress = true;
          vm.progressPercent = 10;

          if( !vm.demPortalUsername ) {
            vm.SET_DEMPORTALUSERNAME(vm.user);
          }

          await vm.$store.dispatch("g/fetchTrackingNumber");
          vm.progressPercent = 50;

          if( vm.$store.state.g.plans == null ||
              vm.$store.state.g.plans.length == 0 ) {
              await vm.$store.dispatch("g/fetchPlans", vm.$route.params.id);
              vm.progressPercent = 99;
          }
          vm.planSubmission = JSON.parse(sessionStorage.getItem("planSubmission"));
          vm.$store.commit("SET_GLOBAL_NOTIFICATION");
        } finally {
            vm.showProgress = false;
            vm.progressPercent = 0;
        }
      });
    }
};
</script>
