<template>
  <span class="level" :class="{ underlined: isUnderlined }">
    <span class="level-left">
      <span class="level-item">$</span>
    </span>
    <span class="level-right">
      <span class="level-item">{{ amount }}</span>
    </span>
  </span>
</template>
<script>
export default {
  props: {
    value: {
      required: false,
      type: Number
    },
    isUnderlined: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  computed: {
    amount() {
      if (this.value) {
        return this.value.toFixed(2);
      } else {
        return 0;
      }
    }
  }
};
</script>
<style>
.underlined {
  border-bottom: 1px solid black;
}
</style>
