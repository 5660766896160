<template>
  <footer 
    class="footer has-text-centered"
    :style="{ backgroundColor: instanceConfig.footerColor }"
    >
    <p class="has-text-white is-size-5">{{ instanceConfig.footer }}</p>
    <p class="has-text-white">
      <small>{{ instanceConfig.footer2 }}</small>
    </p>
  </footer>
</template>
<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["instanceConfig"])
  }
};
</script>
<style lang="scss">
.footer {
  flex-shrink: 0;
  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    z-index: 30;
    height: 8%;
  }
}
</style>
