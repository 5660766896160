import axios from "axios";

export default {
    namespaced: true,
    state: {
        plans: [],
        trackingNumber: "0",
        schedule: [],
        payments: [],
        lookupFields: [],
        lookupFieldsInitialized: false,
        demPortalUsername: null
    },
    mutations: {
        SET_PLANS: (state, plans) => state.plans = plans,
        SET_TRACKING_NUMBER: (state, trackingNumber) => state.trackingNumber = trackingNumber,
        SET_SCHEDULE: (state, schedule) => state.schedule = schedule,
        SET_PAYMENTS: (state, payments) => state.payments = payments,
        SET_LOOKUP_FIELDS: (state, lookupFields) => state.lookupFields = lookupFields,
        SET_LOOKUP_FIELDS_INITIALIZED: (state, lookupFieldsInitialized) => state.lookupFieldsInitialized = lookupFieldsInitialized,
        SET_DEMPORTALUSERNAME: (state, demPortalUsername) => state.demPortalUsername = demPortalUsername
    },
    actions: {
        async fetchPlans({commit,state}) {
            const { data } = await axios.get(
                process.env.VUE_APP_API_URL +
                  "public/plans;user=" + state.demPortalUsername
              );
            commit("SET_PLANS", data);
        },
        async fetchTrackingNumber({state, commit}) {
          const { data } = await axios.post(
            process.env.VUE_APP_API_URL +
              "public/plans/tracking;user=" +
              state.demPortalUsername,
            {}
          );
          commit("SET_TRACKING_NUMBER", data);
        },
        async fetchSchedule({commit, state}, planId) {
            const {data} = await axios.get(
                process.env.VUE_APP_API_URL +
                "public/plans/" + planId + "/schedule;user=" + state.demPortalUsername
            );
            commit("SET_SCHEDULE", data);
        },
        async fetchPayments({state, commit}, planId) {
            const {data} = await axios.get(
                process.env.VUE_APP_API_URL +
                "public/plans/" + planId + "/payments;user=" + state.demPortalUsername
            );
            commit("SET_PAYMENTS", data);
        },
        async fetchLookupFields({state, commit}) {
            if( !state.lookupFieldsInitialized ) { // cache
                const {data} = await axios.get(process.env.VUE_APP_API_URL + "public/plans/lookupFields")
                commit("SET_LOOKUP_FIELDS", data);
                commit("SET_LOOKUP_FIELDS_INITIALIZED", true);
            }
        },
        async lookupAccount({commit}, { label, value }) {
            const qs =
                encodeURIComponent(label) + "=" +
                encodeURIComponent(value);

            const { data } = await axios.get(
                process.env.VUE_APP_API_URL + "public/plans/account?" + qs, {
                headers: {
                    'Accept': 'text/plain'
                }
            });
            commit("SET_DEMPORTALUSERNAME", data);
        }
    },
    getters: {
      //
      // These getters look at just the 1st record since the values are
      // repeated with each record
      //
      userPart1: state => {
        if( state.plans.length > 0 ) {
          return state.plans[0].userPart1;
        }
        return null;
      },
      userPart9: state => {
        if( state.plans.length > 0 ) {
          return state.plans[0].userPart9;
        }
        return null;
      },
      merchantCode: state => {
        if( state.plans.length > 0 ) {
          return state.plans[0].merchantCode;
        }
        return null;
      },
      demA0Username: state => {
          if( state.plans.length > 0 ) {
              return state.plans[0].demA0Name;
          }
          return null;
      }
    }
};
