<template>
  <nav class="breadcrumb" aria-label="breadcrumbs">
    <ul>
      <li
        v-for="lnk in links"
        :key="lnk.label"
        :class="{ 'is-active': lnk.isActive }"
      >
        <router-link :to="lnk.to">
          <span class="icon is-small" v-if="lnk.icon">
            <font-awesome-icon :icon="lnk.icon" />
          </span>
          <span>{{ lnk.label }}</span>
        </router-link>
      </li>
    </ul>
  </nav>
</template>
<script>
export default {
  props: ["links"]
};
</script>
