<template>
  <nav class="breadcrumb" aria-label="breadcrumbs">
    <ul>
      <li>
        <router-link :to="{ name: 'home' }">
            <span class="icon is-small">
              <font-awesome-icon icon="home" />
            </span>
          <span>Home</span></router-link
        >
      </li>
      <li>
        <router-link
            :to="{ name: 'makePayment', params: { id: $route.params.id } }"
        >
            <span class="icon is-small">
              <font-awesome-icon icon="file-invoice-dollar" />
            </span>
          <span>Make Payment</span></router-link
        >
      </li>

      <li class="is-active">
        <router-link
            :to="{ name: 'paymentForm', params: { id: $route.params.id } }"
        >
            <span class="icon is-small">
              <font-awesome-icon icon="dollar-sign" />
            </span>
          <span>Payment Form</span>
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "CorePaymentBreadcrumb"
}
</script>
