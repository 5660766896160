<template>
  <div class="container mb-3">
    <validation-provider
      tag="div"
      :rules="rules[prompt.type]"
      class="field"
      v-for="prompt in prompts"
      :key="prompt.name"
      v-slot="{ errors }"
    >
      <label class="label">{{ prompt.displayLabel }}</label>
      <div class="control">
        <input
          v-if="prompt.type == 'DATE'"
          type="date"
          class="input"
          placeholder="MM/dd/yyyy"
          v-model="prompt.value"
          :name="prompt.displayLabel"
        />
        <input
          v-else
          type="text"
          class="input"
          v-model="prompt.value"
          :name="prompt.displayLabel"
        />
        <small
          class="has-text-danger"
          v-show="errors != null && errors.length > 0"
          >{{ errors[0] }}</small
        >
      </div>
    </validation-provider>
  </div>
</template>
<script>
import { ValidationProvider, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "{_field_} is required"
});

export default {
  props: ["prompts"],
  watch: {
    prompts: {
      deep: true,
      handler(val) {
        this.$emit("prompts-updated", val);
      }
    }
  },
  data() {
    return {
      rules: {
        ALPHANUM: {
          required: true,
          alpha_num: true
        },
        NUMBER: {
          required: true,
          numeric: true
        },
        DATE: {
          required: true
        }
      },
      prompt: {}
    };
  },
  components: { ValidationProvider }
};
</script>
