<template>
    <div class="block">
        <ul class="list">
            <li class="list-item" v-for="(pi,index) in planInfo" :key="index">
                <p class="has-text-weight-bold">{{ pi.label }}</p>
                <p class="mb-2">{{ pi.value }}</p>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    props: ["planInfo"]
};
</script>