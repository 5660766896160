import Vue from "vue";
import Router from "vue-router";
import MakePayment from "@/views/payment/MakePayment.vue";
import TransactionHistory from "@/views/TransactionHistory.vue";
import Details from "@/views/Details.vue";
import CorePayment from "@/views/CorePayment.vue";
import Confirm from "@/views/Confirm.vue";
import Completed from "@/views/Completed.vue";
import ChangePassword from "@/views/ChangePassword.vue";
import UserProfile from "@/views/UserProfile.vue";
import PaymentInfo from "@/views/PaymentInfo.vue";
import RegistrationForm from "@/views/registration/RegistrationForm.vue";
import RegistrationConfirm from "@/views/registration/Confirm.vue";
import RegistrationCompleted from "@/views/registration/Completed.vue";
import ContactUs from "@/views/policies/ContactUs.vue";
import PrivacyPolicy from "@/views/policies/PrivacyPolicy.vue";
import StateDeclarations from "@/views/policies/StateDeclarations.vue";
import TermsAndConditions from "@/views/policies/TermsAndConditions.vue";
import RegE from "@/views/RegE.vue";
import RegGeneralContactMessage from "@/views/registration/GeneralContactMessage.vue";
import Documents from "@/views/Documents.vue";
import GuestRoutes from "./guest";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    /**
     * PUBLIC
     */
    {
      path: "/",
      name: "generalContactMessage",
      component: () => import("@/views/GeneralContactMessage.vue"),
      meta: { requiresAuth: false }
    },
    {
      path: "/:instanceId",
      name: "dispatcher",
      component: () => import("@/views/Dispatcher.vue"),
      props: true,
      meta: { requiresAuth: false }
    },
    {
      path: "/:instanceId/login",
      name: "login",
      component: () => import("@/views/Login.vue"),
      meta: { requiresAuth: false }
    },
    {
      path: "/:instanceId/registration/registrationForm",
      name: "registrationForm",
      component: RegistrationForm,
      meta: { requiresAuth: false }
    },
    {
      path: "/:instanceId/registration/confirm",
      name: "registrationConfirm",
      component: RegistrationConfirm,
      meta: { requiresAuth: false }
    },
    {
      path: "/:instanceId/registration/completed",
      name: "registrationCompleted",
      component: RegistrationCompleted,
      meta: { requiresAuth: false }
    },
    {
      path: "/:instanceId/registration/contact",
      name: "contact",
      component: RegGeneralContactMessage,
      meta: { requiresAuth: false }
    },
    /**
     * REQUIRES LOGIN
     */
    {
      path: "/:instanceId/home",
      name: "home",
      component: () => import("@/views/Home.vue"),
      meta: { requiresAuth: true }
    },
    {
      path: "/:instanceId/accounts/:id",
      name: "account",
      component: () => import("@/views/Account.vue"),
      meta: { requiresAuth: true }
    },
    {
      path: "/:instanceId/accounts/:id/makePayment",
      name: "makePayment",
      component: MakePayment,
      meta: { requiresAuth: true }
    },
    {
      path: "/:instanceId/accounts/:id/overTime",
      name: "overTime",
      component: () => import("@/views/payment/overtime/OverTime.vue"),
      meta: { requiresAuth: true }
    },
    {
      path: "/:instanceId/accounts/:id/schedule",
      name: "amortizationSchedule",
      component: () =>
        import("@/views/payment/overtime/AmortizationSchedule.vue"),
      meta: { requiresAuth: true }
    },
    {
      path: "/:instanceId/accounts/:id/payment/overtime/payment",
      name: "overTime.payment",
      component: () => import("@/views/payment/overtime/Payment.vue"),
      meta: { requiresAuth: true }
    },
    {
      path: "/:instanceId/accounts/:id/payment/overtime/regE",
      name: "overTime.regE",
      component: () => import("@/views/payment/overtime/RegE.vue"),
      meta: { requiresAuth: true }
    },
    {
      path: "/:instanceId/accounts/:id/payment/overtime/review",
      name: "overTime.finalReview",
      component: () => import("@/views/payment/overtime/FinalReview.vue"),
      meta: { requiresAuth: true }
    },
    {
      path: "/:instanceId/accounts/:id/payment/overtime/thank",
      name: "overTime.thank",
      component: () => import("@/views/payment/overtime/ThankYou.vue"),
      meta: { requiresAuth: true }
    },
    {
      path: "/:instanceId/accounts/:id/paymentForm",
      name: "paymentForm",
      component: CorePayment,
      meta: { requiresAuth: true }
    },
    {
      path: "/:instanceId/accounts/:id/confirm",
      name: "confirm",
      component: Confirm,
      meta: { requiresAuth: true }
    },
    {
      path: "/:instanceId/accounts/:id/completed",
      name: "completed",
      component: Completed,
      meta: { requiresAuth: true }
    },
    {
      path: "/:instanceId/accounts/:id/transactionHistory",
      name: "transactionHistory",
      component: TransactionHistory,
      meta: { requiresAuth: true }
    },
    {
      path: "/:instanceId/accounts/:id/details",
      name: "details",
      component: Details,
      meta: { requiresAuth: true }
    },
    {
      path: "/:instanceId/accounts/:id/paymentInfo",
      name: "paymentInfo",
      component: PaymentInfo
    },
    {
      path: "/:instanceId/accounts/:id/rege",
      name: "rege",
      component: RegE,
      meta: { requiresAuth: true }
    },
    {
      path: "/:instanceId/accounts/:id/stateDeclarations",
      name: "stateDeclarations",
      component: StateDeclarations,
      meta: { requiresAuth: true }
    },
    {
      path: "/:instanceId/changePassword",
      name: "changePassword",
      component: ChangePassword
    },
    {
      path: "/:instanceId/userProfile",
      name: "userProfile",
      component: UserProfile
    },
    {
      path: "/:instanceId/privacyPolicy",
      name: "privacyPolicy",
      component: PrivacyPolicy,
      meta: { requiresAuth: true }
    },
    {
      path: "/:instanceId/termsAndConditions",
      name: "termsAndConditions",
      component: TermsAndConditions,
      meta: { requiresAuth: true }
    },
    {
      path: "/:instanceId/contactUs",
      name: "contactUs",
      component: ContactUs,
      meta: { requiresAuth: true }
    },
    {
      path: "/:instanceId/accounts/:id/schedule",
      name: "schedule",
      component: () => import("@/views/Schedule.vue"),
      meta: { requiresAuth: true }
    },
    {
      path: "/:instanceId/accounts/:id/negotiator",
      component: () => import("@/views/negotiator/Negotiator.vue"),
      children: [
        {
          path: "start",
          name: "negotiatorStart",
          component: () => import("@/views/negotiator/Start.vue"),
          meta: { requiresAuth: true }
        },
        {
          path: "payLess",
          name: "negotiatorPayLess",
          component: () => import("@/views/negotiator/PayLess.vue"),
          meta: { requiresAuth: true }
        },
        {
          path: "continue",
          name: "negotiatorPayLessSuccess",
          component: () => import("@/views/negotiator/PayLessSuccess.vue"),
          meta: { requiresAuth: true }
        },
        {
          path: "accept",
          name: "negotiatorPayLessFail",
          component: () => import("@/views/negotiator/PayLessFail.vue"),
          meta: { requiresAuth: true }
        },
        {
          path: "error",
          name: "negotiatorPayLessSystemError",
          component: () => import("@/views/negotiator/PayLessSystemError.vue"),
          meta: { requiresAuth: true }
        },
        {
          path: "payment",
          name: "negotiator.paymentForm",
          component: () => import("@/views/negotiator/PaymentForm.vue"),
          meta: { requiresAuth: true }
        }
      ]
    },
    {
      path: "/:instanceId/accounts/:id/documents",
      name: "documents",
      component: Documents,
      meta: { requiresAuth: true }
    },
    {
      path: "/:instanceId/otp",
      component: () => import("@/views/otp/OTPWrapper.vue"),
      children: [
        {
          path: "enroll",
          name: "otpEnrollment",
          component: () => import("@/views/otp/OTPEnrollment.vue"),
          meta: {requiresAuth: false}
        },
        {
          path: "request",
          name: "otpRequest",
          component: () => import("@/views/otp/OTPRequest.vue"),
          meta: {requiresAuth: false}
        },
        {
          path: "verify",
          name: "otpVerification",
          component: () => import("@/views/otp/OTPVerification.vue"),
          meta: {requiresAuth: false}
        },
      ]
    },
    ...GuestRoutes.routes
  ]
});
