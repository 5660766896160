<template>
  <base-layout :loadingFlag="$store.state.loading || pageLoading">
    <nav class="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li>
          <router-link :to="{ name: 'home' }">
            <span class="icon is-small">
              <font-awesome-icon icon="home" />
            </span>
            <span>Home</span>
          </router-link>
        </li>
        <li class="is-active">
          <router-link
            :to="{ name: 'documents', params: { id: $route.params.id } }"
          >
            <span>Documents</span>
          </router-link>
        </li>
      </ul>
    </nav>
    <div class="content">
      <user-info />
    </div>
    <account-header />
    <div class="columns">
      <div class="column is-one-quarter is-hidden-mobile">
        <sidebar />
      </div>
      <div class="column">
        <div class="field">
          <button class="button" @click="refresh">
            Refresh
          </button>
        </div>
        <div class="field">
          <table class="table is-fullwidth is-striped is-bordered ">
            <thead>
              <tr>
                <th>File</th>
                <th>ID</th>
                <th>Date Updated</th>
              </tr>
            </thead>
            <tbody class="tbody">
              <tr
                v-for="doc in docsList"
                :key="doc.id"
                @click="downLoadFile(doc.filename)"
              >
                <td>{{ doc.filename }}</td>
                <td>{{ doc.seq }}</td>
                <td>{{ doc.date }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <file-upload inputName="file" inputRef="file" />
      </div>
    </div>
  </base-layout>
</template>

<script>
import { mapActions, mapState } from "vuex";
import BaseLayout from "@/components/BaseLayout.vue";
import AccountHeader from "@/components/AccountHeader.vue";
import Sidebar from "@/components/Sidebar.vue";
import FileUpload from "@/components/FileUpload.vue";
import fetchMakePaymentPage from "@/activities/FetchMakePaymentPage";

export default {
  components: {
    BaseLayout,
    UserInfo: () => import("@/components/UserInfo.vue"),
    AccountHeader,
    Sidebar,
    FileUpload
  },
  data() {
    return {
      pageLoading: true
    };
  },
  computed: {
    ...mapState(["docsList"])
  },
  methods: {
    ...mapActions(["fetchUserInfo", "getDocList"]),
    async refresh() {
      await this.$store.dispatch("getDocList", {
        instanceId: this.$route.params.instanceId,
        accountNo: this.$route.params.id
      });
      await this.loadDocs();
    },
    downLoadFile(name) {
      this.$store.dispatch("getDoc", name);
    }
  },
  beforeRouteEnter(to, from, next) {
    next(async vm => {
      try {
        vm.$store.commit("SET_LOADING", true);
        vm.pageLoading = true;
        await fetchMakePaymentPage({
          instanceId: vm.$route.params.instanceId,
          accountNo: vm.$route.params.id
        });
        vm.$store.dispatch("fetchUserInfo");
        await vm.getDocList({
          instanceId: vm.$route.params.instanceId,
          accountNo: vm.$route.params.id
        });
      } catch (e) {
        vm.$store.commit("SET_GLOBAL_NOTIFICATION", {
          showNotification: true,
          notificationMessage: `Error fetching  page for ${
            vm.$route.params.id
          }; ${e}`,
          notificationType: "error"
        });
      } finally {
        vm.$store.commit("SET_LOADING");
        vm.pageLoading = false;
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    //added not to show previously loaded documents
    let emptyDocsList = [];
    this.$store.commit("SET_DOCS_LIST", emptyDocsList);
    next();
  }
};
</script>

<style scoped>
input[type="file"] {
  display: none;
}
</style>
