<template>
    <validation-observer tag="div" class="block" v-slot="{invalid, errors}">
        <div class="message">
            <div class="message-header has-background-info">
                <p>Payment Plan ID: {{ plan.id }}</p>
                <span class="icon is-small" v-if="showDetails" @click="toggleShowDetails">
                    <font-awesome-icon icon="minus-circle" />
                </span>
                <span class="icon is-small" v-else @click="toggleShowDetails">
                    <font-awesome-icon icon="plus-circle" />
                </span>
            </div>
            <div class="message-body">
                <div class="columns" v-if="showDetails">
                    <div class="column is-one-quarter">
                        <payment-plan-sidebar :planInfo="plan.info" />
                    </div>
                    <div class="column">
                        <payment-plan-header :plan="plan" :user="user" />
                        <payment-plan-table :lineItems="plan.lineItems" />
                    </div>
                </div>
                <div class="level" v-if="allowPayments(plan)">
                  <div class="level-left">
                      <p class="level-item has-text-weight-bold">Minimum Payment Due: {{ plan.minPaymentDue | formatCurrency }}</p>
                      <p class="level-item has-text-weight-bold">Payment Due Date: {{ plan.paymentDueDate | formatDate }}</p>
                  </div>
                  <div class="level-right">
                      <div class="level-item"><label class="label">Pay</label></div>
                      <div class="level-item">
                          <div class="field">
                              <div class="select">
                                  <select v-model="selectedAmountValue">
                                      <option v-for="at in amountTypes" :key="at.value" :value="at.value">{{ at.label }}</option>
                                  </select>
                              </div>
                          </div>
                      </div>
                      <div class="level-item" v-if="showExtended">
                          <validation-provider tag="div" class="field" rules="atMostNumDigits:2" ref="tf">
                              <div class="control">
                                  <input name="Other Amount" type="text" class="input" v-model="extendedValue" ref="thetextfield" />
                              </div>
                          </validation-provider>
                      </div>
                  </div>
              </div>
              <div class="level" v-if="invalid">
                <div class="level-left"></div>
                <div class="level-right">
                  <article class="message is-danger">
                    <div class="message-body">
                      {{ Object.values(errors)[0][0] }}
                    </div>
                  </article>
                </div>
              </div>
              <other-amount-message
                  :show="belowMinPayment && otherAmountMessage"
                  :message="otherAmountMessage"
                  clazz="is-warning" />
              <other-amount-message
                  :show="aboveMaxPayment && otherAmountMessage"
                  message="Entered amount cannot exceed Total Due."
                  clazz="is-danger" />
            </div>
        </div>
    </validation-observer>
</template>
<script>
import PaymentPlanTable from "./PaymentPlanTable.vue";
import PaymentPlanSidebar from "./PaymentPlanSidebar.vue";
import PaymentPlanHeader from "./PaymentPlanHeader.vue";
import OtherAmountMessage from "./OtherAmountMessage.vue";
import Filters from "@/utils/filters";

export default {
    components: { 
        PaymentPlanSidebar, 
        PaymentPlanTable,
        PaymentPlanHeader,
        OtherAmountMessage
        },
    props: ["plan","user","otherAmountMessage"],
    data() {
        return {
            showDetails: true,
            selectedAmountValue: null,
            extendedValue: null
        };
    },
    computed: {
        amountTypes() {
            if( !this.plan ) {
                return [];
            } else {
              const at = [
                { extended: false, value: null, label: "Select Amount" },
                { extended: false, value: "TOTAL", label: "Total amount - " +  Filters.formatCurrency(this.totalDue) },
                { extended: true, value: "OTHER", label: "Other amount" }
              ];

              // #1928 won't display this if it's more than the total amount
              const min =
                  { extended: false, value: "MIN", label: "Minimum amount - " + Filters.formatCurrency(this.plan.minPaymentDue) };

              if( Number(this.plan.minPaymentDue) < this.totalDue ) {
                at.splice(1, 0, min);
              }
              return at;
            }
        },
        showExtended() { 
            if( this.amountTypes.length > 0 ) {
                const at = this.amountTypes.find( at => at.value == this.selectedAmountValue );
                if( at ) {
                    return at.extended;
                }
            }
            return false;
        },
        totalDue() {
            if( this.plan ) {
                const total = this.plan.lineItems.find( li => li.type == "TOTAL" );
                if( total ) {
                    return Number(total.due);
                }
            }
            return 0;
        },
        textFieldRef() {
          if( this.plan ) {
            return "thetextfield_" + this.plan.id;
          } else {
            return null;
          }
        },
        belowMinPayment() {
          if( this.extendedValue != null ) {
            return Number(this.extendedValue) < Number(this.plan.minPaymentDue);
          }
          return false;
        },
        aboveMaxPayment() {
          if( this.extendedValue != null ) {
            return Number(this.extendedValue) > Number(this.totalDue);
          }
          return false;
        }
    },
    methods: {
      toggleShowDetails() {
          this.showDetails = !this.showDetails;
      },
      isExtended(val) {
         return this.amountTypes.find(at => at.value == val && at.extended)
      },
      allowPayments(plan) {
        if( plan && plan.info ) {
          const found = plan.info.find( p => p.label == 'ALLOW_PAYMENTS' )

          if( !found ) {
            return true; // legacy; if no ALLOW_PAYMENTS in view then show
          }

          return found.value == 'Y' // 'N' will hide
        }
        return false;  // something is wrong
      }
    },
    watch: {
        selectedAmountValue(val) {
            this.extendedValue = null;
            if( this.plan ) {
                switch(val) {
                    case "MIN":
                        this.$emit("amount-updated", { id: this.plan.id, value: this.plan.minPaymentDue });
                        break;
                    case "TOTAL":
                        this.$emit("amount-updated", { id: this.plan.id, value: this.totalDue });
                        break;
                    case "OTHER":
                        this.$emit("amount-updated", { id: this.plan.id, value: this.extendedValue });
                        this.$nextTick(() => this.$refs["thetextfield"].focus());
                        break;
                    default: {
                        this.$emit("amount-updated", { id: this.plan.id, value: null });
                    }
                }
            }
        },
        extendedValue(val) {
            if( this.plan ) {
                if( this.selectedAmountValue && this.isExtended(this.selectedAmountValue) ) {
                    if( !isNaN(val) ) {
                      this.$emit("amount-updated", {id: this.plan.id, value: val});
                    } else {
                      this.$emit("amount-updated", {id: this.plan.id, value: 0});
                    }
                }
            }
        }
    },
    created() {
        this.$root.$on("reset-form-event", () => {
            this.selectedAmountValue = null;
            this.extendedValue = 0;
        });
    }
};
</script>
