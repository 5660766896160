import axios from "axios";
import moment from "moment";
const actions = {
  async fetchPaymentPortalGlobalConfig({ commit }) {
    commit("SET_LOADING", true);
    commit("SET_GLOBAL_NOTIFICATION", true);
    try {
      let { data } = await axios.get(
        process.env.VUE_APP_API_URL + "public/configParameters/paymentPortal"
      );
      commit("SET_GLOBAL_CONFIG", data);
    } catch (e) {
      commit("SET_GLOBAL_CONFIG", {
        header: "DEFAULT HEADER CONTENT",
        helpMessage: "DEFAULT HELP MESSAGE CONTENT",
        logoURL: "DEFAULT LOGO CONTENT"
      });
      commit("SET_GLOBAL_NOTIFICATION", {
        showNotification: true,
        notificationMessage: `Error fetching global config ${e}`,
        notificationType: "error"
      });
    } finally {
      commit("SET_LOADING", false);
    }
  },
  async fetchPaymentPortalGlobalConfig2({ commit }) {
    const { data } = await axios.get(
        process.env.VUE_APP_API_URL + "public/configParameters/paymentPortal"
    );
    commit("SET_GLOBAL_CONFIG", data);
  },
  async fetchPaymentPortalInstanceConfig({ commit }, instanceId) {
    try {
      let { data } = await axios.get(
        process.env.VUE_APP_API_URL +
          "public/configParameters/paymentPortal/" +
          instanceId
      );
      commit("SET_INSTANCE_CONFIG", data);
    } catch (e) {
      commit("SET_GLOBAL_CONFIG", {
        header: "DEFAULT HEADER CONTENT",
        helpMessage: "DEFAULT HELP MESSAGE CONTENT",
        logoURL: "DEFAULT LOGO CONTENT"
      });
      commit("SET_GLOBAL_NOTIFICATION", {
        showNotification: true,
        notificationMessage: `Error fetching instance ${instanceId} config ${e}`,
        notificationType: "error"
      });
    }
  },
  async finalizePayment({ commit, state }) {
    commit("SET_LOADING", true);
    commit("SET_AUTH_CODE", null);
    commit("SET_PAYMENT_RESULT", null);
    try {
      if (
        state.paymentInProgress.needsSD &&
        state.paymentInProgress.sdAccepted
      ) {
        await axios.post(
          process.env.VUE_APP_API_URL +
            "api/demographics/acknowledge;user=" +
            state.username,
          {
            demPortalUsername: state.username,
            demIdtyNo: state.paymentInProgress.demIdtyNo,
            disclosureLabel: state.sdDisclosureLabel,
            disclosureText: state.sdDisclosureText
          },
          {
            headers: {
              Authorization: "Bearer " + state.jwt
            }
          }
        );
      }

      if (state.paymentInProgress.acceptRegE) {
        await axios.post(
          process.env.VUE_APP_API_URL +
            "api/demographics/acknowledge;user=" +
            state.username,
          {
            demPortalUsername: state.username,
            demIdtyNo: state.paymentInProgress.demIdtyNo,
            disclosureLabel: state.regeDisclosureLabel,
            disclosureText: state.regeDisclosureText,
            dbtNo: state.paymentInProgress.refNum
          },
          {
            headers: {
              Authorization: "Bearer " + state.jwt
            }
          }
        );
      }

      let url =
        process.env.VUE_APP_API_URL + "api/payments;user=" + state.username;
      if (state.paymentInProgress.action == "next") {
        url =
          process.env.VUE_APP_API_URL +
          "api/payments/info/" +
          state.paymentInProgress.instanceId +
          "/" +
          state.paymentInProgress.refNum +
          ";user=" +
          state.username;
      }

      let { data } = await axios.post(url, state.paymentInProgress, {
        headers: {
          Authorization: "Bearer " + state.jwt
        }
      });

      if (data.success) {
        let ppccResponse = await axios.get(
          process.env.VUE_APP_API_URL +
            "api/udw/paymentPortal;user=" +
            state.username,
          {
            headers: {
              Authorization: "Bearer " + state.jwt
            }
          }
        );

        if (ppccResponse.data.successSODCode) {
          let messagesResponse = await axios.get(
            process.env.VUE_APP_API_URL +
              "api/messages/" +
              ppccResponse.data.successSODCode +
              "/" +
              state.paymentInProgress.refNum +
              ";user=" +
              state.username,
            {
              headers: {
                Authorization: "Bearer " + state.jwt
              }
            }
          );
          if (messagesResponse.data) {
            commit(
              "SET_ADDITIONAL_SUCCESS_MESSAGES",
              messagesResponse.data.join()
            );
          }
        }

        commit("SET_AUTH_CODE", data.authCode);
        commit("SET_CONF_NUMBER", data.confNumber);
        commit("SET_PAYMENT_RESULT", {
          success: true,
          authCode: data.authCode,
          confNumber: data.confNumber,
          message: data.message
        });
        commit("SET_GLOBAL_NOTIFICATION", {
          showNotification: true,
          notificationMessage: "Transaction Successful",
          notificationType: "success"
        });
      } else { // data.success == false
        commit("SET_PAYMENT_RESULT", {
          success: false,
          authCode: null,
          confNumber: null,
          message: data.errorMessage
        });
        commit("SET_GLOBAL_NOTIFICATION", {
          showNotification: true,
          notificationMessage: data.errorMessage,
          notificationType: "error"
        });
      }
    } catch (e) {
      if (e.response.data.parameterViolations) {
        commit("SET_PAYMENT_RESULT", {
          success: false,
          authCode: null,
          confNumber: null,
          message: `${e.response.data.parameterViolations[0].path} / 
          ${e.response.data.parameterViolations[0].message}`
        });
      } else {
        commit("SET_PAYMENT_RESULT", {
          success: false,
          authCode: null,
          confNumber: null,
          message: "${e}"
        });
      }
      let nmsg = "Transaction NOT Successful"
      if( e ) {
        nmsg = e.message
      }
      commit("SET_GLOBAL_NOTIFICATION", {
        showNotification: true,
        notificationMessage: nmsg,
        notificationType: "error"
      });
    } finally {
      commit("SET_LOADING", false);
      commit("SET_PAYMENT_IN_PROGRESS", null);
    }
  },
  async fetchUserInfo({ commit, state }) {
    let { data } = await axios.get(
      process.env.VUE_APP_API_URL + "api/demographics/m;user=" + state.username,
      {
        headers: {
          Authorization: "Bearer " + state.jwt
        }
      }
    );
    commit("SET_USER_INFO", data);
  },
  async logout({ commit }) {
    /**
     * Errors handled by caller
     */
    await axios.post(
      process.env.VUE_APP_API_URL +
        "api/demographics/logout;user=" +
        this.state.username,
      {},
      {
        headers: {
          Authorization: "Bearer " + this.state.jwt
        }
      }
    );

    localStorage.removeItem("jwt");
    commit("SET_JWT", null);
    localStorage.removeItem("username");
    commit("SET_USERNAME", null);
  },
  handleGlobalError({ commit }, errorMessage) {
    commit("SET_GLOBAL_NOTIFICATION", {
      showNotification: true,
      notificationMessage: errorMessage,
      notificationType: "error"
    });
    commit("SET_LOADING", false);
  },
  async fetchAccount({ commit, state }, id) {
    try {
      commit("SET_LOADING", true);
      let { data } = await axios.get(
        process.env.VUE_APP_API_URL + "api/demographics;user=" + state.username,
        {
          params: {
            accountNo: id
          },
          headers: {
            Authorization: "Bearer " + state.jwt
          }
        }
      );
      return data;
    } catch (e) {
      commit("SET_GLOBAL_NOTIFICATION", {
        showNotification: true,
        notificationMessage: `Error looking up account; ${e}`,
        notificationType: "error"
      });
    } finally {
      commit("SET_LOADING", false);
    }
  },
  async changePassword({ state }, pwChange) {
    await axios.post(
      process.env.VUE_APP_API_URL +
        "api/demographics/password;user=" +
        state.username,
      pwChange,
      {
        headers: {
          Authorization: "Bearer " + state.jwt
        }
      }
    );
  },
  async saveUserProfile({ state }, userProfile) {
    await axios.post(
        process.env.VUE_APP_API_URL +
        "api/demographics/userProfile;user=" +
        state.username,
        userProfile,
        {
          headers: {
            Authorization: "Bearer " + state.jwt
          }
        }
    );
  },
  async enrollOTP({ state }, otpMedia) {

    await axios.post(
        process.env.VUE_APP_API_URL + "otp/enroll",
        otpMedia
    );
  },
  async requestOTP({ state }, otpMedia) {

    await axios.post(
        process.env.VUE_APP_API_URL + "otp/send",
        otpMedia
    );
  },
  async submitOTP({ state }, otpSubmission) {

    return await axios.post(
        process.env.VUE_APP_API_URL + "otp/apply",
        otpSubmission
    );
  },
  async fetchUserProfile({commit, state}) {
    commit("SET_LOADING", true);
    try {

      let {data} = await axios.get(
          process.env.VUE_APP_API_URL +
          "api/demographics/userProfile;user=" +
          state.username,
          {
            headers: {
              Authorization: "Bearer " + state.jwt
            }
          }
      );
      commit("SET_USER_PROFILE", data);
    } catch (e) {
      commit("SET_GLOBAL_NOTIFICATION", {
        showNotification: true,
        notificationMessage: `Error fetching users; ${e}`,
        notificationType: "error"
      });
    } finally {
      commit("SET_LOADING", false);
    }
  },
  async fetchDemographics({ commit, state }, searchFor) {
    commit("SET_LOADING", true);
    try {
      let { data } = await axios.get(
        process.env.VUE_APP_API_URL + "api/demographics/admin",
        {
          params: {
            searchFor: searchFor
          },
          headers: {
            Authorization: "Bearer " + state.jwt
          }
        }
      );
      commit("SET_DEMOGRAPHICS", data);
    } catch (e) {
      commit("SET_GLOBAL_NOTIFICATION", {
        showNotification: true,
        notificationMessage: `Error fetching users; ${e}`,
        notificationType: "error"
      });
    }
    commit("SET_LOADING", false);
  },
  fetchMessages: async ({ commit, state }, payload) => {
    commit("SET_LOADING", true);
    commit("SET_GLOBAL_NOTIFICATION");
    try {
      let { data } = await axios.get(
        process.env.VUE_APP_API_URL +
          "api/messages/" +
          payload.sodCode +
          "/" +
          payload.relatedAccount +
          ";user=" +
          state.username,
        {
          headers: {
            Authorization: "Bearer " + state.jwt
          }
        }
      );
      return data;
    } catch (e) {
      commit("SET_GLOBAL_NOTIFICATION", {
        showNotification: true,
        notificationMessage: `Error fetching SOD messages; ${e}`,
        notificationType: "error"
      });
    } finally {
      commit("SET_LOADING", false);
    }
  },
  fetchRegEMessages: async ({ commit, state }, params) => {
    commit("SET_LOADING", true);
    commit("SET_GLOBAL_NOTIFICATION");
    try {
      let { data } = await axios.get(
        process.env.VUE_APP_API_URL + "api/regeMessages;user=" + state.username,
        {
          params: {
            option: params.option,
            action: params.action,
            relatedAccount: params.relatedAccount
          },
          headers: {
            Authorization: "Bearer " + state.jwt
          }
        }
      );
      return data;
    } catch (e) {
      commit("SET_GLOBAL_NOTIFICATION", {
        showNotification: true,
        notificationMessage: `Error fetching RegE messages; ${e}`,
        notificationType: "error"
      });
    } finally {
      commit("SET_LOADING", false);
    }
  },
  fetchPaymentArrangements: async ({ state, commit }, id) => {
    commit("SET_LOADING", true);
    commit("SET_GLOBAL_NOTIFICATION");
    try {
      let { data } = await axios.get(
        process.env.VUE_APP_API_URL +
          "api/payments/arrangements/" +
          id +
          ";user=" +
          state.username,
        {
          headers: {
            Authorization: "Bearer " + state.jwt
          }
        }
      );
      return data;
    } catch (e) {
      commit("SET_GLOBAL_NOTIFICATION", {
        showNotification: true,
        notificationMessage: `Error Fetching Schedule; ${e}`,
        notificationType: "error"
      });
    } finally {
      commit("SET_LOADING", false);
    }
  },
  async fetchRegE({ dispatch }, field) {
    let data = await dispatch("fetchPaymentPortalCompanyConfig", "Reg E");
    return data[field];
  },
  async authOk(context, credentials) {
    try {
      let { data } = await axios.post(
          process.env.VUE_APP_API_URL + "token/verify",
          { jwt: credentials.jwt }
      );
      return data.valid;
    } catch (e) {
      return false;
    }
  },
  async fetchNegotiatorSOD2FormFields({ state, commit }, sodCode) {
    let { data } = await axios.get(
      process.env.VUE_APP_API_URL + "api/strategies/" + sodCode + "/formFields",
      {
        headers: {
          Authorization: "Bearer " + state.jwt
        }
      }
    );
    commit("SET_NEGOTIATOR_SOD2_FORM_FIELDS", data); // retvals too
  },
  async callNegotiatorSOD2(
    { state, commit, getters },
    { sodCode, relatedAccount, offerDate, offerAmount }
  ) {
    let s = `${getters.getFormParam(
      "PORTAL_PAY_LESS_AMOUNT"
    )}=${offerAmount}&${getters.getFormParam(
      "PORTAL_PAY_LESS_DATE"
    )}=${offerDate}&relatedAccount=${relatedAccount}`;

    let { data } = await axios.post(
      process.env.VUE_APP_API_URL +
        `api/strategies/${sodCode};user=${state.username}`,
      s,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + state.jwt
        }
      }
    );
    commit("SET_NEGOTIATOR_SOD2_RESULTS", data);
  },
  async getDocList({ commit, state }, { instanceId, accountNo }) {
    try {
      let { data } = await axios.get(
        process.env.VUE_APP_API_URL +
          "api/docs;user=" +
          state.username +
          "?instanceId=" +
          instanceId +
          "&dbtNo=" +
          accountNo,
        {
          headers: {
            Authorization: "Bearer " + state.jwt
          }
        }
      );
      commit("SET_DOCS_LIST", data);
    } catch (e) {
      commit("SET_GLOBAL_NOTIFICATION", {
        showNotification: true,
        notificationMessage: `Error fetching docs for ${accountNo}; ${e}`,
        notificationType: "error"
      });
    }
  },
  async getWebLinks({ commit, state }) {
    try {
      let { data } = await axios.get(
        process.env.VUE_APP_API_URL + "api/webLinks;user=" + state.username,
        {
          headers: {
            Authorization: "Bearer " + state.jwt
          }
        }
      );
      commit("SET_WEB_LINKS", data);
    } catch (e) {
      commit("SET_GLOBAL_NOTIFICATION", {
        showNotification: true,
        notificationMessage: `Error fetching web links for ${
          state.username
        }; ${e}`,
        notificationType: "error"
      });
    }
  },
  addDoc: async ({ commit, state }, { dbtNo, desc, type, uploadedFile }) => {
    let formData = new FormData();
    formData.append("dbtNo", dbtNo);
    formData.append("desc", desc);
    formData.append("type", type);
    formData.append("uploadedFile", uploadedFile);
    await axios
      .post(process.env.VUE_APP_API_URL + "api/docs", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + state.jwt
        }
      })
      .then(async response => {
        commit("SET_GLOBAL_NOTIFICATION", {
          showNotification: true,
          notificationMessage: `${response.data.message};`,
          notificationType: "success"
        });
        let currentDate = new Date();
        let dateString = moment(currentDate).format("YYYY-MM-DD");
        let object = {
          filename: `${response.data.filename}`,
          seq: "",
          date: dateString
        };
        commit("ADD_RECORD_TO_DOCSLIST", { object });
      });
  },
  async getDoc({ commit, state }, fileName) {
    await axios({
      url: process.env.VUE_APP_API_URL + "servlet/doc",
      method: "GET",
      params: { filename: fileName },
      headers: { Authorization: "Bearer " + state.jwt },
      responseType: "blob"
    })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      })
      .catch(e => {
        commit("SET_GLOBAL_NOTIFICATION", {
          showNotification: true,
          notificationMessage: `Error loading file ${fileName}; ${e}`,
          notificationType: "error"
        });
      });
  },
  async deleteArrangement({ state }, id) {
    let { data } = await axios.get(
      process.env.VUE_APP_API_URL +
        "api/paymentArrangements;user=" +
        state.username +
        "/" +
        id +
        "/pendingCheck",
      {
        headers: {
          Authorization: "Bearer " + state.jwt,
          "Content-Type": "application/x-www-form-urlencoded"
        }
      }
    );
    if (data.hasPending) {
      await axios.delete(
        process.env.VUE_APP_API_URL +
          "api/paymentArrangements;user=" +
          state.username +
          "/" +
          id,
        {
          headers: {
            Authorization: "Bearer " + state.jwt,
            "Content-Type": "application/x-www-form-urlencoded"
          }
        }
      );
    }
  }
};

export default actions;
