<template>
  <div class="box noprint">
    <ul class="list">
      <li
        class="list-item"
        v-for="{ label, type, value } in displayableValues"
        :key="label"
      >
        <p>
          <strong>{{ label }}</strong>
        </p>
        <p v-if="type == 'CURRENCY'">
          {{ value | formatCurrency }}
        </p>
        <p v-else-if="type == 'DATE'">
          {{ value | formatDate }}
        </p>
        <p v-else>{{ value }}</p>
      </li>
    </ul>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("accounts", ["account"]),
    displayableValues() {
      return Object.values(this.account).filter(rec => rec.display == "Y");
    },
    id() {
      return this.$route.params.id;
    }
  },
  mounted() {
    this.$store.dispatch("accounts/fetchAccount2", this.id);
  },
  watch: {
    id(nv) {
      this.$store.dispatch("accounts/fetchAccount2", nv);
    }
  }
};
</script>
<style lang="scss">
@media print {
  .noprint {
    display: none;
  }
}
</style>
