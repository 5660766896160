<template>
  <div>
    <div class="columns">
      <div class="column">
        <validation-provider
          rules="required|max:25"
          tag="div"
          class="field"
          v-slot="{ errors, failed }"
        >
          <div class="control">
            <input
              name="First Name"
              class="input"
              type="text"
              id="firstName"
              v-model="info.firstName"
              placeholder="First Name"
              :class="{ 'is-danger': failed }"
            />
          </div>
          <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
        </validation-provider>
      </div>
      <div class="column">
        <validation-provider
          rules="required|max:25"
          tag="div"
          class="field"
          v-slot="{ errors, failed }"
        >
          <div class="control">
            <input
              name="Last Name"
              class="input"
              type="text"
              id="lastName"
              v-model="info.lastName"
              placeholder="Last Name"
              :class="{ 'is-danger': failed }"
            />
          </div>
          <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
        </validation-provider>
      </div>
    </div>
    <validation-provider
      rules="required|max:30"
      tag="div"
      class="field"
      v-slot="{ errors, failed }"
    >
      <div class="control">
        <input
          name="Billing Address"
          class="input"
          type="text"
          id="billingAddress"
          v-model="info.billingAddress"
          placeholder="Billing Address"
          :class="{ 'is-danger': failed }"
        />
      </div>
      <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
    </validation-provider>
    <div class="columns">
      <div class="column">
        <validation-provider
          rules="required"
          tag="div"
          class="field"
          v-slot="{ errors, failed }"
        >
          <div class="control">
            <input
              name="City"
              class="input"
              type="text"
              id="city"
              v-model="info.city"
              maxlength="28"
              placeholder="City"
              :class="{ 'is-danger': failed }"
            />
          </div>
          <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
        </validation-provider>
      </div>

      <div class="is-flex is-align-items-center" v-if="instanceConfig.allowCanadian">
        
        <div class="control">
          <label class="radio">
            <input name="country" type="radio" v-model="info.country" value="US" />
            US
          </label>
        
          <label class="radio">
            <input name="country" type="radio" v-model="info.country" value="CA" />
            Canada
          </label>
        </div>
          
      </div>

      <div class="column">
        <validation-provider
            rules="required"
            tag="div"
            class="field"
            v-slot="{ errors, failed }"
        >
          <div class="control">
            <div class="select is-fullwidth">
              <select name="Province"
                  v-model="info.state" v-if="info.country == 'CA'">
                <option :value="null">Select a Province</option>
                <option v-for="ar in provinces" :key="ar[0]" :value="ar[0]">{{ar[1]}}</option>
                
              </select>
              <select
                  v-if="info.country != 'CA'"
                  name="State"
                  v-model="info.state"
              >
                <option :value="null">Please select a state</option>
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="DC">District Of Columbia</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="PR">Puerto Rico</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
              </select>
            </div>
          </div>
          <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
        </validation-provider>
      </div>
      <div class="column">
        <validation-provider
          :rules="info.country == 'CA' ? 'required|max:6' : 'required|max:10|numeric'"
          tag="div"
          class="field"
          v-slot="{ errors, failed }"
        >
          <div class="control">
            <input
              class="input"
              type="text"
              id="zipCode"
              v-model="info.zipCode"
              placeholder="Zip"
              name="Zip Code"
              maxlength="10"
              :class="{ 'is-danger': failed }"
            />
          </div>
          <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
        </validation-provider>
      </div>
    </div>
    <validation-provider rules="required" tag="div" class="field">
      <div class="control">
        <div class="select is-fullWidth">
          <select v-model="info.paymentOption">
            <option :value="null">Please select a payment option</option>
            <option
              v-for="paySelect in sortedPaySelects"
              :key="paySelect.key"
              :value="paySelect.key"
            >
              {{ paySelect.value | formatMethod }}
            </option>
          </select>
        </div>
      </div>
    </validation-provider>

    <validation-provider
      v-if="info.paymentOption == '0' || info.paymentOption == '2'"
      vid="ccNumber"
      tag="div"
      class="field"
      v-slot="{ errors, failed }"
      :rules="{
        required: info.paymentOption == '0' || info.paymentOption == '2',
        cardLength: info.paymentOption == '0' || info.paymentOption == '2',
        numeric: !info.prefilled, // masking allowed for
        removeWhitespace: true
      }"
    >
      <div class="control">
        <input
          class="input"
          type="text"
          id="ccNumber"
          v-model="info.ccNumber"
          name="Card Number"
          placeholder="Card Number"
        />
      </div>
      <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
    </validation-provider>
    <div
      class="field"
      v-if="info.paymentOption == '0' || info.paymentOption == '2'"
    >
      <div class="columns">
        <div class="column">
          <div class="select is-fullwidth">
            <select v-model="info.ccExpDateMonth">
              <option :value="null">Expiration Month</option>
              <option value="01">January</option>
              <option value="02">February</option>
              <option value="03">March</option>
              <option value="04">April</option>
              <option value="05">May</option>
              <option value="06">June</option>
              <option value="07">July</option>
              <option value="08">August</option>
              <option value="09">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option>
            </select>
          </div>
        </div>
        <div class="column">
          <div class="select is-fullwidth">
            <select v-model="info.ccExpDateYear">
              <option :value="null">Year</option>
              <option v-for="y in next10" :key="y" :value="y">
                {{ y }}
              </option>
            </select>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <validation-provider
              :rules="{
                required: true,
                numeric: !info.prefilled, // masking allowed for
                removeWhitespace: true,
                max: 10
              }"
              tag="div"
              class="field"
              v-slot="{ errors, failed }"
            >
              <div class="control">
                <input
                  name="CVV"
                  class="input"
                  type="text"
                  id="cvv"
                  v-model="info.cvv"
                  placeholder="CVV"
                  maxlength="10"
                />
              </div>
              <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
            </validation-provider>
          </div>
        </div>
      </div>
    </div>

    <validation-provider
      v-if="info.paymentOption == '1' || info.paymentOption == '3'"
      tag="div"
      class="field"
      vid="routingNumber"
      v-slot="{ failed, errors }"
      :rules="{
        required: (info.paymentOption == '1' || info.paymentOption == '3')
      }"
    >
      <div class="control">
        <input
          class="input"
          type="text"
          v-model="info.routingNumber"
          maxlength="10"
          placeholder="Routing number"
          name="Routing number"
        />
      </div>
      <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
    </validation-provider>

    <validation-provider
      v-if="info.paymentOption == '1' || info.paymentOption == '3'"
      tag="div"
      class="field"
      v-slot="{ failed, errors }"
      :rules="{ required: (info.paymentOption == '1' || info.paymentOption == '3'), numeric: true }"
    >
      <div class="control">
        <input
          name="Bank Account Number"
          class="input"
          type="text"
          v-model="info.bankAccountNumber"
          maxlength="25"
          placeholder="Bank account number"
        />
      </div>
      <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
    </validation-provider>

    <validation-provider
        v-if="info.paymentOption == '3'"
        tag="div"
        class="field"
        vid="checkNo"
        v-slot="{ failed, errors }"
        :rules="{
        required: info.paymentOption == '3'
      }"
    >
      <div class="control">
        <input
            class="input"
            type="text"
            v-model="info.checkNo"
            maxlength="10"
            placeholder="Check Number"
            name="Check Number"
        />
      </div>
      <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
    </validation-provider>

    <validation-provider
        v-if="(info.paymentOption == '1' || info.paymentOption == '3') && fullAccount(this.$route.params.id).ach_requires_phone.value"
        tag="div"
        class="field"
        v-slot="{ failed, errors }"
        :rules="{
          required: (info.paymentOption == '1'|| info.paymentOption == '3') && fullAccount(this.$route.params.id).ach_requires_phone.value,
          digits: 10 }"
    >
      <div class="control">
        <input
            name="Phone Number"
            class="input"
            type="text"
            v-model="info.phone"
            maxlength="10"
            placeholder="Phone Number"
        />
      </div>
      <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
    </validation-provider>

    <p v-if="info.paymentOption == null" class="content">
      No Payment Method Selected
    </p>

    <div v-if="showAmount && lockAmount" class="field">
      <p class="control has-icons-left">
        <input
          name="Amount"
          class="input"
          type="text"
          id="amount1"
          :value="info.amount"
          disabled
        />
        <span class="icon is-small is-left">
          <font-awesome-icon icon="dollar-sign" size="sm" />
        </span>
      </p>
    </div>
    <div class="field" v-if="showOnFile && payInfo">
      <div class="control">
        <label class="label">
          <input type="checkbox" v-model="info.prefilled" />
          Use payment method on file
        </label>
      </div>
    </div>

    <validation-provider
      v-if="showAmount && !lockAmount"
      rules="required|noCurrencySymbol|atMostNumDigits:2"
      tag="div"
      class="field"
      v-slot="{ failed, errors }"
    >
      <div class="control has-icons-left">
        <input
          name="Amount"
          class="input"
          type="text"
          id="amount2"
          v-model="info.amount"
          placeholder="Amount"
        />
        <span class="icon is-small is-left">
          <font-awesome-icon icon="dollar-sign" size="sm" />
        </span>
      </div>
      <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
    </validation-provider>
  </div>
</template>
<script>
import { ValidationProvider } from "vee-validate";
import moment from "moment";
import { mapState } from "vuex";
import { mapGetters } from "vuex";
import { extend } from "vee-validate";

extend("digits", {
  validate(value, { length }) {
    return value.length >= length;
  },
  params: ["length"],
  message: "The {_field_} field must be exactly {length} digits."
});

export default {
  props: {
    showAmount: {
      type: Boolean,
      required: true
    },     
    lockAmount: {
      type: Boolean,
      default: false
    },
    showOnFile: {
      type: Boolean,
      required: false
    },
    payInfo: {
      type: Object,
      required: false
    }
  },
  mounted() {
    let provinceCodes = this.provinces.map(item => item[0]);
    
    if (this.payInfo != null) {
      this.info.prefilled = true;
      this.info.firstName = this.payInfo.firstName;
      this.info.lastName = this.payInfo.lastName;
      this.info.billingAddress = this.payInfo.address;
      this.info.city = this.payInfo.city;
      this.info.state = this.payInfo.state;
      this.info.zipCode = this.payInfo.zip;
      this.info.paymentOption = this.payInfo.paymentOption;
      this.info.amount = this.payInfo.amount;
      this.info.country = 'US';
      if (provinceCodes.indexOf(this.info.state) > -1) {
        this.info.country = 'CA';
      }
      this.setMethodFields();
    } else {
      this.info.prefilled = false;
    }
  },
  data() {
    return {
      info: {
        firstName: null,
        lastName: null,
        billingAddress: null,
        country:null,
        city: null,
        state: null,
        zipCode: null,
        ccNumber: null,
        ccExpDateMonth: null,
        ccExpDateYear: null,
        cvv: null,
        amount: null,
        paymentOption: null,
        routingNumber: null,
        bankAccountNumber: null,
        phone: null,
        // #2333 not submitting paymentDate
        prefilled: false,
        checkNo: null
      },
      provinces: [["AB","Alberta"],
        ["BC","British Columbia"],
        ["NL","Newfoundland and Labrador"],
        ["NS","Nova Scotia"],
        ["NU","Nunavut"],
        ["ON","Ontario"],
        ["QC","Quebec"],
        ["SK","Saskatchewan"],
        ["YT","Yukon.."]]
    };
  },
  watch: {
    "info.prefilled": {
      handler: function(nv) {
        if (!nv) {
          this.info.prefilled = false;
          this.info.ccNumber = null;
          this.info.ccExpDateMonth = null;
          this.info.ccExpDateYear = null;
          this.info.cvv = null;
          this.info.routingNumber = null;
          this.info.bankAccountNumber = null;
          this.info.phone = null;
          this.checkNo = null;
        } else {
          this.info.prefilled = true;
          this.info.paymentOption = this.payInfo.paymentOption;
          this.setMethodFields();
        }
      }
    },
    info: {
      deep: true,
      immediate: true,
      handler() {
        this.$emit("payment-info-updated", Object.assign({}, this.info));
      }
    }
  },
  components: {
    "validation-provider": ValidationProvider
  },
  computed: {
    ...mapState(["instanceConfig","userInfo"]),
    ...mapGetters("accounts", ["fullAccount"]),
    sortedPaySelects() {
      if (this.userInfo.allowedPaySelects) {
        let newArray = [...this.userInfo.allowedPaySelects];
        return newArray.sort((a, b) => (a.value < b.value ? -1 : 1));
      } else {
        return [];
      }
    },
    next10() {
      let a = [];
      let y = moment().year();
      for (let i = 0; i < 10; i++) {
        a.push(y + i);
      }
      return a;
    }
  },
  methods: {
    setMethodFields() {
      if (Number(this.info.paymentOption) === 0
          || Number(this.info.paymentOption) === 2) {
        this.info.ccNumber = `************${this.payInfo.acctNumber}`;
        this.info.ccExpDateMonth = this.payInfo.ccExpDate.substring(0, 2);
        this.info.ccExpDateYear = "20" + this.payInfo.ccExpDate.substring(2, 4);
        this.info.cvv = "***";
      } else {

        // both CHECK and ACH
        if(this.payInfo.acctNumber) {
          this.info.bankAccountNumber = `${this.payInfo.acctNumber}`;
        } else {
          this.info.bankAccountNumber = null;
        }
        this.info.routingNumber = this.payInfo.routingNumber;

        // just ACH
        if( Number(this.info.paymentOtpion) === 3 ) {
          this.info.checkNo = this.payInfo.checkNo;
        } else {
          this.info.checkNo = null;
        }
      }
    }
  }
};
</script>
