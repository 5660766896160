<template>
    <base-layout>
        <div class="level">
          <div class="level-left">
            <home-nav :user="user" class="level-item" />
          </div>
          <div class="level-right">
            <router-link :to="{ name: 'guest.lookup' }">
              <button class="button">Lookup ID</button>
            </router-link>
          </div>
        </div>
        <guest-progress :show="showProgress" />
        <payment-plan
            v-for="plan in plans"
            :key="plan.id"
            :plan="plan"
            @amount-updated="updateAmounts"
            :user="user"
            :other-amount-message="instanceConfig.guestOtherAmountMessage" />
        <div class="level" v-if="!showProgress" >
            <div class="level-left"></div>
            <div class="level-right">
                <p class="level-item">Grand Total: {{ grandTotal | formatCurrency }}</p>
                <button data-testid="continue-button" class="level-item button is-success" @click="submit" :disabled="!isValid || grandTotal <= 0">Continue</button>
                <button class="level-item button" @click="reset">Reset</button>
            </div>
        </div>
    </base-layout>
</template>
<script>
import HomeNav from "./HomeNav.vue";
import GuestProgress from "../commons/GuestProgress.vue";
import BaseLayout from "@/components/BaseLayout.vue";
import PaymentPlan from "./PaymentPlan.vue";
import {mapState, mapMutations, mapActions} from "vuex";

export default {
    components: { BaseLayout, PaymentPlan, HomeNav, GuestProgress },
    props: ["user","instanceId"],
    data() {
      return {
          amounts: [],
          showProgress: false
      };
    },
    computed: {
      ...mapState(["instanceConfig"]),
      ...mapState("g", ["plans","demPortalUsername"]),
      grandTotal() {
          return this.amounts.reduce((acc, curr) => acc + Number(curr.value), 0);
      },
      isValid() {
        if( !this.amounts.length ) {
          return false;
        }
        if( !this.amounts.every(amt => amt.id && !isNaN(amt.value)) ) {
          return false;
        }

        let lessThanMax = true;

        if( this.plans ) {
          for (let a of this.amounts) {
            for (let p of this.plans) {
              if (a.id == p.id) {
                const total = p.lineItems.find(li => li.type == "TOTAL");
                if (Number(a.value) > Number(total.due)) {
                  lessThanMax = false;
                  break;
                }
              }
            }
          }
        }

        return lessThanMax;
      }
    },
    methods: {
      ...mapMutations("g", ["SET_DEMPORTALUSERNAME"]),
      ...mapActions(["fetchPaymentPortalInstanceConfig"]),
      ...mapActions("g", ["fetchPlans"]),
      updateAmounts(amount) {
          const idx = this.amounts.findIndex(a => a.id == amount.id);
          if( idx == -1 ) {
              this.amounts.push(amount);
          } else {
              if( amount.value ) {
                this.amounts[idx].value = amount.value;
              } else {
                this.amounts.splice(idx, 1);
              }
          }
      },
      reset() {
          this.$root.$emit("reset-form-event");
      },
      submit() {
          try {
            this.showProgress = true;
            this.progressPercent = 10;

            // #2050,2055 don't send over empty other records
            const filteredAmounts = this.amounts.filter(a => !!a.value)

            sessionStorage.setItem("planSubmission", JSON.stringify({
                  amounts: filteredAmounts,
                  grandTotal: this.grandTotal
              }));
              this.$router.push({ name: "guest.confirm", query: { user: this.user } });
          } finally {
              this.showProgress = false;
              this.progressPercent = 0;
          }
      },
      closeError() {
          this.showError = false;
          this.errorMessage = "";
      }
    },
    beforeRouteEnter(to, from, next) {
      next(async vm => {
        try {
            vm.showError = false;
            vm.errorMessage = "";
            vm.showProgress = true;

            if( !vm.demPortalUsername ) {
              vm.SET_DEMPORTALUSERNAME(vm.user);
            }

            await vm.fetchPaymentPortalInstanceConfig(vm.instanceId);
            await vm.fetchPlans();

            vm.$store.commit("SET_GLOBAL_NOTIFICATION");

            sessionStorage.removeItem("planSubmission");
        } finally {
            vm.showProgress = false;
        }
      });
    }
};
</script>