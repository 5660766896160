var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-layout',{attrs:{"loadingFlag":_vm.$store.state.loading}},[_c('nav',{staticClass:"breadcrumb",attrs:{"aria-label":"breadcrumbs"}},[_c('ul',[_c('li',[_c('router-link',{attrs:{"to":{ name: 'home', query: { tenant: _vm.$route.query.tenant } }}},[_c('span',{staticClass:"icon is-small"},[_c('font-awesome-icon',{attrs:{"icon":"home"}})],1),_c('span',[_vm._v("Home")])])],1),_c('li',[_c('router-link',{attrs:{"to":{
            name: 'makePayment',
            params: { id: _vm.$route.params.id }
          }}},[_c('span',{staticClass:"icon is-small"},[_c('font-awesome-icon',{attrs:{"icon":"file-invoice-dollar"}})],1),_c('span',[_vm._v("Make Payment")])])],1),_c('li',{staticClass:"is-active"},[_c('router-link',{attrs:{"to":{
            name: 'details',
            params: { id: _vm.$route.params.id },
            query: { tenant: _vm.$route.query.tenant }
          }}},[_c('span',{staticClass:"icon is-small"},[_c('font-awesome-icon',{attrs:{"icon":"info-circle"}})],1),_c('span',[_vm._v("Details")])])],1)])]),_c('div',{staticClass:"content"},[_c('user-info')],1),_c('h1',{staticClass:"title is-6"},[_vm._v("Details")]),_c('ul',{staticClass:"list"},_vm._l((_vm.displayableValues),function(ref){
          var label = ref.label;
          var type = ref.type;
          var value = ref.value;
return _c('li',{key:label,staticClass:"list-item"},[_c('p',[_c('strong',[_vm._v(_vm._s(label))])]),(type == 'CURRENCY')?_c('p',[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(value))+" ")]):(type == 'DATE')?_c('p',[_vm._v(" "+_vm._s(_vm._f("formatDate")(value))+" ")]):_c('p',[_vm._v(_vm._s(value))])])}),0),_c('router-link',{staticClass:"button",attrs:{"to":{
      name: 'makePayment',
      params: { id: _vm.$route.params.id },
      query: { tenant: _vm.$route.query.tenant }
    }}},[_vm._v("Back")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }