<template>
  <base-layout :loadingFlag="$store.state.loading">
    <h1 class="title">Privacy Policy</h1>
    <div class="buttons">
      <button class="button" @click="print">
        <span class="icon is-small">
          <font-awesome-icon icon="print" />
        </span>
        <span>Print</span>
      </button>
    </div>
    <article class="media">
      <div class="content">
        <div class="box">
          <p v-for="elem in changedPrivacyPolicy" :key="elem.id">
            {{ elem.text }}
          </p>
        </div>
      </div>
    </article>
  </base-layout>
</template>
<script>
import BaseLayout from "@/components/BaseLayout.vue";
import { mapActions } from "vuex";

export default {
  components: { BaseLayout },
  data() {
    return {
      idgen: 1
    };
  },
  methods: {
    ...mapActions(["fetchPaymentPortalInstanceConfig"]),
    print() {
      window.print();
    }
  },
  computed: {
    changedPrivacyPolicy() {
      if (!this.$store.state.instanceConfig.privacyPolicy) {
        return [];
      } else {
        let newArray = this.$store.state.instanceConfig.privacyPolicy.split(
          "\n"
        );
        newArray = newArray.map(elem => ({ id: this.idgen++, text: elem }));
        return newArray;
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm =>
      vm.fetchPaymentPortalInstanceConfig(vm.$route.params.instanceId)
    );
  }
};
</script>
<style lang="scss">
@media print {
  .media {
    display: block !important;
  }
}
</style>
