<template>
  <base-layout :loadingFlag="$store.state.loading">
    <nav class="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li>
          <router-link
            :to="{ name: 'home', query: { tenant: $route.query.tenant } }"
          >
            <span class="icon is-small">
              <font-awesome-icon icon="home" />
            </span>
            <span>Home</span></router-link
          >
        </li>
        <li class="is-active">
          <router-link
            :to="{
              name: 'userProfile',
              query: { tenant: $route.query.tenant }
            }"
          >
            <span class="icon is-small">
              <font-awesome-icon icon="lock" />
            </span>

            <span>User Profile</span></router-link
          >
        </li>
      </ul>
    </nav>
    <div class="content">
      <user-info />
    </div>

    <h1 class="title is-6">User Profile</h1>
    <p class="content">
      Save this form to change your contacts required to send One Time Password.
    </p>

    <ValidationObserver slim v-slot="{ invalid }">
      <ValidationProvider
        rules="required|min:10|max:10"
        tag="div"
        class="field"
        v-slot="{ errors }"
      >
        <div class="control">
          <input
            name="Phone"
            class="input"
            type="text"
            placeholder="Phone"
            v-model="phone"
          />
          <small class="has-text-danger">{{ errors[0] }}</small>
        </div>
      </ValidationProvider>
      <ValidationProvider
        rules="required"
        tag="div"
        class="field"
        v-slot="{ errors }"
      >
        <div class="control">
          <input
            class="input"
            type="text"
            placeholder="Email"
            v-model="email"
            name="Email"
          />
          <small class="has-text-danger">{{ errors[0] }}</small>
        </div>
      </ValidationProvider>

      <div class="buttons">
        <button
          class="button is-success"
          :disabled="invalid || $store.state.loading"
          @click="save"
        >
          Save
        </button>
        <router-link
          :to="{ name: 'home', query: { tenant: $route.query.tenant } }"
          tag="button"
          class="button is-light"
          >Cancel</router-link
        >
      </div>
    </ValidationObserver>
  </base-layout>
</template>

<script>
import BaseLayout from "@/components/BaseLayout.vue";
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import UserInfo from "@/components/UserInfo.vue";
import store from "@/store";

extend("required", {
  ...required,
  message: "{_field_} is required"
});

extend("min", {
  validate(value, { length }) {
    return value.length >= length;
  },
  params: ["length"],
  message: "The {_field_} field must have at least {length} characters"
});

extend("max", {
  validate(value, { length }) {
    return value.length < length;
  },
  params: ["length"],
  message: "The {_field_} field must be less than {length} characters "
});


export default {
  components: {
    BaseLayout,
    ValidationObserver,
    ValidationProvider,
    UserInfo
  },
  data() {
    return {
      phone: null,
      email: null
    };
  },
  methods: {
    async save() {
      this.$store.commit("SET_GLOBAL_NOTIFICATION");
      this.$store.commit("SET_LOADING", true);
      try {
        await this.$store.dispatch("saveUserProfile", {
          phone: this.phone,
          email: this.email
        });
        this.$store.commit("SET_GLOBAL_NOTIFICATION", {
            showNotification: true,
            notificationMessage: `Data has been saved`,
            notificationType: "success"
        });

      } catch (e) {
        this.$store.commit("SET_GLOBAL_NOTIFICATION", {
          showNotification: true,
          notificationMessage: `Error Saving Password; ${e}`,
          notificationType: "error"
        });
      } finally {
        this.$store.commit("SET_LOADING", false);
      }
    }
  },
  mounted() {
    store.dispatch("fetchUserProfile").then(
      _ => {
      this.phone = store.state.userProfile.phone;
      this.email = store.state.userProfile.email;
      }
    );
  },
};
</script>
