<template>
  <base-layout :loadingFlag="$store.state.loading">
    <h1 class="title">Contact Us</h1>
    <div class="box">
      <h2 v-if="companyName" class="subtitle">{{ companyName }}</h2>
      <div class="content">
        <h3 class="subtitle">Address</h3>
        <hr />
        <p>
          {{ companyAddress }}
        </p>
        <p>{{ companyCity }}, {{ companyState }} {{ companyZipCode }}</p>
      </div>
      <div class="content">
        <h3 class="subtitle">Phone</h3>
        <hr />
        <p>
          {{ companyPhoneNumber }}
        </p>
      </div>
      <div class="content" v-if="companyEmail">
        <h3 class="subtitle">Email</h3>
        <hr />
        <p>
          {{ companyEmail }}
        </p>
      </div>
      <div class="content">
        <h3 class="subtitle">Hours of Operation</h3>
        <hr />
        <p>{{ companyDaysOpen }} {{ companyHoursOpen }}</p>
      </div>
    </div>
  </base-layout>
</template>
<script>
import BaseLayout from "@/components/BaseLayout.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: { BaseLayout },
  data() {
    return {
      companyPhoneNumber: null,
      companyDaysOpen: null,
      companyHoursOpen: null,
      companyAddress: null,
      companyCity: null,
      companyState: null,
      companyZipCode: null,
      companyName: null,
      companyEmail: null
    };
  },
  computed: { ...mapState("cc", ["companyConfig"]) },
  methods: {
    ...mapActions("cc", ["fetchPaymentPortalCompanyConfig"])
  },
  beforeRouteEnter(to, from, next) {
    next(async vm => {
      await vm.fetchPaymentPortalCompanyConfig();
      (vm.companyPhoneNumber = vm.companyConfig.companyPhoneNumber),
        (vm.companyDaysOpen = vm.companyConfig.companyDaysOpen),
        (vm.companyHoursOpen = vm.companyConfig.companyHoursOpen),
        (vm.companyAddress = vm.companyConfig.companyAddress),
        (vm.companyCity = vm.companyConfig.companyCity),
        (vm.companyState = vm.companyConfig.companyState),
        (vm.companyZipCode = vm.companyConfig.companyZipCode),
        (vm.companyName = vm.companyConfig.companyName),
        (vm.companyEmail = vm.companyConfig.companyEmail);
    });
  }
};
</script>
