<template>
    <base-layout>
        <cancel-nav />
        <guest-progress :show="showProgress" :value="progressPercent" />
        <h1 class="title">Cancelled</h1>
        <p class="content">Payment was cancelled</p>
    </base-layout>
</template>
<script>
import BaseLayout from "@/components/BaseLayout.vue";
import CancelNav from "./CancelNav.vue";
import GuestProgress from "../commons/GuestProgress.vue";
import { mapState, mapActions } from "vuex";
import writeHistory from "@/activities/WriteHistory";

export default {
    components: { BaseLayout, CancelNav, GuestProgress },
    computed: { 
        ...mapState("g", ["demPortalUsername"])
    },
    methods: {
      ...mapActions(["fetchPaymentPortalInstanceConfig"])
    },
    data() {
        return {
            showProgress: false,
            progressPercent: 0
        };
    },
    async beforeRouteEnter(to, from, next) {
        next(async vm => {
            try {
                vm.showProgress = true;
                vm.progressPercent = 10;

                const user = to.query.demPortalUsername;
                if( user ) {
                  await writeHistory(to.query.demPortalUsername, {
                    event: "RETURN_TO_PORTAL",
                    accountNo: to.query.demPortalUsername,
                    payload: "CANCEL"
                  });
                }
            } finally {
                vm.progressPercent = 99;
                vm.$store.commit("SET_GLOBAL_NOTIFICATION");
                vm.showProgress = false;
            }
        });
    }
};
</script>
