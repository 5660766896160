<template>
    <div v-show="show">
        <progress class="progress is-info" max="100" :value="value"></progress>
        <h2 class="subtitle">Loading...</h2>
    </div>
</template>
<script>
export default {
    props: {
        show: {
            type: Boolean,
            default: false
        },
        value: {
            type: Number,
            validator: (value) => value >= 0 && value <= 100
        }
    }
};
</script>
