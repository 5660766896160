<template>
  <div>
    <table class="table is-striped is-fullwidth is-hidden-mobile" data-testid="line-item-table">
        <thead>
            <tr>
                <th>Citation Number</th>
                <th>Citation Date</th>
                <th>Violation Description</th>
                <th>Last Payment Date</th>
                <th>Last Payment Amount</th>
                <th>Balance Due</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="lineItem in lines" :key="`${lineItem.planId}-${lineItem.seq}`">
                <td>{{ lineItem.citationNumber }}</td>
                <td>{{ lineItem.citationDate | formatDate }}</td>
                <td>{{ lineItem.violationDescription }}</td>
                <td>{{ lineItem.lastPaymentDate | formatDate }}</td>
                <td>{{ lineItem.lastPaymentAmount | formatCurrency }}</td>
                <td class="has-text-right">{{ lineItem.due | formatCurrency }}</td>
            </tr>
            <tr v-if="total">
                <td colspan="5" class="has-text-right">Total Due</td>
                <td class="has-text-right">{{ total.due | formatCurrency }}</td>
            </tr>
        </tbody>
    </table>
    <table class="table is-fullwidth is-hidden-tablet" data-testid="line-item-table-m">
      <tbody
          v-for="(lineItem, index) in lines"
          :key="`m-${lineItem.planId}-${lineItem.seq}`"
          :class="{
            'has-background-white': ((index%2)==0),
            'has-background-grey-lighter': ((index%2)==1)
            }">
          <tr>
            <th>Citation Number</th>
            <td>{{ lineItem.citationNumber }}</td>
          </tr>
          <tr>
            <th>Citation Date</th>
            <td>{{ lineItem.citationDate | formatDate }}</td>
          </tr>
          <tr>
            <th>Violation Description</th>
            <td>{{ lineItem.violationDescription }}</td>
          </tr>
          <tr>
            <th>Last Payment Date</th>
            <td>{{ lineItem.lastPaymentDate | formatDate }}</td>
          </tr>
          <tr>
            <th>Last Payment Amount</th>
            <td>{{ lineItem.lastPaymentAmount | formatCurrency }}</td>
          </tr>
          <tr>
            <th>Balance Due</th>
            <td >{{ lineItem.due | formatCurrency }}</td>
          </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
    props: ["lineItems"],
    computed: {
        lines() {
            return this.lineItems.filter( li => li.type == "LINE_ITEM");
        },
        total() { // should be only 1
            return this.lineItems.find( li => li.type == "TOTAL" );
        }
    }
};
</script>
