import Vue from "vue";
import Vuex from "vuex";
import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";
import state from "./state";
import arrangementModule from "./modules/arrangement";
import parrngModule from "./modules/parrng";
import regEModule from "./modules/rege";
import payModule from "./modules/payment";
import registrationModule from "./modules/registration";
import accountsModule from "./modules/accounts";
import companyConfigModule from "./modules/companyConfig";
import guestModule from "./modules/guest";

Vue.use(Vuex);

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== "production",
  modules: {
    arr: arrangementModule,
    parrng: parrngModule,
    rege: regEModule,
    pay: payModule,
    registration: registrationModule,
    accounts: accountsModule,
    cc: companyConfigModule,
    g: guestModule
  },
  state,
  mutations,
  actions,
  getters
});
