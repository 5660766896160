<template>
  <base-layout>
    <h1 class="title">Registration</h1>
    <p class="content">
      Fill in and submit the required information to claim your account
    </p>
    <validation-observer slim v-slot="{ invalid }">
      <prompt-input-control
        :prompts="m_prompts"
        @prompts-updated="updatePrompts"
      />
      <validation-provider v-if="instanceConfig.loginIdentifier === 'EMAIL'"
          rules="required|min:8|max:100|email|found"
          tag="div"
          class="field"
          v-slot="{ errors }"
      >
        <label class="label">Email</label>
        <div class="control">
          <input class="input" type="text" name="Email" v-model="username" />
          <small
              class="has-text-danger"
              v-show="errors != null && errors.length > 0"
          >{{ errors[0] }}</small
          >
        </div>
      </validation-provider>
      <validation-provider
          v-else
        rules="required|alpha_num|min:8|max:100|found"
        tag="div"
        class="field"
        v-slot="{ errors }"
      >
        <label class="label">Pick a username</label>
        <div class="control">
          <input class="input" type="text" name="Username" v-model="username" />
          <small
            class="has-text-danger"
            v-show="errors != null && errors.length > 0"
            >{{ errors[0] }}</small
          >
        </div>
      </validation-provider>
      <div class="columns">
        <div class="column">
          <validation-provider
            rules="required|min:8|max:40|password:@confirm"
            tag="div"
            class="field"
            v-slot="{ errors }"
          >
            <div class="control">
              <label class="label">Password</label>
              <input
                class="input"
                type="password"
                v-model="newPassword"
                name="Password"
              />
              <small
                class="has-text-danger"
                v-show="errors != null && errors.length > 0"
                >{{ errors[0] }}</small
              >
            </div>
          </validation-provider>
        </div>
        <div class="column">
          <validation-provider
            vid="confirm"
            rules="required|min:8|max:40"
            tag="div"
            class="field"
            v-slot="{ errors }"
          >
            <div class="control">
              <label class="label">Confirm Password</label>
              <input
                class="input"
                type="password"
                v-model="confirmNewPassword"
                name="Confirm Password"
              />
              <small
                class="has-text-danger"
                v-show="errors != null && errors.length > 0"
                >{{ errors[0] }}</small
              >
            </div>
          </validation-provider>
        </div>
      </div>
      <div class="buttons">
        <button class="button is-success" :disabled="invalid" @click="submit">
          Continue
        </button>
        <router-link :to="{ name: 'login' }">
          <button class="button is-light">Cancel</button>
        </router-link>
      </div>
    </validation-observer>
    <registration-loading-modal :loadingFlag="loading" />
  </base-layout>
</template>
<script>
import BaseLayout from "@/components/RegistrationBaseLayout.vue";
import { extend } from "vee-validate";
import { alpha_num, email } from "vee-validate/dist/rules";
import axios from "axios";
import PromptInputControl from "@/components/PromptInputControl.vue";
import RegistrationLoadingModal from "@/components/RegistrationLoadingModal.vue";
import { mapState, mapActions } from "vuex";
import padDbtNos from "./PadDbtNos";

extend("min", {
  validate(value, { length }) {
    return value.length >= length;
  },
  params: ["length"],
  message: "The {_field_} field must have at least {length} characters"
});

extend("max", {
  validate(value, { length }) {
    return value.length <= length;
  },
  params: ["length"],
  message: "The {_field_} field must be less than {length} characters"
});

extend("found", {
  validate: async value => {
    let { data } = await axios.post(
      process.env.VUE_APP_API_URL + "public/registration/checkUsername",
      `username=${value}`
    );
    if (data.available) {
      return true;
    } else {
      return `{_field_} '${value}' already in use.`;
    }
  }
});

extend("password", {
  params: ["target"],
  validate(value, { target }) {
    return value === target;
  },
  message: "Password confirmation does not match"
});

extend("alpha_num", {
  ...alpha_num,
  message: "{_field_} must contain only letters and numbers "
});

extend("email", {
  ...email,
  message: "{_field_} must be a valid email"
});

export default {
  components: {
    BaseLayout,
    PromptInputControl,
    RegistrationLoadingModal
  },
  computed: {
    ...mapState("registration", ["prompts"]),
    ...mapState(["loading", "instanceConfig"])
  },
  data() {
    return {
      username: null,
      newPassword: null,
      confirmNewPassword: null,
      m_prompts: [],
      email: null
    };
  },
  methods: {
    ...mapActions(["fetchPaymentPortalInstanceConfig"]),
    ...mapActions("registration", ["verifyRegister", "fetchSODPrompts"]),
    async submit() {
      this.$store.commit("SET_LOADING", true);
      try {

        padDbtNos(this.m_prompts);

        await this.verifyRegister({
          password: this.newPassword,
          username: this.username,
          prompts: this.m_prompts,
          instanceId: this.$route.params.instanceId
        });
        this.$router.push({
          name: "registrationConfirm",
          query: { tenant: this.$route.query.tenant }
        });
      } catch (e) {

        let msg = e.message;
        if( this.instanceConfig.regFailureMessage ) {
          msg = this.instanceConfig.regFailureMessage; // #2681
        }

        this.$store.commit("SET_GLOBAL_NOTIFICATION", {
          showNotification: true,
          notificationMessage: msg,
          notificationType: "error"
        });
      } finally {
        this.$store.commit("SET_LOADING", false);
      }
    },
    updatePrompts(val) {
      this.m_prompts = val;
    }
  },
  beforeRouteEnter(to, from, next) {
    next(async vm => {
      vm.$store.commit("SET_LOADING", true);
      vm.$store.commit("SET_GLOBAL_SHOW_NOTIFICATION", false);
      try {
        await vm.fetchPaymentPortalInstanceConfig(to.params.instanceId);
        await vm.fetchSODPrompts(vm.instanceConfig.regSODCode);
        // local copy for form (deep)
        vm.m_prompts = JSON.parse(JSON.stringify(vm.prompts));
      } catch (e) {
        vm.$store.commit("SET_GLOBAL_NOTIFICATION", {
          showNotification: true,
          notificationMessage: e,
          notificationType: "error"
        });
      } finally {
        vm.$store.commit("SET_LOADING", false);
      }
    });
  }
};
</script>
