<template>
  <div class="modal" :class="{ 'is-active': loadingFlag }">
    <div class="modal-background"></div>
    <div class="modal-card">
      <div class="modal-card-body">
        <div class="content spinner-wrapper" style="height: 20rem">
          <span class="icon is-large has-text-info spinner">
            <font-awesome-icon icon="spinner" size="6x" spin />
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["loadingFlag"]
};
</script>
