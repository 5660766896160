<template>
  <div class="content" v-if="!$store.state.loading">
    <!-- payment schedule -->
    <div v-if="scheduledPayments != null && scheduledPayments.length > 0">
      <dyna-table
        tableClasses="table is-striped is-fullwidth"
        :headers="tableHeaders"
        :data="scheduledPayments"
        rowKey="Payment #"
        :supportMobile="true"
      />
    </div>
    <p
      v-if="scheduledPayments != null && scheduledPayments.length > 0"
      class="content"
    >
      ** Expected balance may include estimated interest accrual.
    </p>
    <p v-else class="content">No scheduled payments</p>
  </div>
</template>
<script>
import DynaTable from "@/components/DynaTable.vue";
import { mapActions } from "vuex";

export default {
  props: ["id"],
  components: { DynaTable },
  methods: {
    ...mapActions(["fetchPaymentArrangements"])
  },
  computed: {
    tableHeaders() {
      if (this.scheduledPayments == null || this.scheduledPayments.length == 0)
        return [];
      else return Object.values(this.scheduledPayments[0]);
    }
  },
  data() {
    return {
      scheduledPayments: []
    };
  },
  async mounted() {
    this.scheduledPayments = await this.fetchPaymentArrangements(this.id);
  },
  watch: {
    async id(nv) {
      this.scheduledPayments = await this.fetchPaymentArrangements(nv);
    }
  }
};
</script>
