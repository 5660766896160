<template>
  <base-layout :loadingFlag="$store.state.loading">
    <account-header />
    <div class="columns">
      <div class="column is-one-quarter is-hidden-mobile">
        <sidebar />
      </div>
      <div class="column">
        <h1 class="title">Regulation E Disclosures</h1>
        <p class="notification is-warning">
          You must read and accept the following disclosures before continuing.
        </p>
        <div class="field">
          <div class="control">
            <label class="checkbox">
              <input type="checkbox" v-model="acceptRegE" />
              {{ regeAcknowledgeLine }}
            </label>
          </div>
        </div>
        <div class="buttons">
          <button
            @click="saveAcceptRegE"
            class="button is-success"
            :disabled="!acceptRegE"
          >
            Continue
          </button>
          <router-link
            :to="{ name: 'makePayment', params: { id: $route.params.id } }"
            tag="button"
            class="button is-light"
            >Cancel</router-link
          >
        </div>
        <p class="content" v-for="(re, index) in regeSplit" :key="index">
          {{ re }}
        </p>
      </div>
    </div>
  </base-layout>
</template>
<script>
import BaseLayout from "@/components/BaseLayout.vue";
import filters from "@/utils/filters.js";
import Sidebar from "@/components/Sidebar.vue";
import AccountHeader from "@/components/AccountHeader.vue";
import store from "../store/index";
import { mapState, mapActions } from "vuex";

function unloadListener(event) {
  // Cancel the event as stated by the standard.
  event.preventDefault();
  // Chrome requires returnValue to be set.
  event.returnValue = "";
}

export default {
  components: { BaseLayout, Sidebar, AccountHeader },
  data() {
    return {
      acceptRegE: false,
      regE: [],
      regeAcknowledgeLine: null,
      ppcc: null
    };
  },
  computed: {
    ...mapState("cc", ["companyConfig"]),
    regeLabel() {
      if (this.$route.query.action == "next") {
        if (
          this.$store.state.paymentInProgress.paymentOption == "0" ||
          this.$store.state.paymentInProgress.paymentOption == "2"
        ) {
          return this.ppcc.rege4Label;
        } else {
          return this.ppcc.rege2Label;
        }
      } else {
        if (
          this.$store.state.paymentInProgress.paymentOption == "0" ||
          this.$store.state.paymentInProgress.paymentOption == "2"
        ) {
          return this.ppcc.rege3Label;
        } else {
          return this.ppcc.rege1Label;
        }
      }
    },
    regeSplit() {
      let arr = [];
      for (let i = 0; i < this.regE.length; i++) {
        arr.push(this.regE[i].split("\n"));
      }
      return arr.flat();
    }
  },
  methods: {
    ...mapActions("cc", ["fetchPaymentPortalCompanyConfig"]),
    saveAcceptRegE() {
      let sodText = this.regE.join();
      let disclosureText =
        sodText +
        "\n" +
        `Payment to be Processed of: ${filters.formatCurrency(
          this.$store.state.paymentInProgress.amount
        )}`;
      this.$store.commit("SET_ACCEPT_REGE", true);
      this.$store.commit("SET_REGE_DISCLOSURE", {
        disclosureLabel: this.regeLabel,
        disclosureText: disclosureText
      });
      this.$router.push({
        name: "confirm",
        params: { id: this.$route.params.id }
      });
    }
  },
  beforeRouteEnter(to, from, next) {
    if (!store.state.paymentInProgress) {
      next({ name: "account", params: { id: to.params.id } });
    } else {
      next(async vm => {
        window.addEventListener("beforeunload", unloadListener);
        await vm.fetchPaymentPortalCompanyConfig();
        vm.regeAcknowledgeLine = vm.companyConfig.regeAcknowledgeLine;
        vm.ppcc = vm.companyConfig;
        let payload = {
          option: vm.$store.state.paymentInProgress.paymentOption,
          action: vm.$route.query.action,
          relatedAccount: vm.$route.params.id
        };
        vm.regE = await vm.$store.dispatch("fetchRegEMessages", payload);
      });
    }
  },
  beforeRouteLeave(to, from, next) {
    window.removeEventListener("beforeunload", unloadListener);
    next();
  }
};
</script>
